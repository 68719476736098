import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import * as moment from 'moment-timezone';
import { steps, atc_steps } from './steps';
import { PrepareforRace, PrepareService } from './pre-race.service';

import {
  preapreLocationMappers,
  reverseLocationMappers,
  typeBasedLocations,
} from '../post-race/harness-reports/components/race-reports/locations';
import { getENV, getRaceType } from 'src/app/_helpers/helpers';
import { race } from 'rxjs';

export interface back_value {
  value: string;
  disabled?: string;
}
export interface location_value {
  name: string;
  code: string;
}

const kits_mapping = [
  { id: 'ATC Kit 1', value: 'ATC_1' },
  { id: 'ATC Kit 2', value: 'ATC_KIT2' },
  { id: 'RQLD Kit 1 (BRC)', value: 'BRC_(QLD_KIT1)' },
  { id: 'RQLD Kit 4', value: 'DEFAULT' },
  { id: 'STC Kit 1', value: 'DEFAULT_2' },
  { id: 'RQLD Kit 2', value: 'GC-SCTC-IPSW(QLD_KIT2)' },
  { id: 'HRV Kit 1', value: 'HRV_1' },
  { id: 'HRV Kit 1', value: 'HRV_Cranbourne' },
  { id: 'HRV Kit 2', value: 'HRV_Mildura' },
  { id: 'HRNSW', value: 'Menangle(HRNSW_KIT1)' },
  { id: 'SA Kit 1', value: 'Morphettville-MurrayBridge(SA_KIT1)' },
  { id: 'HRNSW Kit 2', value: 'Newcastle(HRNSW_KIT2)' },
  { id: 'HRNSW Kit 3', value: 'Bathurst(HRNSW_KIT3)' },
  { id: 'RQLD Kit 5', value: 'NthQld (QLD_KIT5)' },
  { id: 'RNZ Kit1', value: 'NZ_Ellerslie(NZ_KIT1)' },
  { id: 'RNZ Kit1', value: 'NZ_Pukekohe(NZ_KIT1)' },
  { id: 'HRNSW', value: 'Penrith(HRNSW_KIT1)' },
  { id: 'RQLD Kit 4', value: 'QLD_Harness(QLD_KIT4)' },
  { id: 'RQLD Kit 3', value: 'QLD_Toowoomba(QLD_KIT3)' },
  { id: 'RQLD Kit 6(ROCK)', value: 'Rocky(QLD_KIT6)' },
  { id: 'RV Kit 1', value: 'RV_1' },
  { id: 'RV Kit 2', value: 'RV_2' },
  { id: 'RV Kit 3', value: 'RV_3' },
  { id: 'SA Kit 1', value: 'SA_Gawler(SA_KIT1)' },
  { id: 'STC Kit 1', value: 'Singapore(STC)' },
];

const locations_mapping = {
  'Tabcorp Park Menangle': 'Tabcorp Pk Menangle',
};

export interface PrepareData {
  variable: any;
  step: number;
  action: string;
  event_id: string;
  rerun: boolean;
}

let config: any;

@Component({
  selector: 'pre-race',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class PreRaceComponent implements OnInit, OnDestroy {
  @Input('venue') venue: string;
  @Input('date') date: string;
  @Input('relogin') relogin: boolean;
  @Input('pc_start_stop') pc_start_stop: boolean;
  @Input('venue_id') venue_id: string;
  @Input('pc') pc: string;
  @Input('operator') operator: string;
  @Input('event_id') event_id: string;
  @Input('kit') kit: string;
  @Input('jurisdiction') jurisdiction: string;
  @Input('type') type: string;
  @Input('url') url: string;
  @Input('fragment') fragment: string;
  @Input('queryParams') queryParams: any;
  @Output('hideModel') hideModel: any = new EventEmitter<boolean>();
  form!: FormGroup;
  submitted = false;
  un: string = localStorage.getItem('un');
  preparing: boolean = false;
  small_file: boolean = false;
  destroyed: boolean = false;

  locations: any = [{ value: 'a' }, { value: 'b' }];
  PCs: back_value[] = [{ value: 'c' }, { value: 'd' }];
  kits: back_value[] = [{ value: 'e' }, { value: 'f' }];
  brands: string[] = ['g', 'f'];
  assigedPcs: any = [];
  location_backup: location_value[] = [];
  operators: back_value[] = [{ value: 'a' }, { value: 'b' }];
  loading: boolean = true;
  steps: any = {};

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public router: Router,
    private apiService: APIService,
    private notifier: NotifierService,
    private prepareSystem: PrepareService
  ) {
    config = this.route.snapshot.data['config'];
  }

  // async timeout(ms: number) {
  //   return new Promise((resolve: any, reject: any) => {
  //     setTimeout(() => {
  //       resolve(true);
  //     }, ms);
  //   })
  // }
  codeType: string = null;
  raceType: string = null;
  location: string = null;
  finish: boolean = false;
  // rerun: boolean = false;
  failedCount: number = 0;

  prepares: any = [];
  preparesSubscribe: any;
  async ngOnInit(): Promise<void> {
    if (locations_mapping[this.venue]) {
      this.venue = locations_mapping[this.venue];
    }

    if(this.jurisdiction == 'ATC') {
      this.steps = JSON.parse(JSON.stringify(atc_steps));
    } else {
      this.steps = JSON.parse(JSON.stringify(steps));
    }
    if (this.relogin) {
      this.pc = null;
    }
    this.fetchTimezone();
    this.preparesSubscribe = this.prepareSystem.dataSource$.subscribe(
      (prepares: any) => {
        if (localStorage.getItem(`prepareSystem_${this.event_id}`)) {
          let prepare = prepares.findIndex((prepare: any) => {
            return (
              prepare.payload.event_id == this.event_id &&
              prepare.payload.type == this.type
            );
          });

          if (prepare > -1) {
            this.prepare = prepares[prepare];
            this.prepare['index'] = prepare;
            this.kit = this.prepare.payload.kit;
            this.event_id = this.prepare.payload.event_id;
            this.venue_id = this.prepare.payload.venue_id;
            this.pc = this.prepare.payload.pc;
            this.location = this.prepare.payload.location;
          }

          // console.log(this.prepare);
        }
      }
    );
    // this.kit = '';
    this.form = this.formBuilder.group({
      brand: ['', Validators.required],
      location: ['', Validators.required],
      PC: [this.pc || '', Validators.required],
      kit: ['', Validators.required],
      operator: [
        this.type == 'login' || this.type == 'manual_login'
          ? this.un
          : this.operator,
        Validators.required,
      ],
    });

    this.locations = [];
    this.PCs = [];
    this.kits = [];
    this.location_backup = [];
    this.brands = [];
    this.operators = [];
    // get ranges from backend

    // await this.timeout(10000);
    this.callAPis();
  }

  systemTimeZone: string = '';
  timezone: string = '';
  isLoadingTimeZone: boolean = false;
  async getCommandStatusAPICaller(commandId: string, commandType: string) {
    const apiURL = `${config[getENV()].raceAPI}/flask-operations`;
    const payload = {
      action: 'ssm_get_command',
      command_type: commandType,
      pc_name: this.form.value.PC,
      command_id: commandId,
    };

    const response = await this.apiService.postDataPromis(apiURL, payload);

    return response;
  }

  async pollCommandStatus(
    commandId: string,
    commandType: string
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await this.getCommandStatusAPICaller(
          commandId,
          commandType
        );
        if (response.status == '1' || response.s == '1') {
          response.response.command_status =
            response?.response.command_status ||
            response?.response.status ||
            'NOT FOUND';
          if (
            response?.response &&
            (response?.response?.command_status === 'InProgress' ||
              response?.response?.command_status === 'Pending')
          ) {
            setTimeout(async () => {
              const statusResponse = await this.pollCommandStatus(
                commandId,
                commandType
              );
              resolve(statusResponse);
            }, 3000);
          } else {
            resolve(response.response);
          }
        } else {
          resolve(response.response);
        }
      } catch (error) {
        console.error('Error polling command status:', error);
        reject(error);
      }
    });
  }

  async getCurrentTimeZone() {
    this.isLoadingTimeZone = true;
    this.systemTimeZone = '';
    const apiURL = `${config[getENV()].raceAPI}/flask-operations`;
    const payload = {
      action: 'ssm_send_command',
      command_type: 'get_time_zone',
      pc_name: this.form.value.PC,
    };
    try {
      const response = await this.apiService.postDataPromis(apiURL, payload);
      if (response.status === '1') {
        const statusResponse = await this.pollCommandStatus(
          response.response.command_id,
          'get_time_zone'
        );
        if (statusResponse.command_status === 'Success') {
          this.systemTimeZone = statusResponse.output.Id;
        }
      }
    } catch (error) {
      console.error('Error fetching time zone:', error);
    } finally {
      this.isLoadingTimeZone = false;
    }
  }

  private async fetchTimezone() {
    let apiURL: string = `${config[getENV()].raceAPI}/flask-operations`;
    let sending: any = {
      action: 'get_venue_timezone',
      venue_id: this.venue_id,
    };

    const result = await this.apiService.postDataPromis(apiURL, sending, {});
    if (result?.status == '1') {
      this.timezone = result?.timezone || '';
    }
  }

  ut: string | null = localStorage.getItem('ut');
  async callAPis() {
    let promises: any = [];
    // if(this.kitsArray.length > 0) {
    //   this.kitsArray.forEach((e: any) => {
    //     var bb = { value: e } as back_value;
    //     this.kits.push(bb);
    //   });
    // } else {
    promises.push(this.getKits());
    // }
    promises.push(this.getPCs());
    promises.push(this.getOperators());
    promises.push(this.getLocations());
    if (this.ut == 'admin') {
      this.fetchAllUsers();
    }
    Promise.all(promises).then((values: any) => {
      promises.push(this.getAssignedPCs());
      this.loading = false;
      // this.PCs.forEach((pc: any) => {
      //   if (this.assigedPcs.includes(pc.value)) {
      //     pc.disabled = true;
      //   } else {
      //     pc.disabled = null;
      //   }
      // });
    });
  }

  reLogin() {
    this.relogin = true;
    if(this.jurisdiction == 'ATC') {
      this.steps = JSON.parse(JSON.stringify(atc_steps));
    } else {
      this.steps = JSON.parse(JSON.stringify(steps));
    }
    this.prepare.payload.steps = this.steps;
    this.onSubmit();
  }

  userList: any = [];
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  async fetchAllUsers() {
    this.notifier.loading(true);
    this.userList = [];

    let data: any = {
      a: 'fetchAll',
      email: localStorage.getItem('eId'),
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${config[getENV()].apiURL}/${
      this.urlPrefix
    }/Settings/usermanagement`;

    try {
      let result = await this.apiService.postDataPromis(apiURL, data, header);

      if (result.s == '1' || result.status == '1') {
        this.userList = result.clients.map((user: any) => ({
          username: user.userName,
        }));
        if (
          this.un &&
          !this.userList.some((user: any) => user.username === this.un)
        ) {
          this.userList.push({ username: this.un });
        }
        this.userList.sort((a: any, b: any) =>
          a.username.localeCompare(b.username)
        );
      } else {
        this.notifier.alert('Info', '', result.error || result.m, 'info', 5000);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      this.notifier.alert('Error', '', 'Failed to fetch users', 'error', 5000);
    } finally {
      this.notifier.loading(false);
      this.loading = false;
    }
  }

  async getAssignedPCs() {
    return new Promise(async (resolve: any, reject: any) => {
      let apiURL = `${
        config[getENV()].raceAPI
      }/flask-operations?action=get_assigned_pcs`;
      let data = await this.apiService.getDataPromis(apiURL, {}, {});
      if (data.status == '1' || data.s == '1') {
        this.assigedPcs = data.pcs.map((pc: any) => {
          pc['MeetingDate'] = moment(pc['MeetingDate'], 'YYYY-MM-DD').format(
            'DD MMMM YYYY'
          );

          const mappedLocation: string = preapreLocationMappers[pc['VenueName']]
            ? preapreLocationMappers[pc['VenueName']]
            : pc['VenueName'];

          if (pc['AssignedPCName'] == this.pc && mappedLocation == this.venue) {
            this.form.patchValue({ kit: pc['AssignedKit'] });
            this.kit = pc['AssignedKit'];
          }
          return pc;
        });
      }
      resolve(true);
    });
  }

  private findKitsByInputKit(inputKit: string): any[] {
    if (!inputKit) return [];
    return kits_mapping.filter(kit => kit.id === inputKit);
  }

  private findKitByVenue(matchedKits: any[], venueName: string): string {
    if (!venueName || !matchedKits.length) return null;

    const normalizedVenue = venueName.toLowerCase().trim();

    // If only one kit matched, use it directly
    if (matchedKits.length === 1) {
      return matchedKits[0].value;
    }

    // If multiple kits, check venue match
    const matchedKit = matchedKits.find(kit => {
      const kitValue = kit.value.toLowerCase();
      const kitLocation = this.extractLocationFromKit(kitValue);
      return kitLocation.includes(normalizedVenue) || normalizedVenue.includes(kitLocation);
    });

    return matchedKit?.value || null;
  }

  async getKits(): Promise<boolean> {
    return new Promise(async (resolve: any, reject: any) => {
      const locationSelected = this.form.value.location || this.location || this.venue;
      let raceType_: string = '';
      let primaryKits = [];
      let secondaryKits = [];

      if (this.venue) {
        raceType_ = getRaceType(this.venue_id)['raceTypeStr'];
      }

      // First get primary and secondary kits
      Object.keys(typeBasedLocations).forEach((raceType: any) => {
        if (raceType_ && raceType_ != raceType) return;

        typeBasedLocations[raceType].forEach((jurisdiction: any) => {
          let location: any = jurisdiction.Locations.find((location: any) =>
            location.Name.toLowerCase() == locationSelected.toLowerCase()
          );

          if (location) {
            primaryKits = location['PrimaryKit'];
            secondaryKits = location['SecondaryKits'];
            this.kits = [
              ...primaryKits.map((kit: any) => ({ value: kit })),
              ...secondaryKits.map((kit: any) => ({ value: kit }))
            ];
          }
        });
      });

      // Then apply kit mapping logic
      if (this.type == 'login' || this.type == 'manual_login') {
        const matchedKits = this.findKitsByInputKit(this.kit);
        let defaultKit = null;

        if (matchedKits.length > 1) {
          defaultKit = this.findKitByVenue(matchedKits, locationSelected);
          if (!defaultKit) {
            this.notifier.alert(
              'Warning',
              '',
              `One or more kits are associated to ${this.kit}, please select manually`,
              'warning',
              5000
            );
            defaultKit = matchedKits[0].value;
          }
        } else if (matchedKits.length === 1) {
          defaultKit = matchedKits[0].value;
        }

        // Set default kit if available in primary/secondary kits
        if (defaultKit && this.kits.some(k => k.value === defaultKit)) {
          this.kit = defaultKit;
          this.form.patchValue({ kit: defaultKit });
        } else if (primaryKits.length > 0) {
          this.kit = primaryKits[0];
          this.form.patchValue({ kit: primaryKits[0] });
        }
      }

      resolve(true);
    });
  }

  private extractLocationFromKit(kitValue: string): string {
    // Extract location from kit value patterns
    return kitValue
      .split('(')[0]         // Remove everything after (
      .split('_')            // Split by _
      .slice(1)             // Take everything after first _
      .join('_')           // Join back if multiple parts
      .toLowerCase()
      .trim();
  }

  pcError: any = null;
  checkPCSelected(event: any) {
    this.getCurrentTimeZone();
    let selected: any = this.assigedPcs.find((pc: any) => {
      return pc['AssignedPCName'] == event.value;
    });
    if (selected) {
      this.pcError = selected;
    } else {
      this.pcError = null;
    }
  }

  async getPCs(): Promise<boolean> {
    return new Promise(async (resolve: any, reject: any) => {
      let apiURL = `${
        config[getENV()].raceAPI
      }/flask-operations?action=get_pcs`;
      let data = await this.apiService.getDataPromis(apiURL, {}, {});
      var pc_range = data.PCs;
      pc_range.forEach((e: any) => {
        var bb = { value: e.name } as back_value;
        this.PCs.push(bb);
      });
      resolve(true);
    });
  }

  changeLocation(event: any) {
    let temp: any = this.location_backup.find((location: any) => {
      return location['name'] == event['value'];
    });
    if (temp) {
      this.codeType = temp['code'];
    }
    this.getKits();
  }

  async getLocations(): Promise<boolean> {
    return new Promise(async (resolve: any, reject: any) => {
      let apiURL = `${
        config[getENV()].raceAPI
      }/flask-operations?action=get_locations`;
      let data = await this.apiService.getDataPromis(apiURL, {}, {});
      var location_range = data.locations;
      let temp = location_range.find((e: any) => {
        var bb = { value: e.name } as back_value;
        this.locations.push(bb);
        this.location_backup.push({ name: e.name, code: e.code });
        // if(e.name == this.codeType.toLowerCase() || e.code   == 'GALLOPS')
        return (
          (reverseLocationMappers[e.name] || e.name)
            .toLowerCase()
            .replaceAll(' ', '') == this.venue.toLowerCase().replaceAll(' ', '')
        );
      });

      if (temp) {
        this.location = temp['name'];
        this.codeType = temp['code'];
      }
      resolve(true);
    });
  }

  async getOperators(): Promise<boolean> {
    return new Promise(async (resolve: any, reject: any) => {
      let apiURL = `${
        config[getENV()].raceAPI
      }/flask-operations?action=get_operators`;
      let data = await this.apiService.getDataPromis(apiURL, {}, {});
      var operator_range = data.operators;
      operator_range.find((e: any) => {
        var bb = { value: e.name } as back_value;
        this.operators.push(bb);
      });
      resolve(true);
    });
  }

  onTypeChange(o: any) {
    this.locations = [];
    this.location_backup.forEach((e: any) => {
      if (e.code == o.value) {
        this.locations.push({ value: e.name } as back_value);
      }
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  async check() {
    let apiURL: string = `${config[getENV()].raceAPI}/flask-operations`;
    let sending: any = {
      action: `${this.type}_check`,
      venue_name: this.location || this.form.value.location,
      PC: this.form.value.PC,
      kit: this.kit || this.form.value.kit,
      operator: this.un,
      venue_id: this.venue_id,
      event_id: this.event_id,
      type: this.codeType,
      rerun: this.relogin,
    };

    let result: any = await this.apiService.postDataPromis(apiURL, sending, {});

    if (result?.response?.status) {
      return true;
    } else {
      this.notifier.alert(
        'Info',
        '',
        result?.response?.reason ||
          result?.error_message ||
          'Unable to validate user. Please relogin or try again later..',
        'info',
        5000
      );
      return false;
    }
  }

  async checkStatus(apiURL: string, step: number, stepObj: any) {
    const payload: any = {
      action: 'login_logout_status',
      venue_name: this.location || this.form.value.location,
      PC: this.form.value.PC,
      kit: this.kit || this.form.value.kit,
      operator: this.un,
      venue_id: this.venue_id,
      event_id: this.event_id,
      type: this.type,
      step: step,
      relogin: this.relogin,
      rerun: this.reruns > 0,
    };
    let res = await this.apiService.postDataPromis(apiURL, payload, {});
    if (res.status == '1') {
      stepObj['comment'] = res?.response?.Comments;
      if (!res?.response?.StepCompletionStatus) {
        return null;
      }
      return (
        res?.response?.StepCompletionStatus.toUpperCase().replaceAll(
          ' ',
          '_'
        ) || 'FAILED'
      );
    } else {
      return null;
    }
  }

  onKitSelection(kit: any) {
    this.kit = kit.value;
    this.form.patchValue({ kit: kit.value });
  }

  reruns: number = 0;
  prepare: PrepareforRace = null;

  faultyPopup: boolean = false;
  hideFaultPopup(event: any) {
    this.faultyPopup = false;
    this.onSubmit();
  }

  onOperatorChange(event: any) {
    this.un = event.value;
  }

  async onSubmit(
    rerun: boolean = false,
    force: boolean = false
  ): Promise<void> {
    if (!this.form.value.PC || !this.form.value.kit) {
      this.notifier.alert(
        'Info',
        '',
        'Please select PC and KIT before submitting',
        'info',
        5000
      );
      return;
    }
    if (!this.form.value.location && !this.location) {
      this.notifier.alert(
        'Info',
        '',
        'Please select Location before submitting',
        'info',
        5000
      );
      return;
    }
    // this.loading = true;

    const location = this.location || this.form.value.location;

    let payload: any = {
      location: location,
      pc: this.form.value.PC,
      kit: this.kit || this.form.value.kit,
      operator: this.un,
      venue_id: this.venue_id,
      event_id: this.event_id,
      jurisdiction: this.jurisdiction,
      code_type: this.codeType,
      race_date: moment(this.date, 'DD MMMM YYYY').format('YYYY-MM-DD'),
      type: this.type,
      action: this.type == 'login' ? 'login_async' : 'logout_async',
      relogin: this.relogin,
      rerun: rerun,
      pc_start_stop: this.pc_start_stop,
    };

    if (this.type == 'manual_login') {
      payload['operator_name'] = payload['operator'];
      payload['pc_number'] = payload['pc'];
      payload['action'] = 'manual_login';

      this.loading = true;

      let apiURL: string = `${config[getENV()].raceAPI}/flask-operations`;

      let result: any = await this.apiService.postDataPromis(
        apiURL,
        payload,
        {}
      );

      if (result.status == '1' || result.s == '1') {
        this.notifier.alert(
          'Success',
          '',
          result.message || 'Login Successful',
          'success',
          5000
        );
        this.hideModel.emit(true);
      } else {
        this.notifier.alert(
          'Info',
          '',
          result?.error_message ||
            'Unable to validate user. Please relogin or try again later..',
          'info',
          5000
        );
      }

      this.loading = false;
      return;
    }

    this.prepare = this.prepareSystem.newPrepare(payload, config);

    // console.log(prepare);

    return;
    let login_check = await this.check();
    this.loading = false;
    console.log('RELOGIN', this.relogin);

    if (!login_check && (!this.relogin || !rerun)) {
      return;
    }
    console.log('RELOGIN', this.relogin);
    if (rerun) {
      this.reruns += 1;
    }
    this.submitted = true;
    this.preparing = true;
    this.finish = false;
    this.small_file = false;
    this.failedCount = 0;

    for (let i = 0; i < this.steps[this.type].length; i++) {
      let step = this.steps[this.type][i];
      if (this.failedCount > 0) {
        break;
      }
      if (step['status'] == 'COMPLETED' && (!this.relogin || !rerun)) continue;
      let sending: PrepareData = {
        action: this.type == 'login' ? 'login_async' : 'logout_async',
        event_id: this.event_id,
        variable: {
          venue_name: this.location || this.form.value.location,
          PC: this.form.value.PC,
          kit: this.kit || this.form.value.kit,
          operator: this.un,
          venue_id: this.venue_id,
          event_id: this.event_id,
          type: this.codeType,
        },
        step: i + 1,
        rerun: this.relogin,
      };

      let apiURL: string = `${config[getENV()].raceAPI}/flask-operations`;
      await this.triggerStep(apiURL, sending, step, i);
    }

    this.preparing = false;
    this.finish = true;
  }

  interval: any = null;
  async triggerStep(
    apiURL: string,
    payload: any,
    step: any,
    stepCount: number
  ) {
    return new Promise(async (resolve: any, reject: any) => {
      const currentStatus: string = step['status'];
      step['status'] = 'IN_PROGRESS';
      let status: any = await this.checkStatus(apiURL, stepCount + 1, step);
      let res = { status: '1' };
      if (status && status == 'COMPLETED' && (!this.relogin || this.reruns)) {
        step['status'] = status;
        resolve(true);
        return;
      } else if (status != 'IN_PROGRESS') {
        res = await this.apiService.postDataPromis(apiURL, payload, {});
      }

      if (res.status != '1') {
        this.failedCount += 1;
        if (stepCount == 1 && this.type == 'logout') {
          this.small_file = true;
        }
        step['status'] = 'FAILED';
        resolve(false);
      } else {
        step['status'] = await this.checkStatus(apiURL, stepCount + 1, step);
        if (step['status'] != 'FAILED') {
          this.interval = setInterval(async () => {
            step['status'] = await this.checkStatus(
              apiURL,
              stepCount + 1,
              step
            );
            if (step['status'] == 'FAILED' || step['status'] == 'COMPLETED') {
              if (step['status'] == 'FAILED') {
                this.failedCount += 1;
                if (stepCount == 1 && this.type == 'logout') {
                  this.small_file = true;
                }
                step['status'] = 'FAILED';
              }
              clearInterval(this.interval);
              this.interval = null;
              resolve(true);
            }
          }, 5000);
        } else {
          this.failedCount += 1;
          resolve(true);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed = true;
    this.preparesSubscribe.unsubscribe();
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  close(event: any) {
    this.prepareSystem.removePrepare(this.prepare, this.prepare['index']);
    this.hideModel.emit(event);
  }
}
