import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { formatFileSize, getENV, getRaceType } from 'src/app/_helpers/helpers';
import { APIService } from 'src/app/services/api.service';
import { locationMappers } from '../../locations';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'src/app/services/notifier.service';
import * as moment from 'moment';

interface ErrorData {
  HorseName: string;
  Section: string;
  Metric: string;
  Value: number;
  Expected: number[];
  RaceLength: number;
  SegmentNumber?: number; // Optional for Thoroughbred
  Status: boolean;
}

interface QueryParams {
  venue_name: string;
  race_number: string;
  race_id: string;
  race_date: string;
  external_event_id: string;
  jurisdiction_code: string;
  external_venue_id: string;
  race_length: string;
  pc_number?: string; // optional
}

@Component({
  selector: 'post-race-qa',
  templateUrl: './post-race-qa.component.html',
  styleUrls: ['./post-race-qa.component.css'],
})
export class PostRaceQaComponent implements OnInit {
  @Input() data: any;
  @Output() hideModel = new EventEmitter<boolean>();

  dragging = false;
  file: File | null = null;
  fileError: string | null = null;
  fileSuccess: string | null = null;
  formatFileSize = formatFileSize;

  errorData: ErrorData[] = [];
  loading: boolean = false;
  apiError: string | null = null;

  config: any;
  groupedErrorData: { [key: string]: ErrorData[] } = {};

  filterStatus: 'ALL' | 'PASS' | 'FAIL' = 'ALL';

  showTolUpload = false;
  ignoringRules: boolean = false;

  // Add property to track race type
  raceType: string;

  private readonly SPEED_METRICS = [
    'AverageSpeed',
    'TopSpeedMethod1',
    'TopSpeedMethod2',
  ];

  constructor(
    private apiService: APIService,
    private route: ActivatedRoute,
    private router: Router,
    private notifier: NotifierService
  ) {
    this.config = this.route.snapshot.data['config'];
  }

  ngOnInit() {
    if (!this.validateQueryParams()) {
      this.router.navigate(['/dash/home']);
      return;
    }
    this.raceType = getRaceType(
      this.data['event'].external_event_id
    ).raceTypeStr;
    this.data.event.race_type = this.raceType;
    this.fetchErrorData();
  }

  private validateQueryParams(): boolean {
    const params = this.route.snapshot.queryParams;
    const requiredParams: (keyof QueryParams)[] = [
      'venue_name',
      'race_number',
      'race_id',
      'race_date',
      'external_event_id',
      'jurisdiction_code',
      'external_venue_id',
    ];

    const missingParams = requiredParams.filter((param) => !params[param]);

    if (missingParams.length > 0) {
      console.error('Missing required query parameters:', missingParams);
      return false;
    }

    // Structure data object from query params
    this.data = {
      event: {
        venue_name: params['venue_name'],
        date: params['race_date'],
        external_event_id: params['external_event_id'],
        JurisdictionCode: params['jurisdiction_code'],
        pc: params['pc_number'] || null,
        external_venue_id: params['external_venue_id'],
      },
      race: {
        SquentialRaceOrderNumber: params['race_number'],
        ExternalRaceId: params['race_id'],
        race_length: params['race_length']
      },
    };

    return true;
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragging = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragging = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragging = false;

    if (!event.dataTransfer?.types.includes('Files')) {
      this.fileError = 'Not a valid file';
      return;
    }

    this.validateFile(event.dataTransfer.files);
  }

  validateFile(files: FileList): boolean {
    this.fileError = null;
    if (!files || files.length === 0) {
      this.fileError = 'Missing file';
      return false;
    }
    if (files.length > 1) {
      this.fileError = 'Multiple files are not allowed';
      return false;
    }

    const file = files[0];
    const validTypes = ['text/xml', 'application/xml'];
    if (!validTypes.includes(file.type)) {
      this.fileError = 'Only XML files are allowed';
      return false;
    }

    this.fileError = '';
    this.file = file;
    return true;
  }

  onFileSelected(event: Event) {
    const element = event.target as HTMLInputElement;
    const files = element.files;
    let status = this.validateFile(files);
    if (!status) {
      element.value = '';
    }
  }

  cancelFile() {
    this.file = null;
    this.fileError = null;
    this.fileSuccess = null;
  }

  refresh() {
    this.fetchErrorData();
  }

  async fetchErrorData() {
    try {
      this.loading = true;
      this.apiError = null;

      const payload = {
        action: 'get_business_rules_error_json',
        location:
          locationMappers[this.data['event'].venue_name] ||
          this.data['event'].venue_name,
        race_number: this.data['race'].SquentialRaceOrderNumber,
        race_id: this.data['race'].ExternalRaceId,
        race_date: this.data['event']['date'],
        type: getRaceType(this.data['event']['external_event_id'])[
          'raceTypeStr'
        ],
        event_id: this.data['event']['external_event_id'],
        jurisdiction: this.data['event']['JurisdictionCode'],
        pc_number: this.data['event']['pc'],
        venue_id: this.data['event']['external_venue_id'],
      };

      let result = await this.apiService.postDataPromis(
        `${this.config[getENV()].raceAPI}/flask-operations`,
        payload
      );

      if (result.status === '1') {
        // Map the errors array based on race type
        if (result.errors.length > 0) {
          this.errorData = result.errors.map((error: ErrorData) => ({
            HorseName: error.HorseName,
            Section: error.Section,
            Metric: error.Metric,
            Value: error.Value,
            Expected: error.Expected,
            RaceLength: error.RaceLength,
            Status: error.Status,
            // Only include SegmentNumber if it exists (for non-Thoroughbred races)
            ...(error.SegmentNumber !== undefined && {
              SegmentNumber: error.SegmentNumber,
            }),
          }));

          // Group errors by horse name
          this.groupedErrorData = this.errorData.reduce((acc, error) => {
            if (!acc[error.HorseName]) {
              acc[error.HorseName] = [];
            }
            acc[error.HorseName].push(error);
            return acc;
          }, {} as { [key: string]: ErrorData[] });
        }
      } else {
        this.apiError = result.error_message || 'Failed to fetch data';
      }

      this.loading = false;
    } catch (error) {
      this.apiError = 'An error occurred while fetching data';
    } finally {
      this.loading = false;
    }
  }

  backToReports() {
    const params = this.route.snapshot.queryParams;

    // Format date as "DD MMMM YYYY"
    const formattedDate = moment(params['race_date']).format('DD MMMM YYYY');

    this.router.navigate(['/dash/post-race/race-reports'], {
      queryParams: {
        event_id: params['external_event_id'],
        date: formattedDate,
      },
    });
  }

  async ignoreBussinessRules() {
    try {
      this.ignoringRules = true;
      this.apiError = null;

      const payload = {
        action: 'result_processor_trigger',
        location:
          locationMappers[this.data['event'].venue_name] ||
          this.data['event'].venue_name,
        type: this.data['event']['race_type'],
        jurisdiction: this.data['event'].JurisdictionCode,
        race_number: this.data['race'].SquentialRaceOrderNumber,
        race_date: this.data['event'].date,
      };

      const result = await this.apiService.postDataPromis(
        `${this.config[getENV()].raceAPI}/flask-operations`,
        payload
      );

      if (result.status === '1') {
        this.notifier.alert(
          'Success',
          '',
          'Business rules ignored successfully',
          'success'
        );
        await this.fetchErrorData(); // Refresh data
      } else {
        this.apiError =
          result.error_message || 'Failed to ignore business rules';
        this.notifier.alert('Error', '', this.apiError, 'error');
      }
    } catch (error) {
      this.apiError = error.message;
      this.notifier.alert('Error', '', this.apiError, 'error');
    } finally {
      this.ignoringRules = false;
    }
  }

  private isSpeedMetric(metric: string): boolean {
    return this.SPEED_METRICS.includes(metric);
  }

  private convertMsToKmh(speedMs: number): number {
    return speedMs * 3.6;
  }

  public formatMetricName(metric: string): string {
    return metric
      .split(/(?=[A-Z0-9])/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  getDisplayValue(value: number, metric: string): string {
    if (this.isSpeedMetric(metric)) {
      return `${this.convertMsToKmh(value).toFixed(2)} km/h`;
    }
    return value.toFixed(2);
  }

  getExpectedRange(expected: (number | null)[], metric: string): string {
    if (!expected || !Array.isArray(expected) || expected.length !== 2) {
      return 'N/A';
    }

    const [min, max] = expected;
    const unit = this.isSpeedMetric(metric) ? ' km/h' : '';

    if (min === null && max === null) return 'No Range';
    if (min === null) {
      const maxValue = this.isSpeedMetric(metric)
        ? this.convertMsToKmh(max)
        : max;
      return `Up to ${maxValue.toFixed(2)}${unit}`;
    }
    if (max === null) {
      const minValue = this.isSpeedMetric(metric)
        ? this.convertMsToKmh(min)
        : min;
      return `From ${minValue.toFixed(2)}${unit}`;
    }

    const minValue = this.isSpeedMetric(metric)
      ? this.convertMsToKmh(min)
      : min;
    const maxValue = this.isSpeedMetric(metric)
      ? this.convertMsToKmh(max)
      : max;
    return `${minValue.toFixed(2)} - ${maxValue.toFixed(2)}${unit}`;
  }

  calculateDeviation(
    value: number,
    expected: number[],
    metric: string
  ): { type: string; value: string } {
    // Return early if both expected values are null
    if (!expected || expected.length !== 2 || (expected[0] === null && expected[1] === null)) {
      return { type: 'neutral', value: '' }; // Empty string instead of 'N/A'
    }

    const unit = this.isSpeedMetric(metric) ? ' km/h' : '';
    const convertedValue = this.isSpeedMetric(metric)
      ? this.convertMsToKmh(value)
      : value;
    const [min, max] = expected.map((val) =>
      val === null ? val : (this.isSpeedMetric(metric) ? this.convertMsToKmh(val) : val)
    );

    if (min !== null && convertedValue < min) {
      return {
        type: 'low',
        value: `-${(min - convertedValue).toFixed(2)}${unit}`,
      };
    }
    if (max !== null && convertedValue > max) {
      return {
        type: 'high',
        value: `+${(convertedValue - max).toFixed(2)}${unit}`,
      };
    }
    return { type: 'neutral', value: '0.00' };
  }

  get totalChecks(): number {
    return this.errorData.length;
  }

  get passedChecks(): number {
    return this.errorData.filter((e) => e.Status).length;
  }

  get failedChecks(): number {
    return this.errorData.filter((e) => !e.Status).length;
  }

  get filteredGroupedErrorData(): { [key: string]: ErrorData[] } {
    if (this.filterStatus === 'ALL') {
      return this.groupedErrorData;
    }

    const filtered = {};
    Object.keys(this.groupedErrorData).forEach((horseName) => {
      const filteredErrors = this.groupedErrorData[horseName].filter((error) =>
        this.filterStatus === 'PASS' ? error.Status : !error.Status
      );

      if (filteredErrors.length > 0) {
        filtered[horseName] = filteredErrors;
      }
    });

    return filtered;
  }

  get doubleGroupedErrorData(): {
    [horseName: string]: { [metric: string]: ErrorData[] };
  } {
    const grouped: { [horseName: string]: { [metric: string]: ErrorData[] } } =
      {};

    Object.entries(this.filteredGroupedErrorData).forEach(
      ([horseName, errors]) => {
        grouped[horseName] = {};

        errors.forEach((error) => {
          if (!grouped[horseName][error.Metric]) {
            grouped[horseName][error.Metric] = [];
          }
          grouped[horseName][error.Metric].push(error);
        });
      }
    );

    return grouped;
  }

  getMetricRowCount(horseMetrics: { [metric: string]: ErrorData[] }): number {
    return Object.values(horseMetrics).reduce(
      (total, errors) => total + errors.length,
      0
    );
  }

  getMetricLength(errors: ErrorData[]): number {
    return errors?.length || 0;
  }

  setFilter(status: 'ALL' | 'PASS' | 'FAIL') {
    this.filterStatus = status;
  }

  openTolUpload() {
    this.showTolUpload = true;
  }

  closeTolUpload() {
    this.showTolUpload = false;
  }
}
