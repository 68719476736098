import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getENV, getRaceType } from 'src/app/_helpers/helpers';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { VenueDetailsService } from '../../../settings/services/venue-details.service';

// Add declaration for CKEDITOR at the top of the file
declare var CKEDITOR: any;

@Component({
  selector: 'app-jira-tracker',
  templateUrl: './jira-tracker.component.html',
  styleUrls: ['./jira-tracker.component.css'],
})
export class JiraTrackerComponent implements OnInit {
  @Input('venue_id') venue_id: string;
  @Input('venue_name') venue_name: string;
  @Input('jurisdiction') jurisdiction: string;
  @Output('hideModal') hideModal: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  ngOnInit(): void {
    this.loadIssuesCaller();
  }

  requestTypes = [
    {
      title: 'Racing Operations Centre (ROC) UI (IMB)',
      name: 'ROC Issues',
      slug: 'roc-ui',
    },
    {
      title: 'Venue and Hardware issues (IMB)',
      name: 'Hardware Issues',
      slug: 'venue-hardware',
    },
    {
      title: 'Tag Incident Management (IMB)',
      name: 'Tag Incidents',
      slug: 'tag-incident',
    },
  ];

  loadIssuesCaller() {
    this.requestTypes.forEach((requestType) => {
      this.loadIssues(undefined, requestType);
    });
    this.loadVenue();
  }

  locationMappers = {
    'Royal Randwick': 'Royal Randwick / Kensington',
    Kensington: 'Royal Randwick / Kensington',
  };

  config: any;
  constructor(
    private activeRoute: ActivatedRoute,
    private apiService: APIService,
    private notifier: NotifierService,
    private venueService: VenueDetailsService
  ) {
    this.config = this.activeRoute.snapshot.data['config'];
  }

  issues: { [key: string]: any[] } = {
    'roc-ui': [],
    'venue-hardware': [],
    'tag-incident': [],
  };
  selectedRequestType: string = 'roc-ui';
  loading = {
    issues: true,
    comm: false,
    notes: false, // Add notes loading state
    'roc-ui': false,
    'venue-hardware': false,
    'tag-incident': false,
  };

  async loadIssues(
    next_token: string = undefined,
    requestType: any = undefined
  ) {
    if (!next_token) {
      this.issues[requestType['slug']] = [];
    }
    this.loading[requestType.slug] = true;
    let payload: any = {
      action: 'jira_list',
      jira_project_key: 'IMB',
      jira_organization_key: 'Minfy Technologies',
      location: this.locationMappers[this.venue_name] || this.venue_name,
      jurisdiction: this.jurisdiction,
      request_type: requestType['title'],
      next_token: next_token,
      include_resolved: false,
    };

    let apiURL: string = `${this.config[getENV()].raceAPI}/jira-operations`;

    let result: any = await this.apiService.postDataPromis(apiURL, payload, {});

    if (result.status == '1' || result.s == '1') {
      result.issues = result.issues
        .filter((issue: any) => {
          const status = issue.status.toLowerCase();
          return status !== 'done' && status !== 'cancelled';
        })
        .map((issue: any) => {
          if (typeof issue.description == 'object') {
            issue.description = '';
          }
          issue.statusStr = issue.status.toLowerCase().replaceAll(' ', '-');
          return issue;
        });
      this.issues[requestType['slug']] = [
        ...this.issues[requestType['slug']],
        ...result.issues,
      ];
      if (result.next_token) {
        this.loadIssues(result.next_token, requestType);
      }
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }

    this.loading[requestType.slug] = false;
  }

  getInitials(name: string): string {
    return name
      .split(' ')
      .map((n) => n[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  }

  selectedIssue: any;

  async selectIssue(issue: any) {
    this.selectedIssue = issue;
    this.loading['comm'] = true;
    let payload: any = {
      action: 'jira_comm',
      issue_key: issue.issue_key,
    };

    let apiURL: string = `${this.config[getENV()].raceAPI}/jira-operations`;
    let result: any = await this.apiService.postDataPromis(apiURL, payload, {});

    if (result.status == '1' || result.s == '1') {
      if (result.issues_details.status_history) {
        result.issues_details.status_history =
          result.issues_details.status_history.reverse();
      }
      this.selectedIssue = {
        ...this.selectedIssue,
        ...result.issues_details,
      };
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
    this.loading['comm'] = false;
  }

  notes: string = '';
  async loadVenue() {
    try {
      this.loading['notes'] = true;
      const venueDetails = await this.venueService.getLocationDetails(
        this.config,
        this.venue_id,
        getRaceType(this.venue_id).raceTypeStr,
        this.venue_name
      );
      if (venueDetails && venueDetails.venues) {
        this.notes = venueDetails.venues.Notes;
      }
    } catch (error) {
      console.error('Error loading venue details:', error);
      this.notifier.alert('Error', '', 'Failed to load venue notes', 'error', 5000);
    } finally {
      this.loading['notes'] = false;
    }
  }

  isEditingNotes: boolean = false;
  editorInstance: any;

  toggleNotesEdit() {
    this.isEditingNotes = true;
    setTimeout(() => {
      this.initializeCKEditor();
    });
  }

  cancelNotesEdit() {
    this.isEditingNotes = false;
    if (this.editorInstance) {
      this.editorInstance.destroy();
      this.editorInstance = null;
    }
  }

  initializeCKEditor() {
    this.editorInstance = CKEDITOR.replace('venueNotesEditor', {
      contentsCss: ['https://cdn.ckeditor.com/4.16.0/full-all/contents.css'],
      fontSize_defaultLabel: '20px',
      removeButtons: 'Image,Iframe'
    });
    this.editorInstance.setData(this.notes || '');
  }

  async saveVenueNotes() {
    try {
      this.loading['notes'] = true;
      const notes = this.editorInstance.getData();

      const response = await this.venueService.saveVenueNotes(
        this.config,
        this.venue_id,
        notes
      );

      if (response.status === '1' || response.s === '1') {
        this.notes = notes;
        this.notifier.alert('Success', '', 'Notes saved successfully', 'success', 5000);
        this.isEditingNotes = false;
        this.editorInstance?.destroy();
        this.editorInstance = null;
      } else {
        this.notifier.alert('Error', '', 'Failed to save notes', 'error', 5000);
      }
    } catch (error) {
      console.error('Error saving venue notes:', error);
      this.notifier.alert('Error', '', 'Failed to save notes', 'error', 5000);
    } finally {
      this.loading['notes'] = false;
    }
  }
}
