import { Injectable } from '@angular/core';
import { APIService } from 'src/app/services/api.service';
import { getENV } from 'src/app/_helpers/helpers';
import { firstValueFrom } from 'rxjs';
import { DevicesService } from 'src/app/services/devices.service';
import { reverseLocationMappersVenueConfig } from '../../pages/components/post-race/harness-reports/components/race-reports/locations';

@Injectable({
  providedIn: 'root',
})
export class VenueDetailsService {
  private apiKey = 'e2bvuAoslY1VJvFMqhr5x7nxoEhFVRii9jRngS3U';

  constructor(private apiService: APIService, private deviceService: DevicesService) {}

  private getHeaders() {
    return {
      'x-api-key': this.apiKey,
    };
  }

  getLocationDetails(
    config: any,
    externalVenueId: string,
    type: string,
    locationName: string
  ): Promise<any> {
    const payload = {
      action: 'get_location_details',
      external_venue_id: externalVenueId,
      type,
      location_name: reverseLocationMappersVenueConfig[locationName] || locationName,
    };
    const apiURL = `${config[getENV()].raceAPI}/flask-operations`;
    return this.apiService.postDataPromis(apiURL, payload, this.getHeaders());
  }

  getKitDetails(config: any, kitName: string): Promise<any> {
    const payload = {
      action: 'get_kit_details',
      kit_name: kitName,
    };
    const apiURL = `${config[getENV()].raceAPI}/flask-operations`;
    return this.apiService.postDataPromis(apiURL, payload, this.getHeaders());
  }

  getJurisdictions(config: any, type: string): Promise<any> {
    const payload = {
      action: 'get_jurisdictions',
      type,
    };
    const apiURL = `${config[getENV()].raceAPI}/flask-operations`;
    return this.apiService.postDataPromis(apiURL, payload, this.getHeaders());
  }

  venueConfiguration(config: any, data: any): Promise<any> {
    const payload = {
      action: 'venue_configuration',
      data,
    };
    const apiURL = `${config[getENV()].raceAPI}/flask-operations`;
    return this.apiService.postDataPromis(apiURL, payload, this.getHeaders());
  }

  loadVenues(config: any) {
    const apiURL = `${config[getENV()].raceAPI}/flask-operations?action=get_venues`;
    return this.apiService.getDataPromis(apiURL, {}, this.getHeaders());
  }

  async getKits(config: any, venueId: string): Promise<any> {
    return new Promise(async (resolve: any) => {
      let apiURL: string = `${config[getENV()].raceAPI}/flask-operations?action=get_kits`;

      let header: any = {
        'X-Api-Key': this.apiKey,
      };
      let result: any = await this.apiService.getDataPromis(apiURL, {}, header);
      if (result.kits) {
        let kits: any[] = [];
        const kit_range = result.kits;
        kit_range.forEach((e: any) => {
          kits.push(e.name);
        });

        resolve({ kits });
      } else {
        resolve(null);
      }
    });
  }

  async saveVenueNotes(config: any, venueId: string, notes: string): Promise<any> {
    const payload = {
      action: 'save_venue_notes',
      venue_id: venueId,
      notes: notes,
    };
    const apiURL = `${config[getENV()].raceAPI}/flask-operations`;
    return this.apiService.postDataPromis(apiURL, payload, this.getHeaders());
  }
}
