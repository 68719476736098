import { Injectable } from '@angular/core';
import { APIService } from 'src/app/services/api.service';
import { getENV } from 'src/app/_helpers/helpers';

interface BusinessRulesResponse {
  status: string;
  response?: {
    [key: string]: {
      ranges: Array<{
        race_length_min: number;
        race_length_max: number | null;
        min?: number;
        max?: number;
        sections?: { [key: string]: [number, number] };
        segments?: { [key: string]: [number, number] };
      }>;
    };
  };
  error_message?: string;
}

@Injectable({
  providedIn: 'root'
})
export class BusinessRulesService {
  private apiKey = 'e2bvuAoslY1VJvFMqhr5x7nxoEhFVRii9jRngS3U';

  constructor(private apiService: APIService) {}

  private getHeaders() {
    return {
      'x-api-key': this.apiKey,
    };
  }

  getBusinessRules(config: any, type: 'harness' | 'thoroughbred'): Promise<BusinessRulesResponse> {
    const payload = {
      action: 'get_business_rules_validation_values',
      type
    };
    const apiURL = `${config[getENV()].raceAPI}/flask-operations`;
    return this.apiService.postDataPromis(apiURL, payload, this.getHeaders());
  }

  saveBusinessRules(config: any, type: string, data: any): Promise<any> {
    const payload = {
      action: 'configure_business_values_rules',
      type,
      data
    };
    const apiURL = `${config[getENV()].raceAPI}/flask-operations`;
    return this.apiService.postDataPromis(apiURL, payload, this.getHeaders());
  }
}
