<div class="card">
  <div class="card-header flex-between">
    <div class="flex-start">
      <h5 class="mb-0">Judges</h5>
    </div>
    <input type="text" class="datetimepicker" />
  </div>
  <div class="card-body">
    <div>
      <div class="form-group mt-2">
        <label>Select your Event</label>
        <post-race-location-picker
          [locations]="locations"
          [selected]="selected"
          (selectedLocation)="selectEvent($event)"
          type="event"
        ></post-race-location-picker>
        <div *ngIf="locations.length == 0">
          No Events Found on <b>{{ date }}</b>
        </div>
      </div>

      <div class="form-group mt-2">
        <div class="flex-between">
          <label class="mb-0">Races</label>
        </div>
        <div class="table-responsive">
          <table class="table races-table">
            <thead>
              <tr>
                <th>Race Number</th>
                <th>Venue</th>
                <th>Race Name</th>
                <th>Live Race Status</th>
                <th>Runner Status</th>
                <th>
                  Race Time
                  <div class="small text-muted">{{ date }}</div>
                </th>
                <th>Report Status</th>
                <th *ngIf="selected?.RaceType == 'Greyhound Racing'">ARM</th>
                <th *ngIf="selected?.RaceType == 'Greyhound Racing'">RESET</th>
                <th>Upload LIF</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let race of races; let i = index">
                <tr [ngClass]="getRaceStatusClass(race)">
                  <td class="text-center">
                    #{{ race.SquentialRaceOrderNumber }}
                  </td>
                  <td class="text-center">{{ selected?.venue_name }}</td>
                  <td class="text-center">
                    <span class="nav-link" (click)="toggleRaceDetails(race)">
                      {{ race.RaceName }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      class="status-badge"
                      [ngClass]="race.RaceState?.toLowerCase()"
                    >
                      {{ race.RaceState }}
                    </span>
                  </td>
                  <td class="text-center">
                    <app-horsestatus [data]="race.status"></app-horsestatus>
                  </td>
                  <td class="text-center">
                    <div class="flex-center">
                      <div [innerHTML]="race.RaceTimeHTML"></div>
                      <i
                        class="fa fa-pencil pointer ms-2"
                        (click)="openRaceTimePopup(race)"
                        *ngIf="
                          !race.loading &&
                          (race.RaceState?.toLowerCase() === 'armed' ||
                          race.RaceState?.toLowerCase() === 'scheduled')
                        "
                      >
                      </i>
                    </div>
                  </td>
                  <td class="text-center">
                    <app-reportstatus
                      [data]="race.buttonActions"
                    ></app-reportstatus>
                  </td>
                  <td
                    class="text-center"
                    *ngIf="selected?.RaceType == 'Greyhound Racing'"
                  >
                    <div class="flex-center">
                      <button
                        class="btn btn-sm flex-start btn-primary-soft"
                        [disabled]="
                          race.loading ||
                          race.RaceState?.toLowerCase() != 'scheduled'
                        "
                        (click)="confirmPopup(race, 'ARM')"
                      >
                        <i
                          class="fa color-inherit"
                          *ngIf="
                            race.RaceState?.toLowerCase() != 'scheduled' || race.loading
                          "
                          [ngClass]="{
                            'fa-spinner fa-spin': race.loading,
                            'fa-check-circle': !race.loading || race.RaceState?.toLowerCase() != 'scheduled'
                          }"
                        ></i>
                        ARM
                      </button>
                    </div>
                  </td>
                  <td
                    class="text-center"
                    *ngIf="selected?.RaceType == 'Greyhound Racing'"
                  >
                    <div class="flex-center">
                      <span>
                        <button
                          class="btn btn-sm flex-start btn-primary-soft"
                          [disabled]="
                            race.loading
                          "
                          (click)="confirmPopup(race, 'RESET')"
                        >
                          <i
                            class="fa color-inherit"
                            [ngClass]="{
                              'fa-spinner fa-spin': race.loading,
                              'fa-refresh': !race.loading
                            }"
                          ></i>
                          RESET
                        </button>
                      </span>
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="flex-center">
                      <button
                        class="btn btn-sm flex-start btn-success-soft"
                        [disabled]="race.loading || !canUploadLIF(race)"
                        (click)="uploadFile($event, race, 'normal_lip')"
                      >
                        <i
                          class="fa color-inherit"
                          [ngClass]="{
                            'fa-spinner fa-spin': race.loading,
                            'fa-upload': !race.loading
                          }"
                        ></i>
                        Upload LIF
                      </button>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="race.isExpanded">
                  <td colspan="10">
                    <div class="p-3">
                      <div class="flex-center p-2" *ngIf="race.loading">
                        <span class="spinner-border spinner-border-sm"></span>
                      </div>
                      <div *ngIf="!race.loading">
                        <table class="table table-docs mb-0">
                          <thead>
                            <tr>
                              <th>SCN</th>
                              <th>Barrier/Box</th>
                              <th>Image</th>
                              <th>Animal Name</th>
                              <th>State</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let entrant of race.entrants">
                              <td>{{ entrant.StartNumber }}</td>
                              <td>{{ entrant.BarrierNumber }}</td>
                              <td>
                                <img
                                  [src]="entrant.imagePath"
                                  alt="Image"
                                  style="height: 40px"
                                />
                              </td>
                              <td>{{ entrant.AnimalName }}</td>
                              <td>
                                <div class="flex-center ml-4">
                                  <select [(ngModel)]="entrant.RaceStateNew">
                                    <option
                                      [value]="status.value"
                                      *ngFor="let status of horse_race_states"
                                    >
                                      {{ status.name }}
                                    </option>
                                  </select>
                                  <button
                                    [ngStyle]="{
                                      visibility:
                                        entrant.RaceState !=
                                        entrant.RaceStateNew
                                          ? 'visible'
                                          : 'hidden'
                                    }"
                                    (click)="saveHorseStatus(race)"
                                    class="btn btn-danger-soft"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #showSpinner>
  <span class="spinner-border spinner-border-sm"></span>
</ng-template>

<upload-file-lip *ngIf="uploadFileObj" [config]="config" [meta]="uploadFileObj">
  <button
    ngProjectAs="closePopup"
    class="btn btn-light"
    [disabled]="
      uploadFileObj.uploadStatus && uploadFileObj.uploadStatus != 'COMPLETED'
        ? true
        : null
    "
    (click)="closeUploadLIF()"
  >
    Close
  </button>
</upload-file-lip>
<eod-confirm-box
  *ngIf="actionConfirmation.race"
  title="Confirm Action"
  message=""
  (hideModel)="closePopup($event)"
  [buttons]="['confirm', 'close']"
>
  <div ngProjectAs="main-body">
    <p>
      Are you sure you want to {{ actionConfirmation.action }} Race #{{
        actionConfirmation.race.SquentialRaceOrderNumber
      }}?
    </p>
  </div>
</eod-confirm-box>

<!-- Add the race time popup at the bottom of the template -->
<div *ngIf="showRaceTimePopup" class="popup-screen">
  <div class="popup-container">
    <div class="popup-header">
      Update Race Time
      <button type="button" class="close" (click)="closeRaceTimePopup()">
        &times;
      </button>
    </div>
    <div class="popup-body">
      <div class="flex-start align-items-start">
        <div>
          <label>Current Race Time: </label>
          <div>{{ selectedRace?.RaceTime }}</div>
        </div>
        <div class="arrow">→</div>
        <div>
          <label>New Race Time: </label>
          <div class="flex-start">
            <select class="form-select" [(ngModel)]="newRaceTime.hour">
              <option *ngFor="let hour of hours" [value]="hour">{{ hour }}</option>
            </select>
            :
            <select class="form-select" [(ngModel)]="newRaceTime.minute">
              <option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</option>
            </select>
          </div>
          <div class="flex-start">
            <span class="nav-link pointer" (click)="addMinutes(5)">+5 mins</span>
            <span class="nav-link pointer" (click)="addMinutes(15)">+15 mins</span>
            <span class="nav-link pointer" (click)="addMinutes(30)">+30 mins</span>
          </div>
        </div>
      </div>
      <div class="mt-3 flex-end">
        <button class="btn btn-primary-soft" (click)="resetRaceTIme()" *ngIf="selectedRace.timeChanged">Reset</button>
        <button class="btn btn-success-soft" (click)="updateRaceTime()">Update</button>
        <button class="btn btn-light" (click)="closeRaceTimePopup()">Cancel</button>
      </div>
    </div>
  </div>
</div>
