import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { ActivatedRoute } from '@angular/router';
import { getENV } from 'src/app/_helpers/helpers';

@Component({
  selector: 'app-ssm-pc-control',
  templateUrl: './ssm-pc-control.component.html',
  styleUrls: ['./ssm-pc-control.component.css'],
})
export class SsmPcControlComponent implements OnInit {
  @Input('pc') pc: any;
  @Output() hideModel: EventEmitter<void> = new EventEmitter();
  selectedTab: string = 'system';
  selectedTimeZone: string = 'Australia/Sydney';
  selectedDate: string;
  selectedHour: string;
  selectedMinute: string;
  hours: number[] = Array.from({ length: 24 }, (_, i) => i);
  minutes: number[] = Array.from({ length: 60 }, (_, i) => i);
  searchTerm: string = '';

  applications: any = [];
  processes: any = [];
  searchTermProcesses: string = '';

  times = [
    {
      country: 'Australia',
      city: 'Sydney',
      timeZone: 'Australia/Sydney',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Australia/Sydney',
      }),
      name: 'AUS Eastern Standard Time',
      id: 'Australia/Sydney',
      diff: 'UTC +10:00',
    },
    {
      country: 'Australia',
      city: 'Melbourne',
      timeZone: 'Australia/Melbourne',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Australia/Melbourne',
      }),
      name: 'AUS Eastern Standard Time',
      id: 'Australia/Melbourne',
      diff: 'UTC +10:00',
    },
    {
      country: 'Australia',
      city: 'Brisbane',
      timeZone: 'Australia/Brisbane',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Australia/Brisbane',
      }),
      name: 'AUS Eastern Standard Time',
      id: 'Australia/Brisbane',
      diff: 'UTC +10:00',
    },
    {
      country: 'Australia',
      city: 'Perth',
      timeZone: 'Australia/Perth',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Australia/Perth',
      }),
      name: 'W. Australia Standard Time',
      id: 'Australia/Perth',
      diff: 'UTC +08:00',
    },
    {
      country: 'Australia',
      city: 'Adelaide',
      timeZone: 'Australia/Adelaide',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Australia/Adelaide',
      }),
      name: 'Cen. Australia Standard Time',
      id: 'Australia/Adelaide',
      diff: 'UTC +09:30',
    },
    {
      country: 'Australia',
      city: 'Darwin',
      timeZone: 'Australia/Darwin',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Australia/Darwin',
      }),
      name: 'AUS Central Standard Time',
      id: 'Australia/Darwin',
      diff: 'UTC +09:30',
    },
    {
      country: 'Australia',
      city: 'Hobart',
      timeZone: 'Australia/Hobart',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Australia/Hobart',
      }),
      name: 'Tasmania Standard Time',
      id: 'Australia/Hobart',
      diff: 'UTC +10:00',
    },
    {
      country: 'Afghanistan',
      timeZone: 'Asia/Kabul',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Asia/Kabul',
      }),
      name: 'Afghanistan Standard Time',
      id: 'Asia/Kabul',
      diff: 'UTC +04:30',
    },
    {
      country: 'Albania',
      timeZone: 'Europe/Tirane',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Europe/Tirane',
      }),
      name: 'Central Europe Standard Time',
      id: 'Europe/Tirane',
      diff: 'UTC +01:00',
    },
    {
      country: 'Algeria',
      timeZone: 'Africa/Algiers',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Africa/Algiers',
      }),
      name: 'W. Central Africa Standard Time',
      id: 'Africa/Algiers',
      diff: 'UTC +01:00',
    },
    {
      country: 'Denmark',
      timeZone: 'Europe/Copenhagen',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Europe/Copenhagen',
      }),
      name: 'Romance Standard Time',
      id: 'Europe/Copenhagen',
      diff: 'UTC +01:00',
    },
    {
      country: 'Ecuador',
      timeZone: 'America/Guayaquil',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'America/Guayaquil',
      }),
      name: 'SA Pacific Standard Time',
      id: 'America/Guayaquil',
      diff: 'UTC -05:00',
    },
    {
      country: 'Egypt',
      timeZone: 'Africa/Cairo',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Africa/Cairo',
      }),
      name: 'Egypt Standard Time',
      id: 'Africa/Cairo',
      diff: 'UTC +02:00',
    },
    {
      country: 'Finland',
      timeZone: 'Europe/Helsinki',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Europe/Helsinki',
      }),
      name: 'FLE Standard Time',
      id: 'Europe/Helsinki',
      diff: 'UTC +02:00',
    },
    {
      country: 'France',
      timeZone: 'Europe/Paris',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Europe/Paris',
      }),
      name: 'Romance Standard Time',
      id: 'Europe/Paris',
      diff: 'UTC +01:00',
    },
    {
      country: 'Germany',
      timeZone: 'Europe/Berlin',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Europe/Berlin',
      }),
      name: 'W. Europe Standard Time',
      id: 'Europe/Berlin',
      diff: 'UTC +01:00',
    },
    {
      country: 'Greece',
      timeZone: 'Europe/Athens',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Europe/Athens',
      }),
      name: 'GTB Standard Time',
      id: 'Europe/Athens',
      diff: 'UTC +02:00',
    },
    {
      country: 'Hungary',
      timeZone: 'Europe/Budapest',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Europe/Budapest',
      }),
      name: 'Central Europe Standard Time',
      id: 'Europe/Budapest',
      diff: 'UTC +01:00',
    },
    {
      country: 'India',
      timeZone: 'Asia/Kolkata',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Asia/Kolkata',
      }),
      name: 'India Standard Time',
      id: 'Asia/Kolkata',
      diff: 'UTC +05:30',
    },
    {
      country: 'Indonesia',
      timeZone: 'Asia/Jakarta',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Asia/Jakarta',
      }),
      name: 'SE Asia Standard Time',
      id: 'Asia/Jakarta',
      diff: 'UTC +07:00',
    },
    {
      country: 'Japan',
      timeZone: 'Asia/Tokyo',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Asia/Tokyo',
      }),
      name: 'Tokyo Standard Time',
      id: 'Asia/Tokyo',
      diff: 'UTC +09:00',
    },
    {
      country: 'Kazakhstan',
      timeZone: 'Asia/Almaty',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Asia/Almaty',
      }),
      name: 'Central Asia Standard Time',
      id: 'Asia/Almaty',
      diff: 'UTC +06:00',
    },
    {
      country: 'Korea',
      timeZone: 'Asia/Seoul',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Asia/Seoul',
      }),
      name: 'Korea Standard Time',
      id: 'Asia/Seoul',
      diff: 'UTC +09:00',
    },
    {
      country: 'Malaysia',
      timeZone: 'Asia/Kuala_Lumpur',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Asia/Kuala_Lumpur',
      }),
      name: 'Singapore Standard Time',
      id: 'Asia/Kuala_Lumpur',
      diff: 'UTC +08:00',
    },
    {
      country: 'Mexico',
      timeZone: 'America/Mexico_City',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'America/Mexico_City',
      }),
      name: 'Central Standard Time (Mexico)',
      id: 'America/Mexico_City',
      diff: 'UTC -06:00',
    },
    {
      country: 'Netherlands',
      timeZone: 'Europe/Amsterdam',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Europe/Amsterdam',
      }),
      name: 'W. Europe Standard Time',
      id: 'Europe/Amsterdam',
      diff: 'UTC +01:00',
    },
    {
      country: 'New Zealand',
      timeZone: 'Pacific/Auckland',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Pacific/Auckland',
      }),
      name: 'New Zealand Standard Time',
      id: 'Pacific/Auckland',
      diff: 'UTC +12:00',
    },
    {
      country: 'Norway',
      timeZone: 'Europe/Oslo',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Europe/Oslo',
      }),
      name: 'W. Europe Standard Time',
      id: 'Europe/Oslo',
      diff: 'UTC +01:00',
    },
    {
      country: 'Pakistan',
      timeZone: 'Asia/Karachi',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Asia/Karachi',
      }),
      name: 'Pakistan Standard Time',
      id: 'Asia/Karachi',
      diff: 'UTC +05:00',
    },
    {
      country: 'Philippines',
      timeZone: 'Asia/Manila',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Asia/Manila',
      }),
      name: 'Singapore Standard Time',
      id: 'Asia/Manila',
      diff: 'UTC +08:00',
    },
    {
      country: 'Poland',
      timeZone: 'Europe/Warsaw',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Europe/Warsaw',
      }),
      name: 'Central European Standard Time',
      id: 'Europe/Warsaw',
      diff: 'UTC +01:00',
    },
    {
      country: 'Russia',
      timeZone: 'Europe/Moscow',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Europe/Moscow',
      }),
      name: 'Russian Standard Time',
      id: 'Europe/Moscow',
      diff: 'UTC +03:00',
    },
    {
      country: 'Singapore',
      timeZone: 'Asia/Singapore',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Asia/Singapore',
      }),
      name: 'Singapore Standard Time',
      id: 'Asia/Singapore',
      diff: 'UTC +08:00',
    },
    {
      country: 'South Africa',
      timeZone: 'Africa/Johannesburg',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Africa/Johannesburg',
      }),
      name: 'South Africa Standard Time',
      id: 'Africa/Johannesburg',
      diff: 'UTC +02:00',
    },
    {
      country: 'Spain',
      timeZone: 'Europe/Madrid',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Europe/Madrid',
      }),
      name: 'Romance Standard Time',
      id: 'Europe/Madrid',
      diff: 'UTC +01:00',
    },
    {
      country: 'Sweden',
      timeZone: 'Europe/Stockholm',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Europe/Stockholm',
      }),
      name: 'W. Europe Standard Time',
      id: 'Europe/Stockholm',
      diff: 'UTC +01:00',
    },
    {
      country: 'United Kingdom',
      timeZone: 'Europe/London',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'Europe/London',
      }),
      name: 'GMT Standard Time',
      id: 'Europe/London',
      diff: 'UTC +00:00',
    },
    {
      country: 'United States',
      timeZone: 'America/New_York',
      currentTime: new Date().toLocaleString('en-US', {
        timeZone: 'America/New_York',
      }),
      name: 'Eastern Standard Time',
      id: 'America/New_York',
      diff: 'UTC -05:00',
    },
  ];

  config: any;
  isLoading: boolean = false;
  isSettingTimeZone: boolean = false;
  isSettingDateTime: boolean = false;
  isStartingPC: boolean = false;
  isStoppingPC: boolean = false;
  isStartingApplication: { [key: string]: boolean } = {};
  isStoppingApplication: { [key: string]: boolean } = {};
  isKillingProcess: { [key: string]: boolean } = {};
  isLoadingApplications: boolean = false;
  isLoadingProcesses: boolean = false;
  isLoadingDateTime: boolean = false;
  isLoadingTimeZone: boolean = false;

  constructor(
    private apiService: APIService,
    private notifier: NotifierService,
    private router: ActivatedRoute
  ) {
    this.config = this.router.snapshot.data['config'];
  }

  ngOnInit() {
    this.getCurrentDateTime();
    this.getCurrentTimeZone();
    this.getApplications();
    this.getProcesses();
    this.getPCStatus();
  }

  selectTab(tabId: string) {
    this.selectedTab = tabId;
  }

  async getCommandStatusAPICaller(commandId: string, commandType: string) {
    const apiURL = `${this.config[getENV()].raceAPI}/flask-operations`;
    const payload = {
      action: 'ssm_get_command',
      command_type: commandType,
      pc_name: this.pc.name,
      command_id: commandId,
    };

    const response = await this.apiService.postDataPromis(apiURL, payload);

    return response;
  }

  async pollCommandStatus(
    commandId: string,
    commandType: string
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await this.getCommandStatusAPICaller(
          commandId,
          commandType
        );

        if (response.status === '0') {
          this.notifier.alert(
            'Error',
            '',
            response.error_message || 'Command execution failed',
            'error',
            5000
          );
          resolve({ command_status: 'Failed', error: response.error_message });
          return;
        }

        if (response.status === '1') {
          response.response.command_status =
            response?.response.command_status ||
            response?.response.status ||
            'NOT FOUND';

          if (
            response?.response &&
            (response?.response?.command_status === 'InProgress' ||
              response?.response?.command_status === 'Pending')
          ) {
            setTimeout(async () => {
              const statusResponse = await this.pollCommandStatus(
                commandId,
                commandType
              );
              resolve(statusResponse);
            }, 3000);
          } else {
            resolve(response.response);
          }
        } else {
          resolve({ command_status: 'Failed', error: 'Invalid response status' });
        }
      } catch (error) {
        console.error('Error polling command status:', error);
        this.notifier.alert(
          'Error',
          '',
          'Failed to check command status',
          'error',
          5000
        );
        resolve({ command_status: 'Failed', error: error.message });
      }
    });
  }

  async getApplications() {
    this.isLoadingApplications = true;
    const apiURL = `${this.config[getENV()].raceAPI}/flask-operations`;
    const payload = {
      action: 'ssm_send_command',
      command_type: 'list_applications',
      pc_name: this.pc.name,
    };
    const response = await this.apiService.postDataPromis(apiURL, payload);
    if (response.status === '1') {
      const statusResponse = await this.pollCommandStatus(
        response.response.command_id,
        'list_applications'
      );
      if (statusResponse.command_status === 'Success') {
        this.applications = statusResponse.output;
      }
    }
    this.isLoadingApplications = false;
  }

  async startApplication(app) {
    this.isStartingApplication[app.name] = true;
    const apiURL = `${this.config[getENV()].raceAPI}/flask-operations`;
    const payload = {
      action: 'ssm_send_command',
      command_type: 'start_application',
      pc_name: this.pc.name,
      application_name: app.DisplayName,
    };
    const response = await this.apiService.postDataPromis(apiURL, payload);
    if (response.status === '1') {
      const statusResponse = await this.pollCommandStatus(
        response.response.command_id,
        'start_application'
      );
      if (statusResponse.command_status === 'Success') {
        this.notifier.alert(
          'Success',
          '',
          statusResponse.output.message,
          'success',
          5000
        );
        app.State = 'Running'; // Change state to Running
      } else {
        this.notifier.alert(
          'Info',
          '',
          'Failed to start application',
          'info',
          5000
        );
      }
    }
    this.isStartingApplication[app.name] = false;
  }

  async stopApplication(app) {
    this.isStoppingApplication[app.name] = true;
    const apiURL = `${this.config[getENV()].raceAPI}/flask-operations`;
    const payload = {
      action: 'ssm_send_command',
      command_type: 'stop_application',
      pc_name: this.pc.name,
      application_name: app.DisplayName,
    };
    const response = await this.apiService.postDataPromis(apiURL, payload);
    if (response.status === '1') {
      const statusResponse = await this.pollCommandStatus(
        response.response.command_id,
        'stop_application'
      );
      if (statusResponse.command_status === 'Success') {
        this.notifier.alert(
          'Success',
          '',
          statusResponse.output.message,
          'success',
          5000
        );
        app.State = 'Stopped'; // Change state to Stopped
      } else {
        this.notifier.alert(
          'Info',
          '',
          'Failed to stop application',
          'info',
          5000
        );
      }
    }
    this.isStoppingApplication[app.name] = false;
  }

  async getProcesses() {
    this.isLoadingProcesses = true;
    const apiURL = `${this.config[getENV()].raceAPI}/flask-operations`;
    const payload = {
      action: 'ssm_send_command',
      command_type: 'get_processes',
      pc_name: this.pc.name,
    };
    const response = await this.apiService.postDataPromis(apiURL, payload);
    if (response.status === '1') {
      const statusResponse = await this.pollCommandStatus(
        response.response.command_id,
        'get_processes'
      );
      if (statusResponse.command_status === 'Success') {
        this.processes = statusResponse.output;
      }
    }
    this.isLoadingProcesses = false;
  }

  filteredProcesses() {
    if (!this.searchTermProcesses) {
      return this.processes;
    }
    return this.processes.filter((process) =>
      process.Name.toLowerCase().includes(
        this.searchTermProcesses.toLowerCase()
      )
    );
  }

  async killProcess(pid) {
    this.isKillingProcess[pid] = true;
    const apiURL = `${this.config[getENV()].raceAPI}/flask-operations`;
    const payload = {
      action: 'ssm_send_command',
      command_type: 'stop_process',
      pc_name: this.pc.name,
      process_id: pid,
    };
    const response = await this.apiService.postDataPromis(apiURL, payload);
    if (response.status === '1') {
      const statusResponse = await this.pollCommandStatus(
        response.response.command_id,
        'stop_process'
      );
    }
    this.isKillingProcess[pid] = false;
  }

  async startPC() {
    this.isStartingPC = true;
    const apiURL = `${this.config[getENV()].raceAPI}/flask-operations`;
    const payload = {
      action: 'ssm_send_command',
      command_type: 'start_server',
      pc_name: this.pc.name,
    };
    const response = await this.apiService.postDataPromis(apiURL, payload);
    if (response.status === '1') {
      const statusResponse = await this.pollCommandStatus(
        response.response.command_id,
        'start_server'
      );
    }
    this.isStartingPC = false;
  }

  async stopPC() {
    this.isStoppingPC = true;
    const apiURL = `${this.config[getENV()].raceAPI}/flask-operations`;
    const payload = {
      action: 'ssm_send_command',
      command_type: 'stop_server',
      pc_name: this.pc.name,
    };
    const response = await this.apiService.postDataPromis(apiURL, payload);
    if (response.status === '1') {
      const statusResponse = await this.pollCommandStatus(
        response.response.command_id,
        'stop_server'
      );
    }
    this.isStoppingPC = false;
  }

  async getCurrentDateTime() {
    this.isLoadingDateTime = true;
    const apiURL = `${this.config[getENV()].raceAPI}/flask-operations`;
    const payload = {
      action: 'ssm_send_command',
      command_type: 'get_date_and_time',
      pc_name: this.pc.name,
    };
    const response = await this.apiService.postDataPromis(apiURL, payload);
    if (response.status === '1') {
      const statusResponse = await this.pollCommandStatus(
        response.response.command_id,
        'get_date_and_time'
      );
      if (statusResponse.command_status === 'Success') {
        const dateTime = new Date(statusResponse.output.datetime);
        this.selectedDate = dateTime.toISOString().split('T')[0];
        this.selectedHour = dateTime.getHours().toString();
        this.selectedMinute = dateTime.getMinutes().toString();
      }
    }
    this.isLoadingDateTime = false;
  }

  async getCurrentTimeZone() {
    this.isLoadingTimeZone = true;
    const apiURL = `${this.config[getENV()].raceAPI}/flask-operations`;
    const payload = {
      action: 'ssm_send_command',
      command_type: 'get_time_zone',
      pc_name: this.pc.name,
    };
    const response = await this.apiService.postDataPromis(apiURL, payload);
    if (response.status === '1') {
      const statusResponse = await this.pollCommandStatus(
        response.response.command_id,
        'get_time_zone'
      );
      if (statusResponse.command_status === 'Success') {
        // const timeZone = statusResponse.output.StandardName;
        this.selectedTimeZone = statusResponse.output.Id;
      }
    }
    this.isLoadingTimeZone = false;
  }

  async setTimeZone() {
    this.isSettingTimeZone = true;
    this.isLoadingTimeZone = true;
    const apiURL = `${this.config[getENV()].raceAPI}/flask-operations`;
    const payload = {
      action: 'ssm_send_command',
      command_type: 'modify_time_zone',
      pc_name: this.pc.name,
      time_zone: this.selectedTimeZone,
    };
    const response = await this.apiService.postDataPromis(apiURL, payload);
    if (response.status === '1') {
      const statusResponse = await this.pollCommandStatus(
        response.response.command_id,
        'modify_time_zone'
      );
      this.notifier.alert(
        'Success',
        '',
        'Command Sent Successfully',
        'success',
        5000
      );
    } else {
      this.notifier.alert('Info', '', 'Command Sent Failed', 'info', 5000);
    }
    this.isSettingTimeZone = false;
    this.isLoadingTimeZone = false;
  }

  async setDateTime() {
    this.isSettingDateTime = true;
    this.isLoadingDateTime = true;
    const apiURL = `${this.config[getENV()].raceAPI}/flask-operations`;
    const date = (document.getElementById('date') as HTMLInputElement).value;
    const hour = (document.getElementById('hour') as HTMLSelectElement).value;
    const minute = (document.getElementById('minute') as HTMLSelectElement)
      .value;
    const dateTime = `${date}T${hour}:${minute}:00`;
    const payload = {
      action: 'ssm_send_command',
      command_type: 'set_date_and_time',
      pc_name: this.pc.name,
      date_time: dateTime,
    };
    const response = await this.apiService.postDataPromis(apiURL, payload);
    if (response.status === '1') {
      const statusResponse = await this.pollCommandStatus(
        response.response.command_id,
        'set_date_and_time'
      );
    }
    this.isSettingDateTime = false;
    this.isLoadingDateTime = false;
  }

  async checkCommandStatus(commandId: string, commandType: string) {
    const apiURL = `${this.config[getENV()].raceAPI}/flask-operations`;
    const payload = {
      action: 'ssm_get_command',
      command_type: commandType,
      pc_name: this.pc.name,
      command_id: commandId,
    };
    const response = await this.apiService.postDataPromis(apiURL, payload);
    if (response.status === 'success') {
      this.notifier.alert(
        'Success',
        '',
        'Command executed successfully',
        'success',
        5000
      );
    } else {
      this.notifier.alert('Info', '', 'Command execution failed', 'info', 5000);
    }
  }

  errors: { [key: string]: string } = {};
  isLoadingPCStatus: boolean = false;
  async getPCStatus() {
    this.isLoadingPCStatus = true;
    this.errors['pcStatus'] = '';
    const apiURL = `${this.config[getENV()].raceAPI}/flask-operations`;
    const payload = {
      action: 'pc_status',
      pc_name: this.pc.name,
    };
    try {
      const response = await this.apiService.postDataPromis(apiURL, payload);
      if (response.status === '1') {
        const pcStatus = response.response.find(
          (pc) => pc.PCName === this.pc.name
        );
        if (pcStatus) {
          this.pc.status = pcStatus.PingStatus;
        } else {
          this.errors['pcStatus'] = 'PC status not found';
        }
      } else {
        this.errors['pcStatus'] = 'Failed to fetch PC status';
      }
    } catch (error) {
      console.error('Error fetching PC status:', error);
      this.errors['pcStatus'] = 'Failed to fetch PC status';
    } finally {
      this.isLoadingPCStatus = false;
    }
  }

  filteredApplications() {
    if (!this.searchTerm) {
      return this.applications;
    }
    return this.applications.filter((app) =>
      app.DisplayName.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }
}
