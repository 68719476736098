<!-- toggler -->
<!--<a class="navbar-brand" >

</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>-->
<!--<input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
<button class="btn btn-primary" type="submit">Search</button> *ngIf="searchOnList(i.groupName,accountGroupSearchTerm)" -->
<!--<button class="btn float-end" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" role="button">
  <i class="bi bi-arrow-left-square-fill fs-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvas"></i>
</button>-->
<!--<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>-->
<!--<div class="leftmenu offcanvas offcanvas-start  show" tabindex="-1" id="offcanvas" data-bs-keyboard="false" data-bs-backdrop="false">
  <div class="offcanvas-header">
    <h6 class="offcanvas-title d-none d-sm-block" id="offcanvas" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" role="button">
      <img style="width:50px" src="../assets/img/logo.png" />
    </h6>
  </div>-->

<div class="leftmenu" *ngIf="started">
  <div class="offcanvas-body px-0 max-overflow">
    <button
      class="menuCloseButton ground-border"
      (click)="hideLeftMenu()"
      id="menuClosebtn"
    >
      <i class="fa fa-arrow-circle-right fa-rotate-180"></i>
    </button>

    <button class="ground-border" (click)="showLeftMenu()" id="menuShowbtn">
      <i class="fa fa-arrow-circle-right"></i>
    </button>

    <ul
      class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start"
      id="menu"
    >
      <li class="nav-item" *ngIf="checkIfAvaForUser('Dashboard')">
        <a
          class="nav-link text-truncate"
          title="Dashboard"
          routerLink="/dash/home"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Dashboard%403x.png"
            class="monitoringimg"
            alt=""
          />
          Dashboard
        </a>
      </li>
      <li class="nav-item" *ngIf="checkIfAvaForUser('Dashboard')">
        <a
          class="nav-link text-truncate"
          title="Race Overview"
          routerLink="/dash/race-overview"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Dashboard%403x.png"
            class="monitoringimg"
            alt=""
          />
          Race Overview
        </a>
      </li>
      <!-- <li class="nav-item" *ngIf="checkIfAvaForUser('Pre Race')">
        <a
          class="nav-link text-truncate"
          title="Pre Race"
          routerLink="/dash/pre-race"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Dashboard%403x.png"
            class="monitoringimg"
            alt=""
          />
          Pre Race
        </a>
      </li> -->

      <!-- <li class="nav-item" *ngIf="checkIfAvaForUser('Post Race')">
        <a
          class="nav-link text-truncate"
          title="Post Race"
          routerLink="/dash/post-race"
          fragment="home"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Dashboard%403x.png"
            class="monitoringimg"
            alt=""
          />
          Post Race
        </a>
      </li> -->
      <li class="nav-item" *ngIf="checkIfAvaForUser('Post Race')">
        <a
          class="nav-link text-truncate"
          title="Race Reports"
          routerLink="/dash/post-race/race-reports"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Dashboard%403x.png"
            class="monitoringimg"
            alt=""
          />
          Race Reports
        </a>
      </li>
      <!-- <li class="nav-item" *ngIf="checkIfAvaForUser('Dashboard')">
        <a
          class="nav-link text-truncate"
          title="PC Status"
          routerLink="/dash/pc-status"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Dashboard%403x.png"
            class="monitoringimg"
            alt=""
          />
          PC Status
        </a>
      </li>-->
      <!-- <li class="nav-item" *ngIf="checkIfAvaForUser('Pre Race')">
        <a
          class="nav-link text-truncate"
          title="Pre-Races"
          routerLink="/dash/prepare-system"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Dashboard%403x.png"
            class="monitoringimg"
            alt=""
          />
          Pre-Races
        </a>
      </li> -->
      <li class="dropdown" *ngIf="checkIfAvaForUser('Monitoring')">
        <a
          class="nav-link dropdown-toggle text-truncate"
          id="monitoringDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Dashboard%403x.png"
            class="monitoringimg"
            alt=""
          />
          Monitoring
        </a>
        <ul
          class="dropdown-menu text-small shadow sub-menu"
          aria-labelledby="monitoringDropdown"
        >
          <li>
            <h3 class="sub-menu-title">Monitoring</h3>
            <hr />
          </li>
          <li>
            <a
              class="dropdown-item"
              title="Race Day Rundown"
              routerLink="/dash/monitoring"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                class="wid-40"
                alt=""
              />
              <span>Race Day Rundown</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              title="Grayhound Monitoring"
              routerLink="/dash/grayhound-monitoring"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                class="wid-40"
                alt=""
              />
              <span>Greyhound Monitoring</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              title="PC Status"
              routerLink="/dash/pc-status"
              ><img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                class="wid-40"
                alt=""
              />
              <span>PC Status</span></a
            >
          </li>
        </ul>
      </li>
      <!-- <li class="nav-item" *ngIf="checkIfAvaForUser('Race Status')">
        <a class="nav-link text-truncate" title="Race Status" routerLink="/dash/race-status">
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Dashboard%403x.png"
            class="monitoringimg"
            alt=""
          />
          Race Status
        </a>
      </li> -->
      <!-- <li class="nav-item" *ngIf="checkIfAvaForUser('Staffing')">
        <a class="nav-link text-truncate" title="Staffing">
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Dashboard%403x.png"
            class="monitoringimg"
            alt=""
          />
          Staffing
        </a>
      </li> -->
      <li class="nav-item" *ngIf="checkIfAvaForUser('Equipment')">
        <a
          class="nav-link text-truncate"
          title="Equipment"
          routerLink="/dash/operator-kit-management"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Dashboard%403x.png"
            class="monitoringimg"
            alt=""
          />
          Kit Management
        </a>
      </li>

      <li class="dropdown" *ngIf="checkIfAvaForUser('On Course Ops')">
        <a
          class="nav-link dropdown-toggle text-truncate"
          id="monitoringDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Dashboard%403x.png"
            class="monitoringimg"
            alt=""
          />
          On-Course Ops
        </a>
        <ul
          class="dropdown-menu text-small shadow sub-menu"
          aria-labelledby="monitoringDropdown"
        >
          <li>
            <h3 class="sub-menu-title">On-Course Ops</h3>
            <hr />
          </li>
          <li *ngIf="checkIfAvaForUser('On Course Ops:Race Reports')">
            <a
              class="dropdown-item"
              title="Race Reports"
              routerLink="/dash/on-course-ops/race-reports"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                class="wid-40"
                alt=""
              />
              <span>Race Reports</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('On Course Ops:Judges')">
            <a
              class="dropdown-item"
              title="Judges"
              routerLink="/dash/on-course-ops/judges"
              ><img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                class="wid-40"
                alt=""
              />
              <span>Judges</span></a
            >
          </li>
          <li *ngIf="checkIfAvaForUser('On Course Ops:Sectionals')">
            <a
              class="dropdown-item"
              title="Sectionals"
              routerLink="/dash/on-course-ops/sectionals"
              ><img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                class="wid-40"
                alt=""
              />
              <span>Sectionals</span></a
            >
          </li>
          <li *ngIf="checkIfAvaForUser('On Course Ops:Sectionals')">
            <a
              class="dropdown-item"
              title="Silk Upload"
              routerLink="/dash/on-course-ops/silk-upload"
              ><img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                class="wid-40"
                alt=""
              />
              <span>Silk Upload</span></a
            >
          </li>
        </ul>
      </li>

      <li class="dropdown">
        <a
          class="nav-link dropdown-toggle text-truncate"
          id="dropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Settings.svg"
            class="wid-40"
            alt=""
          />
          Settings
        </a>
        <ul
          class="dropdown-menu text-small shadow sub-menu"
          aria-labelledby="dropdown"
        >
          <li>
            <h3 class="sub-menu-title">Settings</h3>
            <hr />
          </li>
          <li *ngIf="ut == 'admin' || isUserAdmin">
            <a class="dropdown-item" routerLink="/dash/settings/users">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/User%404x.png"
                class="wid-40"
                alt=""
              />
              <span>Users</span>
            </a>
          </li>
          <!-- <li *ngIf="
                checkIfAvaForUser('Settings:operations/ec2groups') &&
                urlPrefix == 'client' &&
                ut == 'admin'
              ">
              <a class="dropdown-item" routerLink="/dash/settings/ec2group/view">
                <i class="fa fa-server"></i><span> EC2 Groups </span>
              </a>
            </li> -->
          <li *ngIf="ut == 'admin' || isUserAdmin">
            <a class="dropdown-item" routerLink="/dash/settings/policies">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Policies%404x.png"
                class="wid-40"
                alt=""
              />
              <span>Policies</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('Dashboard')">
            <a
              class="dropdown-item"
              title="Emails Configuration"
              routerLink="/dash/venue-configuration"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                class="wid-40"
                alt=""
              />
              <span>Emails Configuration</span>
            </a>
          </li>
          <!-- <li *ngIf="checkIfAvaForUser('Settings:audit trail')">
            <a class="dropdown-item" routerLink="/dash/settings/audittrail">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Audit+Trail%404x.png"
                class="wid-40"
                alt=""
              />
              <span> Audit Trail </span>
            </a>
          </li> -->
          <li>
            <a class="dropdown-item" routerLink="/dash/settings/profile">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/User+Management%404x.png"
                class="wid-40"
                alt=""
              /><span>User Management</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="/dash/settings/venue-configuration">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/User+Management%404x.png"
                class="wid-40"
                alt=""
              /><span>Venue Details</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="/dash/settings/business-rules-configuration">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/User+Management%404x.png"
                class="wid-40"
                alt=""
              /><span>Business Rules Configuration</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>

<div class="position-wid-99">
  <div class="row">
    <div class="col pad-right">
      <nav class="navbar navbar-expand-lg top-menu">
        <div class="container-fluid">
          <div class="flex-start">
            <span class="nav-item">
              <span class="nav-link text-truncate padd-0">
                <img class="wid-115" src="../assets/img/tsd_logo_light.png" />
              </span>
            </span>
            <span class="dev-mode">DEV</span>
          </div>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto">
              <li
                class="nav-item dropdown searchbox min-width-auto"
                *ngIf="process['prepares'].length"
              >
                <a
                  class="d-block text-decoration-none pcs-used-indicator color-top"
                  id="dropdownUser1"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                >
                  <span>
                    {{ process["prepares"].length }}
                  </span>
                </a>
                <div
                  class="dropdown-menu text-small p-0 m-0"
                  aria-labelledby="dropdownUser1"
                  data-popper-placement="bottom-start"
                >
                  <!--<li><a class="dropdown-item" >Settings</a></li>
                    <li><a class="dropdown-item" >Profile</a></li>-->
                  <ul class="event-prepare-dropdown">
                    <li
                      class="event-prepare-status-container"
                      *ngFor="let prepare of process['prepares']; let i = index"
                      (click)="expandEvent($event, prepare)"
                    >
                      <div>
                        <div class="flex-between mb-2">
                          <div class="flex-start" style="gap: 0.8rem">
                            <div
                              class="fw-bold border-right-light white-space-none"
                            >
                              {{ prepare["payload"]["location"] }}
                            </div>
                            <div class="white-space-none">
                              {{
                                prepare["payload"]["type"] == "login"
                                  ? "Prepare System"
                                  : "Logout"
                              }}
                            </div>
                          </div>
                          <div class="flex-start">
                            <div class="flex-start">
                              <i
                                class="fa fa-trash poitner nav-link p-0 m-0"
                                (click)="deletePrepare(prepare, i)"
                              ></i>
                              <i
                                class="fa fa-external-link poitner nav-link p-0 m-0"
                                (click)="prepareForRaceVenue = prepare"
                              ></i>
                            </div>
                            <div
                              class="alert event-alert"
                              role="alert"
                              *ngIf="
                                prepare['status'] != 'IN_PROGRESS';
                                else loader
                              "
                              [ngClass]="{
                                'alert-success':
                                  prepare['status'] == 'COMPLETED',
                                'alert-primary':
                                  prepare['status'] == 'IN_PROGRESS',
                                'alert-danger': prepare['status'] == 'FAILED'
                              }"
                            >
                              {{ prepare["status"] }}
                            </div>
                          </div>
                        </div>
                        <div
                          class="flex-between mb-2"
                          *ngIf="!prepare.expanded; else expandedView"
                        >
                          <div class="white-space-none">
                            {{
                              prepare.payload.steps[prepare.payload.type][
                                prepare.currentStep - 1
                              ]["name"]
                            }}
                          </div>
                          <div class="flex-start">
                            <div
                              class="alert step-alert"
                              role="alert"
                              *ngIf="
                                prepare.payload.steps[prepare.payload.type][
                                  prepare.currentStep - 1
                                ]['status'] != 'IN_PROGRESS';
                                else loader
                              "
                              [ngClass]="{
                                'alert-success':
                                  prepare.payload.steps[prepare.payload.type][
                                    prepare.currentStep - 1
                                  ]['status'] == 'COMPLETED',
                                'alert-primary':
                                  prepare.payload.steps[prepare.payload.type][
                                    prepare.currentStep - 1
                                  ]['status'] == 'IN_PROGRESS',
                                'alert-danger':
                                  prepare.payload.steps[prepare.payload.type][
                                    prepare.currentStep - 1
                                  ]['status'] == 'FAILED',
                                'alert-dark':
                                  prepare.payload.steps[prepare.payload.type][
                                    prepare.currentStep - 1
                                  ]['status'] == 'PENDING'
                              }"
                            >
                              {{
                                prepare.payload.steps[prepare.payload.type][
                                  prepare.currentStep - 1
                                ]["status"]
                              }}
                            </div>
                            <div>
                              <i
                                class="fa fa-chevron-down"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        </div>
                        <ng-template #expandedView>
                          <div
                            class="flex-between mb-2"
                            *ngFor="
                              let step of prepare.payload.steps[
                                prepare.payload.type
                              ]
                            "
                          >
                            <div class="white-space-none">
                              {{ step["name"] }}
                            </div>
                            <div
                              class="alert step-alert"
                              role="alert"
                              *ngIf="
                                step['status'] != 'IN_PROGRESS';
                                else loader
                              "
                              [ngClass]="{
                                'alert-success': step['status'] == 'COMPLETED',
                                'alert-primary':
                                  step['status'] == 'IN_PROGRESS',
                                'alert-danger': step['status'] == 'FAILED',
                                'alert-dark': step['status'] == 'PENDING'
                              }"
                            >
                              {{ step["status"] }}
                            </div>
                          </div>
                        </ng-template>
                        <ng-template #loader>
                          <img
                            class="image_loader"
                            src="./assets/img/loading_2.svg"
                            alt=""
                          />
                        </ng-template>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item dropdown searchbox min-width-auto">
                <a
                  class="d-block text-decoration-none dropdown-toggle color-top"
                  id="dropdownUser1"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                >
                  <!--<img src="../../assets/img/passport.png" class="rounded-circle" width="32" height="32" />-->
                  <i class="fa fa-user" aria-hidden="true"></i>
                  <!-- <span class="color-family">{{ username }}</span> -->
                </a>
                <ul
                  class="dropdown-menu text-small"
                  aria-labelledby="dropdownUser1"
                  data-popper-placement="bottom-start"
                >
                  <!--<li><a class="dropdown-item" >Settings</a></li>
                    <li><a class="dropdown-item" >Profile</a></li>-->
                  <li>
                    <span class="color-family text-bold">{{ username }}</span>
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <button class="dropdown-item" (click)="logout()">
                      Sign out
                    </button>
                  </li>
                </ul>
              </li>
            </ul>

            <div class="dropdown text-end"></div>

            <div class="wid-75"></div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</div>

<div
  class="content min-vh-100 rightcontent"
  [ngStyle]="{
    marginLeft: !started ? '26px' : undefined
  }"
>
  <router-outlet></router-outlet>
</div>

<div id="popup" class="popup" *ngIf="inactiveModal">
  <div class="popup-container">
    <div class="popup-header">2 Hours Inactivity Warning</div>
    <div class="popup-content">
      <p>
        You will be signed off within <strong>5 minutes</strong> due to
        inactivity.
        <br />
        Please click "Continue" to keep working or click "Logout" to end your
        session now
      </p>
      <div class="popup-buttons flex-center">
        <button
          id="continueBtn"
          class="btn btn-success-soft"
          (click)="resetActivityTimer(true)"
        >
          Continue
        </button>
        <button id="logoutBtn" class="btn btn-danger-soft" (click)="logout()">
          Logout
        </button>
      </div>
    </div>
  </div>
</div>

<pre-race
  *ngIf="prepareForRaceVenue"
  [type]="prepareForRaceVenue.payload.type"
  url="/dash/post-race"
  [queryParams]="{
    location: prepareForRaceVenue.payload.location
  }"
  [venue]="prepareForRaceVenue.payload.location"
  [relogin]="prepareForRaceVenue.payload.relogin"
  [venue_id]="prepareForRaceVenue.payload.venue_id"
  [pc]="prepareForRaceVenue.payload.pc"
  [operator]="prepareForRaceVenue.payload.operator"
  [event_id]="prepareForRaceVenue.payload.event_id"
  [kit]="prepareForRaceVenue.payload.kit"
  (hideModel)="prepareForRaceVenue = null"
></pre-race>
<eod-confirm-box
  *ngIf="baseStationStatusCode"
  title="Base Station"
  [buttons]="getButtons"
  (hideModel)="handlePopupConfirmation($event)"
>
  <div ngProjectAs="main-body">
    <ng-container *ngIf="baseStationStatusCode == 'LOGIN_PENDING'">
      <div><strong>Do you want to turn the Base Station on?</strong></div>
      <div class="alert alert-warning p-2" role="alert">
        Do you confirm the other one is not on?
      </div>
    </ng-container>
    <ng-container *ngIf="baseStationStatusCode == 'LOGIN_LOADING'">
      <div>Turn on the base station now... it may takes several seconds...</div>
    </ng-container>
    <ng-container *ngIf="baseStationStatusCode == 'LOGIN_COMPLETED'">
      <div>The base station is turned on!</div>
    </ng-container>
    <ng-container *ngIf="baseStationStatusCode == 'LOGIN_FAILED'">
      <div>Failed to connect to base station</div>
    </ng-container>
    <ng-container *ngIf="baseStationStatusCode == 'LOGOUT_LOADING'">
      <div>
        Turn off the base station now... it may takes several seconds...
      </div>
    </ng-container>
    <ng-container *ngIf="baseStationStatusCode == 'LOGOUT_COMPLETED'">
      <div>The base station is turned off!</div>
    </ng-container>
    <ng-container *ngIf="baseStationStatusCode == 'LOGOUT_FAILED'">
      <div>Failed to disconnect from base station</div>
    </ng-container>
  </div>
</eod-confirm-box>
