<div class="card">
  <div class="card-header flex-between">
    <div class="flex-start">
      <button
        class="btn btn-light"
        (click)="backToReports()">
        <i class="fa fa-arrow-left"></i> Back to Race Reports
      </button>
      <h5 class="pb-0 mb-0">Post Race QA</h5>
    </div>
    <div class="flex-start">
      <button class="btn btn-light" (click)="refresh()">
        Refresh
      </button>
      <button
        class="btn btn-primary-soft flex-start"
        (click)="ignoreBussinessRules()"
        [disabled]="ignoringRules"
      >
        <i *ngIf="ignoringRules" class="fa fa-spinner fa-spin"></i>
        Ignore Business Rules
      </button>
      <button class="btn btn-success-soft" (click)="openTolUpload()">
        Upload TOL XML
      </button>
    </div>
  </div>

  <div class="card-body">
    <div class="race-details">
      <div class="details-group">
        <h6>Race Information</h6>
        <div class="detail-item">
          <span class="label">Race Number:</span>
          <span class="value">{{ data.race.SquentialRaceOrderNumber }}</span>
        </div>
        <div class="detail-item">
          <span class="label">Race ID:</span>
          <span class="value">{{ data.race.ExternalRaceId }}</span>
        </div>
        <div class="detail-item">
          <span class="label">Date:</span>
          <span class="value">{{ data.event.date | date : "mediumDate" }}</span>
        </div>
        <div class="detail-item">
          <span class="label">Race Length:</span>
          <span class="value">{{ data.race.race_length }}m</span>
        </div>
      </div>

      <div class="details-group">
        <h6>Event Details</h6>
        <div class="detail-item">
          <span class="label">Event ID:</span>
          <span class="value">{{ data.event.external_event_id }}</span>
        </div>
        <div class="detail-item">
          <span class="label">Venue:</span>
          <span class="value">{{ data.event.venue_name }}</span>
        </div>
        <div class="detail-item">
          <span class="label">Venue ID:</span>
          <span class="value">{{ data.event.external_venue_id }}</span>
        </div>
      </div>

      <div class="details-group">
        <h6>Additional Info</h6>
        <div class="detail-item">
          <span class="label">Jurisdiction:</span>
          <span class="value">{{ data.event.JurisdictionCode }}</span>
        </div>
        <div class="detail-item" *ngIf="data.event.pc">
          <span class="label">PC Number:</span>
          <span class="value">{{ data.event.pc }}</span>
        </div>
      </div>
    </div>

    <div class="grid-container">
      <div class="right-section">
        <div *ngIf="loading" class="loading-spinner">
          <span class="spinner-border"></span>
          <span>Loading error data...</span>
        </div>

        <div *ngIf="apiError" class="error flex-start">
          <i class="fa-solid fa-circle-exclamation"></i>
          <span>{{ apiError }}</span>
        </div>

        <!-- Add no data message -->
        <div *ngIf="!loading && !apiError && errorData.length === 0" class="no-data">
          <i class="fa-solid fa-clipboard-check"></i>
          <span>No checks found</span>
        </div>

        <div *ngIf="!loading && !apiError && errorData.length > 0" class="error-table-container">
          <div class="summary-stats" *ngIf="errorData.length">
            <div
              class="stat-item cursor-pointer"
              (click)="setFilter('ALL')"
              [class.active]="filterStatus === 'ALL'"
            >
              <div class="lg-text">{{ totalChecks }}</div>
              <div>Total Checks</div>
            </div>
            <div
              class="stat-item cursor-pointer"
              (click)="setFilter('PASS')"
              [class.active]="filterStatus === 'PASS'"
            >
              <div class="lg-text text-success">{{ passedChecks }}</div>
              <div>Passed</div>
            </div>
            <div
              class="stat-item cursor-pointer"
              (click)="setFilter('FAIL')"
              [class.active]="filterStatus === 'FAIL'"
            >
              <div class="lg-text text-danger">{{ failedChecks }}</div>
              <div>Failed</div>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table table-docs">
              <thead>
                <tr>
                  <th>Horse Name</th>
                  <th>Metric</th>
                  <th>Section</th>
                  <th>Value</th>
                  <th>Expected Range</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let horseGroup of doubleGroupedErrorData | keyvalue; let firstHorse = first">
                  <ng-container *ngFor="let metricGroup of horseGroup.value | keyvalue; let firstMetric = first; let i = index">
                    <ng-container *ngFor="let error of metricGroup.value; let j = index">
                      <tr class="light-border">
                        <!-- Horse name column - no color coding -->
                        <td *ngIf="i === 0 && j === 0" [attr.rowspan]="getMetricRowCount(horseGroup.value)">
                          {{ horseGroup.key }}
                        </td>
                        <!-- Update the Metric column -->
                        <td *ngIf="j === 0" [attr.rowspan]="getMetricLength(metricGroup.value)">
                          {{ formatMetricName(metricGroup.key) }}
                        </td>
                        <!-- Other columns with color coding -->
                        <td [ngClass]="{'failed-row': !error.Status, 'passed-row': error.Status}">
                          {{ error.Section }}
                        </td>
                        <!-- Update the Value column -->
                        <td [ngClass]="{'failed-row': !error.Status, 'passed-row': error.Status}">
                          {{ getDisplayValue(error.Value, error.Metric) }}
                          <ng-container *ngIf="!error.Status">
                            <span class="deviation-indicator deviation-{{calculateDeviation(error.Value, error.Expected, error.Metric).type}}">
                              {{ calculateDeviation(error.Value, error.Expected, error.Metric).value }}
                            </span>
                          </ng-container>
                        </td>
                        <!-- Update the Expected Range column -->
                        <td [ngClass]="{'failed-row': !error.Status, 'passed-row': error.Status}">
                          {{ getExpectedRange(error.Expected, error.Metric) }}
                        </td>
                        <td [ngClass]="{'failed-row': !error.Status, 'passed-row': error.Status}">
                          <span [class]="error.Status ? 'status-pass' : 'status-fail'">
                            {{ error.Status ? 'PASS' : 'FAIL' }}
                          </span>
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Add TOL Upload Component -->
<upload-tol
  *ngIf="showTolUpload"
  [data]="data"
  [config]="config"
  (closePopup)="closeTolUpload()">
</upload-tol>
