import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { formatFileSize, getENV } from 'src/app/_helpers/helpers';
import { APIService } from 'src/app/services/api.service';
import { FileUploadService } from 'src/app/services/fileupload.service';

@Component({
  selector: 'upload-file-lip',
  templateUrl: './upload-file-lip.component.html',
  styleUrls: [
    './upload-file-lip.component.css',
    './../lps-generate-report/lps-generate-report.component.css',
  ],
})
export class UploadFileLipComponent {
  @Input({ required: true }) meta: any;
  @Input({ required: true }) config: any;

  formatFileSize: any = formatFileSize;

  metaData: any = {
    normal_lip: {
      action: 'upload_lif',
      title: 'Upload LIF File',
      allowed: '.lif',
      name: 'LIF',
    },
    lps_xml_lip: {
      action: 'upload_lps_xml_to_s3',
      title: 'Upload LPS XML LIF File',
      allowed: '.xml',
      name: 'LPS XML',
    },
  };

  constructor(
    private apiService: APIService,
    private fileUploadService: FileUploadService
  ) {}

  dragging: boolean = false;
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragging = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragging = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragging = false;

    if (!event.dataTransfer.types.includes('Files')) {
      this.meta.file.fileError = 'Not a valid file';
      return;
    }

    this.validateFile(event.dataTransfer.files);
  }

  validateFile(files: FileList): boolean {
    this.meta.fileError = null;
    const allowed = this.metaData[this.meta.type].allowed;
    if (files && files.length == 0) {
      this.meta.fileError = 'Missing file';
      return false;
    } else if (files.length > 1) {
      this.meta.fileError = 'Multiple files are not allowed';
      return false;
    } else if (!files[0].name.toLowerCase().endsWith(allowed)) {
      this.meta.fileError = `Only ${allowed} file is allowed`;
      return false;
    }
    this.meta.fileError = '';
    this.meta.file = files[0];
    // this.meta.file.uploadStatus = 'COMPLETED';
    return true;
  }

  onFileSelected(event: Event) {
    const element = event.target as HTMLInputElement;
    const files = element.files;
    let status: boolean = this.validateFile(files);
    if (!status) {
      element.value = '';
    }
  }

  cancelPDF() {
    this.meta.uploadStatus = null;
    this.meta.file = null;
  }

  get getFileName() {
    const raceDate = this.meta.raceCource.date;
    const raceNumber = this.meta.race.SquentialRaceOrderNumber;
    const raceId = this.meta.race.ExternalRaceId;
    const jurisdiction = this.meta.raceCource.Jurisdiction;
    const [year, month, day] = raceDate.split('-');

    let fileName = '';

    switch (jurisdiction) {
      case 'Singapore Racing':
      case 'Greyhound Racing':
        fileName = `${year}-${month}-${day}-${raceNumber}-${raceId}.lif`;
        break;
      default:
        fileName = `${day}-${month}-${year}-${raceNumber}-${raceId}.lif`;
    }

    return fileName;
  }

  async handleFile() {
    // Process the file here (e.g., upload to server, parse XML, etc.)
    this.meta.uploadStatus = 'GETTING_PRESIGNED_URL';
    let apiURL: string = `${this.config[getENV()].raceAPI}/flask-operations`;

    let payload: any = {
      action: this.metaData[this.meta.type].action,
      file_name: this.meta.file.name,
      location: this.meta.raceCource.venue_name,
      event_id: this.meta.raceCource.external_event_id,
      race_number: this.meta.race.SquentialRaceOrderNumber,
      race_date: this.meta.raceCource.date,
      jurisdiction: this.meta.raceCource.JurisdictionCode,
      type: this.meta.raceCource.RaceType,
    };

    if (payload.action === 'upload_lif') {
      payload.race_id = this.meta.race.ExternalRaceId;
      payload['file_name'] = this.getFileName;
    }

    let result: any = await this.apiService.postDataPromis(apiURL, payload, {});

    if (result.status == '1' || result.s == '1') {
      this.meta.uploadStatus = 'PRESIGNED_URL_GENERATED';
      this.meta.presigned_url =
        result.url || result.response || result.presign_url;
      this.uploadFile(this.meta.file, result);
    } else {
      this.meta.uploadStatus = null;
      this.meta.fileError =
        result.error_message || 'Failed to Upload File. Please try again';
    }
  }

  async uploadFile(file: File, result: any) {
    await this.uploadFileAPI(result, file);
  }

  async uploadFileAPI(result: any, file: any) {
    let metadata = result.meta_data || {};
    return new Promise(async (resolve: any, reject: any) => {
      this.fileUploadService
        .uploadFile(this.meta.presigned_url, file, metadata)
        .subscribe(async (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              this.meta.uploadStatus = 'UPLOADING';
              let progress = Math.round(
                (event.loaded / (event.total || 0)) * 100
              );
              this.meta.uploaded = event.loaded;
              this.meta.percentage = progress;
              break;
            case HttpEventType.Response:
              if (event.status === 200) {
                if (this.meta.type == 'lps_xml_lip') {
                  this.meta.uploadStatus = 'UPLOADED';
                  this.tolProcessor(result);
                } else {
                  this.meta.uploadStatus = 'COMPLETED';
                }
                resolve(true);
              } else {
                this.meta.uploadStatus = 'ERROR';
                this.meta.fileError = `Upload failed with status ${event.status}`;
                reject(new Error(`Upload failed with status ${event.status}`));
              }
              break;
            case HttpEventType.User:
              break;
          }
        });
    });
  }

  async tolProcessor(result: any) {
    this.meta.uploadStatus = 'PROCESSING';
    let apiURL: string = `${this.config[getENV()].raceAPI}/flask-operations`;
    const bucketNameRegex = /https:\/\/([^.]+)\.s3\.amazonaws\.com/;
    const match = this.meta.presigned_url.match(bucketNameRegex);
    let bucketName: string;
    if (match && match[1]) {
      bucketName = match[1];
    }

    let payload: any = {
      action: 'trigger_rnsw_tol_processor',
      location: this.meta.raceCource.venue_name,
      event_id: this.meta.raceCource.external_event_id,
      race_number: this.meta.race.SquentialRaceOrderNumber,
      race_date: this.meta.raceCource.date,
      jurisdiction: this.meta.raceCource.JurisdictionCode,
      type: this.meta.raceCource.RaceType,
      bucket: bucketName,
      key: result.s3_prefix + '/' + this.meta.file.name,
      file_name: this.meta.file.name,
    };
    let response: any = await this.apiService.postDataPromis(
      apiURL,
      payload,
      {}
    );

    if (
      (response.s == '1' || response.status == '1') &&
      response.response.statusCode == 200
    ) {
      this.meta.fileSuccess = 'File Uploaded and Processed Successfully';
    } else {
      this.meta.fileError =
        response.error_message ||
        response.response.body ||
        'Failed to Process File. Please try again';
    }
    this.meta.uploadStatus = 'COMPLETED';
  }

  viewContent() {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.previewXML(e.target.result);
    };
    reader.readAsText(this.meta.file);
  }

  previewXML(xmlContent) {
    // Create a new window
    const newWindow = window.open('', '_blank');

    // Create a pre element to preserve formatting
    const preElement = newWindow.document.createElement('pre');

    // Format the XML content
    const formattedXML = this.formatXML(xmlContent);

    // Set the formatted XML as the text content of the pre element
    preElement.textContent = formattedXML;

    // Append the pre element to the new window's body
    newWindow.document.body.appendChild(preElement);

    // Add some basic styling
    newWindow.document.head.innerHTML = `
      <style>
          body { font-family: monospace; background-color: #f0f0f0; }
          pre { white-space: pre-wrap; word-wrap: break-word; }
      </style>
  `;
  }

  formatXML(xml) {
    let formatted = '';
    let indent = '';
    const tab = '  '; // 2 spaces for indentation

    xml.split(/>\s*</).forEach(function (node) {
      if (node.match(/^\/\w/)) {
        indent = indent.substring(tab.length); // Decrease indent
      }
      formatted += indent + '<' + node + '>\r\n';
      if (node.match(/^<?\w[^>]*[^\/]$/)) {
        indent += tab; // Increase indent
      }
    });

    return formatted.substring(1, formatted.length - 3);
  }
}
