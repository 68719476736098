import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { generate, Subscription } from 'rxjs';
import {
  locationMappers,
  locations,
  mappers,
  typeBasedLocations,
} from './locations';
import { NotifierService } from 'src/app/services/notifier.service';
import { APIService } from 'src/app/services/api.service';
import * as moment from 'moment-timezone';
import {
  checkForVenuePermission,
  convertTimestamp,
  getENV,
  getRaceType,
} from 'src/app/_helpers/helpers';

import VenueEmails from '../../../../services/emails';
import { VenueDetailsService } from 'src/app/modules/globals/dash/modules/settings/services/venue-details.service';

declare let $: any;
@Component({
  selector: 'post-race-race-reports',
  templateUrl: './race-reports.component.html',
  styleUrls: ['./race-reports.component.css'],
})
export class RaceReportsComponent implements OnInit, OnDestroy, AfterViewInit {
  locations: any = [];
  mappers: any = mappers;
  username: string = localStorage.getItem('un');

  getTypeOf(obj: any): string {
    return Array.isArray(obj) ? 'array' : 'object';
  }

  getKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  type: string | null = null;

  date: string = null;
  async ngAfterViewInit(): Promise<void> {
    this.date = this.date || moment().format('DD MMMM YYYY');
    $('.datetimepicker').val(this.date);
    $('.datetimepicker')
      .datepicker({
        autoclose: true,
        // minViewMode: 1,
        format: 'dd MM yyyy',
        orientation: 'bottom auto',
        endDate: moment().add(2, 'days').toDate(),
      })
      .on('changeDate', (selected: any) => {
        this.date = $('.datetimepicker').val();
        this.fetchEvents();
      });

    setTimeout(() => {
      this.fetchEvents();
    }, 100);
  }

  selected: any = null;
  fragmentSubscription: Subscription;
  querySubscription: Subscription;
  state: string | null = null;
  isMylapsVenue: boolean = false;
  config: any;
  venueEmail: VenueEmails;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notifier: NotifierService,
    private apiService: APIService,
    private venueService: VenueDetailsService
  ) {
    this.config = this.route.snapshot.data['config'];
    this.venueEmail = new VenueEmails(
      apiService,
      this.config[getENV()],
      this.notifier
    );
  }

  params: any = {
    event_id: null,
    race_id: null,
  };
  ngOnInit(): void {
    this.fragmentSubscription = this.route.fragment.subscribe((params: any) => {
      this.type = params || null;
    });
    this.querySubscription = this.route.queryParams.subscribe((params: any) => {
      if (params.event_id) {
        this.params['event_id'] = params.event_id;
      }
      if (params.race_id) {
        this.params['race_id'] = params.race_id;
      }
      if (params.date) {
        this.date = params.date;
      }
    });
  }

  // selectPC: boolean = false;
  selectedReport: any = null;
  // hideSelectPC(event: any) {
  //   if (event) {
  //     this.selected['pc'] = event.value;
  //     this.handleClicks(true, this.selectedReport);
  //   }
  //   this.selectPC = false;
  // }

  showLocations: any = [];
  async fetchEvents() {
    return new Promise(async (resolve: any) => {
      this.notifier.loading(true);
      this.selected = null;
      this.locations = [];
      this.races = [];
      const date = this.date
        ? moment(this.date, 'DD MMMM YYYY').format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD');
      let raceTypes: any = localStorage.getItem('raceTypes');
      if (raceTypes) {
        raceTypes = JSON.parse(raceTypes);
      }
      let apiURL: string = `${
        this.config[getENV()].raceAPI
      }/event-list?formatted_date=${date}`;

      if (raceTypes && raceTypes.length > 0) {
        apiURL += `&race_types=${raceTypes.join(',')}`;
      }

      let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

      if (result.eventNames && result.eventNames.length > 0) {
        result.eventNames = result.eventNames.filter((event: any) => {
          return checkForVenuePermission(event['external_venue_id']);
        });
        let resultEventNames = result.eventNames;

        resultEventNames.forEach((event: any, index: number) => {
          let raceType = getRaceType(event['external_venue_id'])['raceTypeStr'];

          if (
            raceType == 'Thoroughbred Racing' &&
            event['venue_name'] == 'Cranbourne'
          ) {
            event['venue_name'] = 'Cranbourne Turf';
          }

          const jurisdiction = (typeBasedLocations[raceType] as any).find(
            (j: any) => {
              let location: any = j.Locations.find((location: any) => {
                return (
                  location.Name.toLowerCase() ==
                  event['venue_name'].toLowerCase()
                );
              });
              if (location) {
                event['type'] = location.type;
              }
              return location;
            }
          );

          if (jurisdiction) {
            event['State'] = jurisdiction.State;
            event['JurisdictionCode'] = jurisdiction.JurisdictionCode;
            event['Jurisdiction'] = jurisdiction.Jurisdiction;
            event['RaceType'] = raceType;
            event['Buttons'] = JSON.parse(
              JSON.stringify(jurisdiction['Actions'])
            );
            event['ButtonsBackup'] = JSON.parse(
              JSON.stringify(jurisdiction['Actions'])
            );
            event['date'] = date;
          }

          event['name'] = event['venue_name'];
          event['id'] = event['external_event_id'];
          // this.loadEvent(event, index);
        });

        this.locations = resultEventNames;
        if (this.params.event_id) {
          const selectedEvent = this.locations.find(
            (event: any) => event.external_event_id === this.params.event_id
          );
          if (selectedEvent) {
            this.selectEvent(selectedEvent);
          } else {
            this.notifier.loading(false);
          }
        } else {
          this.notifier.loading(false);
        }
      } else {
        this.locations = [];
        this.notifier.loading(false);
      }
      resolve(true);
    });
  }

  venueEmailData: any = null;
  // async loadEvent(event: any, index: number) {
  //   let apiURL: string = `${
  //     this.config[getENV()].raceAPI
  //   }/getevent?ExternalEventId=${event.external_event_id}`;

  //   let header: any = {
  //     'X-Api-Key': 'e2bvuAoslY1VJvFMqhr5x7nxoEhFVRii9jRngS3U',
  //   };

  //   let result: any = await this.apiService.getDataPromis(apiURL, {}, header);

  //   this.locations[index] = { ...event, ...result.event };

  //   if (
  //     this.selected &&
  //     this.selected['external_event_id'] == event['external_event_id']
  //   ) {
  //     this.selected = { ...this.selected, ...result.event };
  //   }
  // }

  races: any = [];

  async loadVenue() {
    try {
      const venueDetails = await this.venueService.getLocationDetails(
        this.config,
        this.selected['external_venue_id'],
        this.selected['RaceType'],
        this.selected['venue_name']
      );
      if (venueDetails && venueDetails.venues) {
        this.selected['VenueNotes'] = venueDetails.venues.Notes;
        console.log(venueDetails.venues.MylapsIP)
        console.log(this.selected['Buttons'])
        this.isMylapsVenue = venueDetails.venues.MylapsIP != null;
        console.log(this.isMylapsVenue)
        if (this.isMylapsVenue) {
          this.selected['Buttons'] = this.selected['Buttons'].filter(item =>
            !(item.name === "Generate PDF" && item.action === "generate_pdf")
          );
        }
        console.log(this.selected['Buttons'])

      }
    } catch (error) {
      console.error('Error loading venue details:', error);
    }
  }

  async selectEvent(event: any) {
    this.notifier.loading(true);

    this.router.navigate(['/dash/post-race/race-reports'], {
      queryParams: { event_id: event['external_event_id'], date: this.date },
    });
    this.selected = event;
    this.loadVenue();

    this.venueEmailData = await this.venueEmail.loadVenueEmail(
      this.selected['external_venue_id']
    );

    // this.loadEvent(event.external_event_id);

    const apiURL = `${
      this.config[getENV()].raceAPI
    }/getracedetails?ExternalVenueId=${
      event.external_venue_id
    }&ExternalEventId=${event.external_event_id}`;

    const result = await this.apiService.getDataPromis(apiURL, {}, {});

    if (result && Array.isArray(result) && result.length > 0) {
      result.sort(
        (a, b) => a.SquentialRaceOrderNumber - b.SquentialRaceOrderNumber
      );

      result.forEach((race: any) => {
        race['RaceState'] = race['RaceState'] || 'PLANNED';
        if (this.selected['Buttons']) {
          race['Buttons'] = JSON.parse(
            JSON.stringify(this.selected['ButtonsBackup'])
          )
            .filter((button: any) => {
              let check: boolean = false;
              if (!button.conditions) {
                return true;
              }
              button.conditions.forEach((condition: any) => {
                if (condition.venues) {
                  if (condition.venues.includes(event['venue_name'])) {
                    check = true;
                  }
                }
                if (
                  condition.key === 'RaceLength' &&
                  condition.equation === 'equals' &&
                  condition.value === race['RaceLength']
                ) {
                  check = true;
                } else if (
                  condition.key === 'RaceLength' &&
                  condition.equation === 'not equals' &&
                  condition.value !== race['RaceLength']
                ) {
                  check = true;
                }
                if (check) {
                  this.selected['type'] = condition['type'] || 'default';
                }
              });

              return check;
            })
            .map((button: any) => {
              button.steps = [];
              this.checkButtonCondition(race, button, true);
              return button;
            });
        }

        race['eventName'] = event.name;
        race['raceStatus'] = race['RaceState']?.toUpperCase() || 'PLANNED';
        race['RaceTimeStr'] = race['RaceTime'];
        race['RaceTime'] = convertTimestamp(
          race['RaceTime'],
          event['venue_state']
        );

        race['queryParamsVenue'] = {
          event_id: race['ExternalEventId'],
          venue_id: race['ExternalVenueId'],
          date: race['eventDate'],
          event_name: race['eventName'],
        };

        race['queryParamsRace'] = {
          race_id: race['ExternalRaceId'],
        };

        race[
          'name'
        ] = `#${race['SquentialRaceOrderNumber']} ${race['RaceName']}`;
        race['id'] = race['SquentialRaceOrderNumber'];
      });

      this.races = result;

      // this.getRaceResult(result);

      this.notifier.loading(false);
    }
  }

  async reviewPDF(
    race: any,
    button: any = null,
    type: string = '.pdf',
    file_folder: string = undefined
  ) {
    if (button) {
      button.loading = true;
    }
    let apiURL: string = `${this.config[getENV()].raceAPI}/flask-operations`;

    let step_type: string = undefined;
    if (button && this.selected['type'] == 'LPS') {
      step_type = button.lastUpdatedStepType;
    }
    let report_type = this.selected['RaceType'];
    if (this.isMylapsVenue) {
      report_type = 'Mylaps Racing';
    }
    let payload: any = {
      action: 'review_files',
      location:
        locationMappers[this.selected.venue_name] || this.selected.venue_name,
      race_number: race['SquentialRaceOrderNumber'],
      jurisdiction: this.selected['JurisdictionCode'],
      race_date: this.selected['date'],
      type: report_type,
      event_id: this.selected['external_event_id'],
      race_id: race['ExternalRaceId'],
      file_folder: file_folder,
      file_extension: type,
      step_type: step_type,
    };

    let result: any = await this.apiService.postDataPromis(apiURL, payload, {});

    if (result.status === '1' || result.s === '1') {
      let a: HTMLAnchorElement = document.createElement('a');
      a.target = '_blank';
      a.download = result.presigned_url;
      a.href = result.presigned_url;
      a.click();
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
    if (button) {
      button.loading = false;
    }
  }

  // async getRaceResult(races: any) {
  //   return new Promise(async (resolve: any) => {
  //     let apiURL: string = `${
  //       this.config[getENV()].raceAPI
  //     }/getraceresults?ExternalEventId=${
  //       this.selected.external_event_id
  //     }&ExternalVenueId=${this.selected.external_venue_id}&${races
  //       .map((race: any) => {
  //         return `raceIds%5B%5D=${race['ExternalRaceId']}`;
  //       })
  //       .join('&')}`;

  //     let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

  //     if (result && typeof result == 'object') {
  //       races.forEach((race: any) => {
  //         if (result[race['ExternalRaceId']] != undefined) {
  //           race['raceStatus'] = result[race['ExternalRaceId']].toUpperCase();
  //         } else {
  //           race['raceStatus'] = 'DNS';
  //         }
  //       });
  //     }

  //     // this.races = [...this.races, ...races];
  //     resolve(true);
  //   });
  // }

  // async selectRace(race: any) {
  //   this.router.navigate(['/dash/post-race/race-reports'], {
  //     queryParams: {
  //       event_id: this.selected['external_event_id'],
  //       race_id: race['ExternalRaceId'],
  //       date: this.date,
  //     },
  //   });
  // }

  loadingActions: any = {};

  isRaceCompleted(race: any) {
    return (
      race['Buttons'].length > 1 &&
      race['Buttons'][race['Buttons'].length - 1].completed
    );
  }

  isRaceError(race: any) {
    return (
      !this.isRaceCompleted(race) &&
      race['Buttons'].some((button: any) => button.error)
    );
  }

  async checkButtonAPIStatus(
    race: any,
    button: any,
    firstTIme: boolean = false
  ) {
    return new Promise(async (resolve: any, reject: any) => {
      button.error = '';
      button.lastUpdatedMs = null;
      let payload: any = {
        action:
          button.action == 'publish' ||
          (button.action == 'generate_pdf' && button.type == 'LPS')
            ? 'publish_status'
            : 'action_status',
        event_id: this.selected['external_event_id'],
        button_action: button.action,
        report_action: button.action,
        race_number: race['SquentialRaceOrderNumber'],
        race_id: race['ExternalRaceId'],
        operator_name: this.selected['operator'] || this.username,
        pc_number: this.selected['pc'],
        venue_id: this.selected['external_venue_id'],
      };

      let apiURL: string = `${this.config[getENV()].raceAPI}/flask-operations`;

      let result: any;
      if (!firstTIme) {
        result = await this.apiService.postDataPromis(apiURL, payload, {});
      } else {
        let response: any;
        if (
          button.action != 'publish' &&
          !(button.action == 'generate_pdf' && button.type == 'LPS')
        ) {
          response = race['ReportStatus'].find((report: any) => {
            return report.StepType == button.action;
          });
          if (response) {
            result = {
              status: '1',
              response: response,
            };
          } else {
            result = {
              status: '1',
              response: {},
            };
          }
        } else {
          response = race['ReportStatus'].filter((report: any) => {
            return report.EPCSWSID.indexOf('_' + button.action) > -1;
          });
          if (response) {
            result = {
              status: '1',
              response: response,
            };
          } else {
            result = {
              status: '1',
              response: [],
            };
          }
        }
      }

      if (result.status == '1' || result.s == '1') {
        if (
          button.action == 'publish' ||
          (button.action == 'generate_pdf' && button.type == 'LPS')
        ) {
          result.response.sort((a: any, b: any) => {
            const dateA = moment(a.LastUpdatedAt);
            const dateB = moment(b.LastUpdatedAt);
            return dateB.diff(dateA);
          });

          button.steps = result.response;
          let status: string = 'COMPLETED';

          button.lastUpdatedStepType = null;
          for (let i = 0; i < result.response.length; i++) {
            const report = result.response[i];
            let last_updated = report?.LastUpdatedAt || report?.CreatedAt;
            if (last_updated) {
              const lastUpdatedMs = new Date(last_updated).getTime();
              button.lastUpdatedMs = lastUpdatedMs;
            }
            if (report?.StepCompletionStatus.toUpperCase() == 'IN PROGRESS') {
              status = 'IN PROGRESS';
              break;
            }
            if (report?.StepCompletionStatus.toUpperCase() == 'FAILED') {
              status = 'FAILED';
            }
            if (report?.StepCompletionStatus.toUpperCase() == 'COMPLETED') {
              if (!button.lastUpdatedStepType) {
                button.lastUpdatedStepType = report.StepType;
              }
            }
          }

          if (status == 'COMPLETED') {
            button.completed = true;
            button.loading = false;
            resolve('COMPLETED');
          } else if (status == 'FAILED') {
            button.loading = true;
            button.completed = false;
            button.error = 'Failed';
            resolve('FAILED');
          } else {
            button.loading = true;
            button.completed = false;
            button.status = 'IN PROGRESS';
            resolve('IN PROGRESS');
          }
        } else if (result?.response?.StepCompletionStatus) {
          let last_updated =
            result?.response?.LastUpdatedAt || result?.response?.CreatedAt;
          if (last_updated) {
            const lastUpdatedMs = new Date(last_updated).getTime();
            button.lastUpdatedMs = lastUpdatedMs;
          }
          if (
            result?.response?.StepCompletionStatus?.toUpperCase() == 'FAILED'
          ) {
            button.completed = false;
            button.error =
              result?.response?.Comments ||
              'Something went wrong. Please try again!';
          } else if (
            result?.response?.StepCompletionStatus?.toUpperCase() == 'COMPLETED'
          ) {
            button.error = '';
            button.loading = false;
            button.completed = true;
          } else if (!result?.response?.StepCompletionStatus) {
            button.completed = false;
            button.error = '';
          }
          resolve(
            result?.response?.StepCompletionStatus?.toUpperCase() || 'PENDING'
          );
        } else {
          resolve('PENDING');
        }
      } else {
        reject('FAILED');
      }
    });
  }

  async checkButtonCondition(
    race: any,
    button: any,
    firstTime: boolean = false
  ) {
    let event_id = this.selected['external_event_id'];
    let race_id = race['ExternalRaceId'];
    button.loading = true;
    button.pending = false;
    let status = await this.checkButtonAPIStatus(race, button, firstTime);

    if (status == 'PENDING') {
      button.loading = false;
      button.pending = true;
    } else if (status == 'IN PROGRESS') {
      button.loading = true;
      setTimeout(() => {
        if (
          event_id == this.selected['external_event_id'] &&
          race_id == race['ExternalRaceId']
        ) {
          this.checkButtonCondition(race, button, false);
        } else {
          return;
        }
      }, 5000);
    } else if (status == 'COMPLETED') {
      button.loading = false;
      button.completed = true;
    } else {
      button.loading = false;
    }

    if (
      (button.action == 'publish' ||
        (button.action == 'generate_pdf' && button.type == 'LPS')) &&
      button.steps.length == 0
    ) {
      button.pending = true;
      button.completed = false;
    }
  }

  cooldownAlert: any = null;
  selectedRaceCooldown: any = null;
  hideCooldownAlert(event: boolean) {
    if (event) {
      this.cooldownAlert['lastUpdatedMs'] =
        this.cooldownAlert['lastUpdatedMs'] - 6 * 60 * 1000;
      let button: any = this.selectedRaceCooldown['Buttons'].find(
        (button: any) => {
          return button.action == this.cooldownAlert['action'];
        }
      );
      if (button) {
        button.lastUpdatedMs = this.cooldownAlert['lastUpdatedMs'];
      }
      this.handleClicks(this.selectedRaceCooldown, true, button);
    }
    this.cooldownAlert = null;
  }

  hideConfirmPublish(event: any) {
    if (event) {
      this.handleClicks(
        this.confirmPublish['race'],
        this.confirmPublish['event'],
        this.confirmPublish['params'],
        true
      );
    }
    this.confirmPublishCheck = false;
  }

  uploadFileObj: any = null;
  uploadFile(race: any, params: any, type: string) {
    this.uploadFileObj = {
      type: type,
      race: race,
      raceCource: this.selected,
      params: params,
    };
  }

  generateFileObj: any = null;
  generateLIPFile(race: any, params: any) {
    this.generateFileObj = {
      race: race,
      raceCource: this.selected,
      params: params,
    };
  }

  getOfficialReport() {
    if (this.generateFileObj.completed) {
      let race = this.races.find((race: any) => {
        return race.ExternalRaceId == this.generateFileObj.race.ExternalRaceId;
      });

      let params = race['Buttons'].find((param: any) => {
        return param.action == this.generateFileObj.params.action;
      });

      params.loading = true;
      setTimeout(() => {
        this.checkButtonCondition(race, params);
      }, 5000);
    }

    this.generateFileObj = null;
  }

  closeActionsConfirm(event: any) {
    if (!event) {
      this.confirmToPublishOnlyOneStep = null;
      return;
    }
    let race = this.races.find((race: any) => {
      return (
        race.ExternalRaceId ==
        this.confirmToPublishOnlyOneStep.race.ExternalRaceId
      );
    });

    let params = race['Buttons'].find((param: any) => {
      return param.action == this.confirmToPublishOnlyOneStep.params.action;
    });

    const selected = params.steps.filter((step: any) => {
      return step.selected;
    });

    if (selected.length == 0) {
      this.confirmToPublishOnlyOneStep['error'] =
        'Please select atleast one action';
      return;
    }

    this.handleClicks(race, this.confirmToPublishOnlyOneStep.event, params);

    this.confirmToPublishOnlyOneStep = null;
  }

  confirmPublish: any = null;
  confirmPublishCheck: boolean = false;
  lpsPopup: any = null;

  checkIfAtleastOnePDFisCreated(button: any) {
    if (button.completed) {
      return true;
    }
    let pdfs = button.steps.filter((step: any) => {
      return step.StepCompletionStatus.toUpperCase() == 'COMPLETED';
    });
    if (pdfs.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  checkIfAtleastOneRacePDFisCreated() {
    let pdfs = this.races.filter((race: any) => {
      let button = race['Buttons'].find((button: any) => {
        return button.action == 'generate_pdf';
      });
      if (button) {
        return this.checkIfAtleastOnePDFisCreated(button);
      } else {
        return false;
      }
    });
    if (pdfs.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  downloadingAll: boolean = false;
  downloadAll(type: string = '.pdf', file_folder: string = undefined) {
    let pdfs = this.races.filter((race: any) => {
      let button = race['Buttons'].find((button: any) => {
        return button.action == 'generate_pdf';
      });
      if (button) {
        return this.checkIfAtleastOnePDFisCreated(button);
      } else {
        return false;
      }
    });
    if (pdfs.length > 0) {
      let promises: Promise<void>[] = [];
      this.downloadingAll = true;
      pdfs.forEach((pdf: any) => {
        let button = pdf['Buttons'].find((button: any) => {
          return (
            button.action == 'generate_pdf' ||
            button.action == 'generate_pdf_redcliffe'
          );
        });
        promises.push(this.reviewPDF(pdf, button, type, file_folder));
      });
      Promise.all(promises).then((values: any) => {
        this.downloadingAll = false;
      });
    } else {
      this.notifier.alert(
        'Info',
        '',
        'No PDF files to download!',
        'info',
        5000
      );
    }
  }

  generateAllPDF() {
    this.races.forEach((race: any) => {
      let generatePdf: any = race['Buttons'].find((button: any) => {
        return button.action == 'generate_pdf';
      });
      if (generatePdf) {
        this.handleClicks(race, false, generatePdf, false);
      }
    });
  }

  confirmToPublishOnlyOneStep: any = null;
  async handleClicks(
    race: any,
    event: boolean = false,
    params: any,
    force: boolean = false,
    tsd_harness_sectionals: boolean = false,
    pdf_source: string = 'tol'
  ) {
    // if (params.completed) {
    //   if (event) {
    //     this.notifier.alert(
    //       'Info',
    //       '',
    //       'Action is already completed!',
    //       'info',
    //       5000
    //     );
    //   }`
    //   return;
    // }

    // if (!this.selected['pc']) {
    //   this.selectPC = true;
    //   this.selectedReport = params;
    //   return;
    // } else {
    //   this.selectPC = false;
    //   this.selectedReport = null;
    // }

    if (params.loading && event) {
      this.notifier.alert(
        'Info',
        '',
        'Action is already in progress!',
        'info',
        5000
      );
      return;
    }

    if (params.cooldown && params.lastUpdatedMs && params.completed) {
      const nowMs = Date.now();
      const minutesAgo = Math.abs(
        Math.floor((nowMs - params.lastUpdatedMs) / 60000)
      );
      if (minutesAgo < 5) {
        this.selectedRaceCooldown = race;
        this.cooldownAlert = params;
        return;
      } else {
        this.cooldownAlert = null;
      }
    }

    if (params.action == 'publish' && !force) {
      const completedSteps = (params.steps || []).filter(
        (step: any) => step.StepCompletionStatus?.toUpperCase() == 'COMPLETED'
      );
      if (!this.confirmToPublishOnlyOneStep) {
        if (completedSteps.length > 0) {
          this.confirmToPublishOnlyOneStep = {
            race: race,
            event: event,
            params: params,
          };
          return;
        } else {
          this.confirmToPublishOnlyOneStep = null;
        }
      }
      const emailSteps = params.steps.find((step: any) => {
        return step.selected && step.StepType.toLowerCase().includes('email');
      });

      if (emailSteps || completedSteps.length == 0) {
        race['email'] = true;
      } else {
        race['email'] = false;
      }
    }

    if (params.action == 'publish' && !force) {
      this.confirmPublishCheck = true;
      this.confirmPublish = {
        race: race,
        event: event,
        params: params,
        force: force,
      };
      return;
    }

    if (params.action == 'generate_pdf' && params.type == 'LPS' && !force && !this.isMylapsVenue) {
      this.lpsPopup = {
        race: race,
        event: event,
        raceCource: this.selected,
        params: params,
        force: force,
      };
      return;
    }
    console.log("Hello");
    params.loading = true;
    let steps: any = params.steps
      .filter((step: any) => step.selected)
      .map((step: any) => step.StepReference);
    let race_report_type = this.selected['RaceType'];
    if (this.isMylapsVenue && race_report_type != 'Greyhound Racing') {
      race_report_type = 'Mylaps Racing';
    }
    if ((this.isMylapsVenue || race_report_type == 'Greyhound Racing') && pdf_source == 'tol') {
      pdf_source = 'Sectional_processed'
    }
    let data: any = {
      action: params['action'],
      location:
        locationMappers[this.selected.venue_name] || this.selected.venue_name,
      race_number: race.SquentialRaceOrderNumber,
      race_id: race.ExternalRaceId,
      race_date: this.selected['date'],
      event_id: this.selected['external_event_id'],
      jurisdiction: this.selected['JurisdictionCode'],
      type: race_report_type,
      operator_name: this.selected['operator'] || this.username,
      pc_number: this.selected['pc'],
      venue_id: this.selected['external_venue_id'],
      publish_steps: steps.length > 0 ? steps : undefined,
      tsd_harness_sectionals: tsd_harness_sectionals,
      pdf_source: pdf_source,
    };

    if (params.type == 'LPS' && params.action == 'generate_pdf' && !this.isMylapsVenue) {
      data.report_type = this.lpsPopup.reportType;
      data.source_xml = this.lpsPopup.sourceType;
      params.error = '';
    }

    let apiURL: string = `${this.config[getENV()].raceAPI}/flask-operations`;
    let result: any = await this.apiService.postDataPromis(apiURL, data, {});

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert(
        'Success',
        '',
        result.msg ||
          result.message ||
          result.success ||
          result?.response?.message,
        'success',
        5000
      );
      setTimeout(() => {
        this.checkButtonCondition(race, params);
      }, 10000);
    } else {
      this.notifier.alert(
        'Info',
        '',
        result.error_message || result.error || result.message,
        'info',
        5000
      );
    }

    this.notifier.loading(false);
  }

  // QA
  qa: any = null;
  showQA(race: any) {
    const queryParams = {
      venue_name: this.selected.venue_name,
      race_number: race.SquentialRaceOrderNumber,
      race_id: race.ExternalRaceId,
      race_date: this.selected.date,
      external_event_id: this.selected.external_event_id,
      jurisdiction_code: this.selected.JurisdictionCode,
      external_venue_id: this.selected.external_venue_id,
      race_length: race.RaceLength,
      pc_number: this.selected.pc || undefined // optional
    };

    this.router.navigate(['/dash/post-race-qa'], {
      queryParams
    });
  }

  hideQA() {
    this.qa = null;
  }

  showNotes: any = null;
  showVenueNotes() {
    this.showNotes = {
      title: `${this.selected['venue_name']} - Venue Notes`,
      notes: this.selected['VenueNotes'],
      id: this.selected['external_venue_id'],
      action: 'save_venue_notes',
    };
  }

  showRaceNotes(race: any) {
    this.showNotes = {
      title: `${race['RaceName']} - Race Notes`,
      notes: race['Notes'],
      id: race['ExternalRaceId'],
      action: 'race_notes',
    };
  }

  hideNotes(notes: string) {
    if (this.showNotes.action == 'event_notes') {
      this.selected['Notes'] = notes;
    } else if (this.showNotes.action == 'save_venue_notes') {
      this.selected['VenueNotes'] = notes;
    } else if (this.showNotes.action == 'race_notes') {
      let race = this.races.find(
        (race: any) => race['ExternalRaceId'] == this.showNotes.id
      );
      if (race) {
        race['Notes'] = notes;
      }
    }
    this.showNotes = null;
  }
  hasConditionType(button: any, type: string): boolean {
    return button.conditions?.some((condition: any) => condition.type === type) || false;
  }

  ngOnDestroy(): void {
    this.querySubscription.unsubscribe();
    this.fragmentSubscription.unsubscribe();
  }
}
