<div class="card">
  <div class="card-header flex-between">
    <h5>Business Rules Configuration</h5>
    <div class="flex-start">
      <button class="btn btn-primary-soft" (click)="loadBusinessRules()">
        <i class="fa fa-refresh"></i> Refresh
      </button>
    </div>
  </div>

  <div class="card-body">
    <!-- Add main loader -->
    <div class="loader-container" *ngIf="loading.main">
      <div class="spinner-border text-primary"></div>
    </div>

    <!-- Main content with opacity when loading -->
    <div [class.content-loading]="loading.main">
      <nav>
        <div class="nav nav-tabs" id="ruleTab" role="tablist">
          <button *ngFor="let type of types"
            class="nav-link tab"
            [class.active]="currentType === type"
            [id]="type + '-tab'"
            data-bs-toggle="tab"
            [attr.data-bs-target]="'#' + type"
            type="button"
            role="tab"
            (click)="selectType(type)"
            [attr.aria-controls]="type">
            {{type | titlecase}}
          </button>
        </div>
      </nav>

      <div class="tab-content">
        <div *ngFor="let section of sections" class="form-section">
          <div class="form-section-header flex-between">
            <span>{{formatMetricName(section)}} {{ checkIfSpeedMetric(section) ? '(km/h)' : '' }}</span>
            <button class="btn btn-primary-soft" (click)="openEditModal(section)">Edit</button>
          </div>
          <div class="form-section-body">
            <!-- Replace table with cards view -->
            <div *ngFor="let range of businessRules[currentType]?.[section]?.ranges" class="range-card">
              <div class="range-header">
                <strong>Race Length: {{range.race_length_min}} - {{range.race_length_max || 'No Limit'}}m</strong>
              </div>
              <div class="range-content">
                <!-- Show min/max values for non-average-speed sections -->
                <ng-container *ngIf="section !== 'average_speed'">
                  <div class="range-item">
                    <span class="range-label">Min Value</span>
                    <span class="range-value">{{range.min | number:'1.2-2'}} {{ checkIfSpeedMetric(section) ? 'km/h' : '' }}</span>
                  </div>
                  <div class="range-item">
                    <span class="range-label">Max Value</span>
                    <span class="range-value">{{range.max | number:'1.2-2'}} {{ checkIfSpeedMetric(section) ? 'km/h' : '' }}</span>
                  </div>
                </ng-container>

                <!-- Show segments/sections for average-speed -->
                <ng-container *ngIf="section === 'average_speed'">
                  <div class="range-item full-width">
                    <span class="range-label">{{currentType === 'harness' ? 'Segments' : 'Sections'}}</span>
                    <div class="segments-list">
                      <div *ngFor="let item of (currentType === 'harness' ? (range.segments | keyvalue) : (range.sections | keyvalue))"
                          class="segment-item">
                        <span class="segment-title">{{getSegmentLabel(item.key)}}</span>
                        <span class="segment-values-box">{{item.value[0] | number:'1.2-2'}} - {{item.value[1] | number:'1.2-2'}} {{ checkIfSpeedMetric(section) ? 'km/h' : '' }}</span>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Edit Modal -->
<div class="popup-screen" *ngIf="showModal">
  <div class="popup-container">
    <div class="popup-header">
      Edit {{ formatMetricName(editingSection) }}
      <button type="button" class="close" (click)="closeModal()">&times;</button>
    </div>
    <div class="popup-body">
      <!-- Range Management -->
      <div *ngFor="let range of editingRules?.ranges; let i = index">
        <div class="form-section">
          <div class="form-section-header">
            <span>Range {{i + 1}}</span>
            <button class="btn btn-danger-soft btn-sm" (click)="deleteRange(i)">
              <i class="fa fa-trash"></i> Remove Range
            </button>
          </div>
          <div class="form-section-body">
            <!-- Race Length Configuration -->
            <div class="form-section">
              <div class="form-section-header">Race Length Configuration</div>
              <div class="form-section-body">
                <div class="grid-container">
                  <div class="form-group">
                    <label>Min Length (m)</label>
                    <input type="number" class="form-control" [(ngModel)]="range.race_length_min">
                  </div>
                  <div class="form-group">
                    <label>Max Length (m)</label>
                    <input type="number" class="form-control" [(ngModel)]="range.race_length_max">
                  </div>
                </div>
              </div>
            </div>

            <!-- Value Range Configuration -->
            <div class="form-section" *ngIf="editingSection !== 'average_speed'">
              <div class="form-section-header">Value Range Configuration</div>
              <div class="form-section-body">
                <div class="grid-container">
                  <div class="form-group">
                    <label>Min Value {{ checkIfSpeedMetric(editingSection) ? '(km/h)' : '' }}</label>
                    <input type="number" class="form-control" [(ngModel)]="range.min" step="0.01">
                  </div>
                  <div class="form-group">
                    <label>Max Value {{ checkIfSpeedMetric(editingSection) ? '(km/h)' : '' }}</label>
                    <input type="number" class="form-control" [(ngModel)]="range.max" step="0.01">
                  </div>
                </div>
              </div>
            </div>

            <!-- Segments/Sections Configuration -->
            <div class="form-section" *ngIf="editingSection === 'average_speed'">
              <div class="form-section-header">
                <span>{{currentType === 'harness' ? 'Segments' : 'Sections'}} Configuration</span>
                <button class="btn btn-primary-soft btn-sm" (click)="addSegmentOrSection(range)">
                  <i class="fa fa-plus"></i> Add {{currentType === 'harness' ? 'Segment' : 'Section'}}
                </button>
              </div>
              <div class="form-section-body">
                <div class="segments-container">
                  <div *ngFor="let item of (currentType === 'harness' ? (range.segments | keyvalue) : (range.sections | keyvalue))"
                      class="segment-row">
                    <div class="segment-field">
                      <label class="mb-0">Name</label>
                      <input type="text" class="form-control" [(ngModel)]="item.key" placeholder="Section Name">
                    </div>
                    <div class="segment-field">
                      <label class="mb-0">Min Value {{ checkIfSpeedMetric(editingSection) ? '(km/h)' : '' }}</label>
                      <input type="number" class="form-control" [(ngModel)]="item.value[0]" step="0.01" placeholder="Min">
                    </div>
                    <div class="segment-field">
                      <label class="mb-0">Max Value {{ checkIfSpeedMetric(editingSection) ? '(km/h)' : '' }}</label>
                      <input type="number" class="form-control" [(ngModel)]="item.value[1]" step="0.01" placeholder="Max">
                    </div>
                    <button class="btn btn-danger-soft btn-sm align-self-end" (click)="removeSegmentOrSection(range, item.key)">
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Add Range Button -->
      <button class="btn btn-primary-soft w-100 mt-3" (click)="addRange()">
        <i class="fa fa-plus"></i> Add New Range
      </button>
    </div>
    <div class="popup-footer">
      <button type="button" class="btn btn-light" (click)="closeModal()">Cancel</button>
      <button type="button" class="btn btn-success-soft" (click)="saveRules()" [disabled]="loading.save">
        <span class="spinner-border spinner-border-sm me-1" *ngIf="loading.save"></span>
        {{loading.save ? 'Saving...' : 'Save Changes'}}
      </button>
    </div>
  </div>
</div>
