<div class="popup-screen">
  <div class="popup-container">
    <div class="popup-header">
      Base Station Calibration
      <button type="button" class="close" (click)="close()">&times;</button>
    </div>
    <div class="popup-body">
      <!-- Initial loading state -->
      <div *ngIf="loading" class="center-loader">
        <img src="./assets/img/loading_2.svg" alt="Loading..." class="loader-img" />
      </div>

      <div *ngIf="!loading">
        <!-- Show calibration in progress loader -->
        <div *ngIf="calibrationInProgress" class="center-loader">
          <img src="./assets/img/loading_2.svg" alt="Loading..." class="loader-img" />
        </div>

        <!-- Show warning only when no data and not calibrating -->
        <div *ngIf="!hasCalibrationData && !calibrationInProgress" class="text-center alert alert-warning">
          Calibration is missing.
          <span class="nav-link d-inline" (click)="startCalibration()">Click here</span>
          to calibrate.
        </div>

        <!-- Show table when calibration data exists and not in progress -->
        <table class="table" *ngIf="hasCalibrationData && !calibrationInProgress && calibrationData">
          <tbody>
            <tr>
              <th>Nearest Station</th>
              <td>{{calibrationData.nearest_station}}</td>
            </tr>
            <tr>
              <th>Distance</th>
              <td>{{calibrationData.distance.toFixed(4)}} km</td>
            </tr>
            <tr>
              <th>Latitude</th>
              <td>{{calibrationData.latitude}}</td>
            </tr>
            <tr>
              <th>Longitude</th>
              <td>{{calibrationData.longitude}}</td>
            </tr>
            <tr>
              <th>Nearest Mountpoint</th>
              <td>{{calibrationData.nearest_mountpoint}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="popup-footer">
      <button class="btn btn-primary-soft flex-start"
              (click)="startCalibration()"
              [disabled]="calibrationInProgress">
        <img src="./assets/img/loading_2.svg" *ngIf="calibrationInProgress" class="loader-img" alt="" />
        <span>{{calibrationInProgress ? 'Calibrating...' : 'Calibrate'}}</span>
      </button>
      <button class="btn btn-light" (click)="close()">Close</button>
    </div>
  </div>
</div>
