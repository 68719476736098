import { Component, OnInit } from '@angular/core';
import { BusinessRulesService } from './business-rules.service';
import { ActivatedRoute } from '@angular/router';
import { NotifierService } from 'src/app/services/notifier.service';

interface Range {
  race_length_min: number | null;
  race_length_max: number | null;
  min?: number;
  max?: number;
  segments?: { [key: string]: [number, number] };
  sections?: { [key: string]: [number, number] };
}

interface BusinessRuleSection {
  ranges: Range[];
}

interface BusinessRules {
  [key: string]: {
    [key: string]: BusinessRuleSection;
  };
}

@Component({
  selector: 'app-business-rules-configuration',
  templateUrl: './business-rules-configuration.component.html',
  styleUrls: ['./business-rules-configuration.component.css'],
})
export class BusinessRulesConfigurationComponent implements OnInit {
  config: any;
  businessRules: BusinessRules = {};
  currentType: 'harness' | 'thoroughbred' = 'harness';
  types: ['harness', 'thoroughbred'] = ['harness', 'thoroughbred'];
  showModal = false;
  editingSection: string | null = null;
  editingRules: BusinessRuleSection | null = null; // Temporary storage for editing
  loading = {
    main: false,
    save: false,
  };

  sections: string[] = [
    'average_speed',
    'top_speed_method1',
    'top_speed_method2',
    'stride_length',
    'stride_frequency',
  ];

  SPEED_METRICS = ['average_speed', 'top_speed_method1', 'top_speed_method2'];

  checkIfSpeedMetric(metric: string) {
    return this.SPEED_METRICS.includes(metric);
  }

  constructor(
    private businessRulesService: BusinessRulesService,
    private activatedRoute: ActivatedRoute,
    private notifier: NotifierService
  ) {
    this.config = this.activatedRoute.snapshot.data['config'];
  }

  ngOnInit() {
    this.loadBusinessRules();
  }

  public formatMetricName(metric: string): string {
    return metric
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
      .replace(/(\d+)/g, ' $1');
  }

  async loadBusinessRules() {
    try {
      this.loading.main = true;
      const result = await this.businessRulesService.getBusinessRules(
        this.config,
        this.currentType
      );
      if (result.status === '1') {
        if (!this.businessRules[this.currentType]) {
          this.businessRules[this.currentType] = {};
        }
        Object.keys(result.response || {}).forEach((key) => {
          const section = result.response![key];
          if (this.checkIfSpeedMetric(key)) {
            section.ranges.forEach((range: Range) => {
              if (range.segments) {
                Object.keys(range.segments).forEach((segmentKey) => {
                  range.segments![segmentKey] = range.segments![segmentKey].map(
                    (value) => value * 3.6
                  ) as [number, number];
                });
              }
              if (range.sections) {
                Object.keys(range.sections).forEach((sectionKey) => {
                  range.sections![sectionKey] = range.sections![sectionKey].map(
                    (value) => value * 3.6
                  ) as [number, number];
                });
              }
              if (range.min !== undefined && range.max !== undefined) {
                range.min = range.min * 3.6;
                range.max = range.max * 3.6;
              }
            });
          }
          this.businessRules[this.currentType][key] = section;
        });
      }
    } catch (error) {
      this.notifier.alert(
        'Error',
        '',
        'Failed to load business rules',
        'error'
      );
    } finally {
      this.loading.main = false;
    }
  }

  selectType(type: string) {
    if (type === 'harness' || type === 'thoroughbred') {
      this.currentType = type;
      this.loadBusinessRules(); // Reload rules when type changes
    }
  }

  openEditModal(section: string) {
    this.editingSection = section;
    // Create deep copy of the rules for editing
    this.editingRules = JSON.parse(
      JSON.stringify(this.businessRules[this.currentType][section])
    );
    this.showModal = true;
  }

  addRange() {
    if (!this.editingSection || !this.editingRules) return;

    const ranges = this.editingRules.ranges;
    const newRange: Range = {
      race_length_min: null,
      race_length_max: null,
    };

    if (this.editingSection === 'average_speed') {
      if (this.currentType === 'harness') {
        newRange['segments'] = {
          '0': [0, 0],
          '1': [0, 0],
          '2': [0, 0],
        };
      } else {
        newRange['sections'] = {
          '200m': [0, 0],
          '400m': [0, 0],
          '600m': [0, 0],
        };
      }
    } else {
      newRange['min'] = 0;
      newRange['max'] = 0;
    }

    ranges.push(newRange);
  }

  deleteRange(index: number) {
    if (!this.editingRules) return;
    this.editingRules.ranges.splice(index, 1);
  }

  async saveRules() {
    try {
      this.loading.save = true;
      if (!this.editingSection || !this.editingRules) return;

      const dataToSave = JSON.parse(
        JSON.stringify({
          ...this.businessRules[this.currentType],
          [this.editingSection]: this.editingRules,
        })
      );

      Object.keys(dataToSave).forEach((key) => {
        const section = dataToSave[key];
        section.ranges.forEach((range: Range) => {
          if (range.segments) {
            Object.keys(range.segments).forEach((segmentKey) => {
              range.segments![segmentKey] = range.segments![segmentKey].map(
                (value) => value / 3.6
              ) as [number, number];
            });
          }
          if (range.sections) {
            Object.keys(range.sections).forEach((sectionKey) => {
              range.sections![sectionKey] = range.sections![sectionKey].map(
                (value) => value / 3.6
              ) as [number, number];
            });
          }
          if (range.min !== undefined && range.max !== undefined && this.checkIfSpeedMetric(key)) {
            range.min = range.min / 3.6;
            range.max = range.max / 3.6;
          }
        });
      });

      const result = await this.businessRulesService.saveBusinessRules(
        this.config,
        this.currentType,
        dataToSave
      );
      if (result.status === '1') {
        // Only update main data after successful save
        this.businessRules[this.currentType][this.editingSection] =
          this.editingRules;
        this.notifier.alert(
          'Success',
          '',
          'Business rules saved successfully',
          'success'
        );
        this.closeModal();
      } else {
        this.notifier.alert(
          'Error',
          '',
          result.error_message || 'Failed to save business rules',
          'error'
        );
      }
    } catch (error) {
      this.notifier.alert(
        'Error',
        '',
        'Failed to save business rules',
        'error'
      );
    } finally {
      this.loading.save = false;
    }
  }

  closeModal() {
    this.showModal = false;
    this.editingSection = null;
    this.editingRules = null;
  }

  getSegmentLabel(key: string): string {
    // Return the key directly without adding "Segment" prefix
    return key;
  }

  addSegmentOrSection(range: any) {
    if (this.currentType === 'harness') {
      const segmentKeys = Object.keys(range.segments);
      const newKey = String(segmentKeys.length);
      // Remove "Segment" prefix from new key
      range.segments[newKey] = [0, 0];
    } else {
      const sectionKeys = Object.keys(range.sections);
      const nextDistance = (sectionKeys.length + 1) * 200;
      range.sections[`${nextDistance}m`] = [0, 0];
    }
  }

  removeSegmentOrSection(range: any, key: string) {
    if (this.currentType === 'harness') {
      delete range.segments[key];
    } else {
      delete range.sections[key];
    }
  }
}
