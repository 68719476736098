<div class="pb-2 pt-2 flex-end" *ngIf="data">
  <button
    type="button"
    (click)="default($event)"
    class="btn btn-light relative"
  >
    <i class="fa fa-cog"></i>
    <div class="floating-button">
      <div *ngFor="let h of headers" class="flex-between">
        <div class="pl-2">
          {{ typeOf(h.name) == "function" ? h.name() : h.name }}
        </div>
        <div (click)="hideMenu($event, h)">
          <label class="switch" style="pointer-events: none">
            <input
              autocomplete="off"
              type="checkbox"
              style="pointer-events: none"
              [ngModel]="h.show"
            />
            <span class="slider round"></span>
          </label>
        </div>
      </div>
    </div>
  </button>
</div>
<div class="width-100">
  <table class="table table-docs table-bordered table-hover">
    <thead>
      <tr>
        <th
          *ngFor="let h of headers"
          [ngStyle]="{
            display: h.show ? 'table-cell' : 'none',
            width: h?.style?.width || 'auto'
          }"
        >
          <div>
            <span>{{ typeOf(h.name) == "function" ? h.name() : h.name }}</span>
            <span *ngIf="h.filter" class="inputseach">
              <input
                type="text"
                [(ngModel)]="h.filterText"
                [disabled]="formDisabled"
              />
              <i class="fa fa-search" aria-hidden="true"></i>
            </span>
          </div>
          <ng-container *ngIf="h.datepicker">
            <div>
              <input type="text" class="{{ h.datepicker?.class }}" />
            </div>
          </ng-container>
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of data; let i = index">
        <tr
          *ngIf="notifier.filterTableColums(headers, item)"
          class="{{
            item['rowClassConfition'] && item['rowClassConfition'](item)
          }}"
        >
          <td
            *ngFor="let h of headers"
            [class.changed]="h.name == 'Race Time' && item?.timeChanged"
            [ngClass]="{ 'light-danger': item?.dropdown?.error[h.id] }"
            [ngStyle]="{
              display: h.show ? 'table-cell' : 'none',
              'text-align': h.align || null
            }"
          >
            <div class="flex-start">
              <div
                [class.flex-center]="sendEvent && h.name == 'Race Time'"
                [class.flex-grow-1]="
                  h?.component?.name != 'ReprotsdownloadComponent'
                "
              >
                <ng-container *ngIf="h.type == 'string'">
                  <a
                    *ngIf="h.link && !h.link.callback"
                    [routerLink]="[h?.link?.href]"
                    [queryParams]="item[h.link.queryParamsKey]"
                  >
                    {{ item[h.id] || h?.or?.text || "-" }}
                  </a>
                  <a
                    *ngIf="h.link && h.link.callback"
                    class="nav-link"
                    (click)="h.link.callback($event, h?.link?.href, item[h.link.queryParamsKey])"
                  >
                    {{ item[h.id] || h?.or?.text || "-" }}
                  </a>
                  <span *ngIf="!h.link" [innerHTML]="item[h.id] || h?.or?.text || '-'">
                  </span>
                  <div *ngIf="h.name == 'Race Time' && sendEvent">
                    <i
                      class="fa fa-pencil pointer"
                      (click)="
                        sendEvent.emit({ type: 'editRaceTime', item: item })
                      "
                    ></i>
                  </div>
                </ng-container>
                <ng-container *ngIf="h.type == 'inputbox'">
                  <input
                    class="form-control"
                    [ngStyle]="h.style"
                    [type]="h.inputtype"
                    [(ngModel)]="item[h.id]"
                  />
                </ng-container>
                <ng-container *ngIf="h.type == 'dropdown'">
                  <select
                    class="form-select"
                    [(ngModel)]="item[h.dropdown.key]"
                    [disabled]="formDisabled"
                  >
                    <option
                      value="{{ option.value }}"
                      *ngFor="let option of h.dropdown.getOptions()"
                    >
                      {{ option.name }}
                    </option>
                  </select>
                </ng-container>
                <ng-container *ngIf="h.type == 'component'">
                  <app-status-view
                    *ngIf="h.component.name == 'StatusViewComponent'"
                    [statuses]="h.component.data"
                    [selected]="item[h.id]"
                  ></app-status-view>
                  <app-horsestatus
                    *ngIf="h.component.name == 'HorseStatusViewComponent'"
                    [data]="item[h.id]"
                  ></app-horsestatus>
                  <app-reportstatus
                    *ngIf="h.component.name == 'RaceStatusViewComponent'"
                    [data]="item[h.id]"
                  ></app-reportstatus>
                  <div *ngIf="h.component.name == 'ReprotsdownloadComponent'">
                    <app-reprotsdownload [data]="item"></app-reprotsdownload>
                  </div>
                  <div *ngIf="h.component.name == 'RaceStatus'">
                    <span
                      class="status-text-container alert flex-center"
                      [ngClass]="{
                        'alert-primary': item[h.id]?.toUpperCase() == 'RUNNING',
                        'alert-success':
                          item[h.id] == 'COMPLETED' || item[h.id] == 'FINISHED',
                        'alert-dark':
                          item[h.id] == 'PLANNED' ||
                          item[h.id] == 'CLEANED' ||
                          item[h.id] == 'PUBLISHED',
                        'alert-danger':
                          item[h.id] == 'INCOMPLETED' ||
                          item[h.id] == 'CANCELLED' ||
                          item[h.id] == 'DIDNOTTRACK' ||
                          item[h.id] == 'ABANDONED'
                      }"
                    >
                      <i
                        class="fa fa-circle color-inherit"
                        aria-hidden="true"
                      ></i>
                      <span class="color-inherit">{{ item[h.id] || "-" }}</span>
                    </span>
                  </div>
                  <app-mqtt-state
                    *ngIf="h.component.name == 'MqttStateComponent'"
                    [data]="item[h.id]"
                  ></app-mqtt-state>
                  <span
                    *ngIf="h.component.name == 'span'"
                    class="{{ item[h.id] }}"
                  ></span>
                  <span
                    *ngIf="h.component.name == 'clickspan'"
                    class="cursor-pointer {{ item[h.id] }}"
                    (click)="this.sendEvent.emit({ type: h.click, item: item })"
                  ></span>
                  <span
                    *ngIf="
                      h.component.name == 'MinutesLeft' &&
                      item[h.id] != undefined
                    "
                    [class.red-text]="item[h.id] < 6"
                    >{{ item[h.id] }}
                  </span>
                </ng-container>

                <ng-container *ngIf="h.type == 'image'">
                  <img
                    src="{{ item[h.id] }}"
                    class="width-50"
                    alt="{{ h.name }}"
                  />
                </ng-container>
                <ng-container *ngIf="h.type == 'checkbox'">
                  <input
                    type="checkbox"
                    [(ngModel)]="item[h.id]"
                    [disabled]="formDisabled"
                  />
                </ng-container>

                <ng-container *ngIf="h.type == 'multi-select'">
                  <ng-container *ngIf="h.type == 'multi-select'">
                    <ng-multiselect-dropdown
                      *ngIf="item?.dropdown?.options"
                      [placeholder]="h.dropdown.placeholder"
                      [settings]="{
                        singleSelection: !h.dropdown.multiple,
                        idField: 'item_id',
                        textField: 'item_text',
                        selectAllText: 'Select All',
                        unSelectAllText: 'UnSelect All',
                        itemsShowLimit: 3,
                        allowSearchFilter: true
                      }"
                      (onFilterChange)="
                        onFilterChange($event, item, h.dropdown.onchange, h)
                      "
                      [ngModelOptions]="{ standalone: true }"
                      (onSelect)="onDropdownChange($event, item, h)"
                      [data]="item.dropdown.options[h.id]"
                      [(ngModel)]="item.dropdown.selected[h.id]"
                      [disabled]="formDisabled"
                    >
                    </ng-multiselect-dropdown>
                  </ng-container>
                </ng-container>
              </div>
              <div
                *ngIf="item?.loading?.[h.id]"
                class="spinner-border spinner-border-sm text-secondary mr-2"
              ></div>
            </div>
          </td>
        </tr>
      </ng-container>
      <tr *ngIf="data && data.length == 0 && !loading">
        <td class="center" [attr.colSpan]="headers.length + 1">
          <span>No Data Found</span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
