import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { formatFileSize, getENV } from 'src/app/_helpers/helpers';
import { APIService } from 'src/app/services/api.service';
import { FileUploadService } from 'src/app/services/fileupload.service';

@Component({
  selector: 'upload-tol',
  templateUrl: './upload-tol.component.html',
  styleUrls: ['./upload-tol.component.css',
    './../../../lps-generate-report/lps-generate-report.component.css',
  ],
})
export class UploadTolComponent {
  @Input({ required: true }) data: any;
  @Input({ required: true }) config: any;
  @Output() closePopup = new EventEmitter<void>();

  formatFileSize = formatFileSize;
  dragging = false;
  file: File | null = null;
  fileError: string | null = null;
  fileSuccess: string | null = null;
  uploadStatus: string | null = null;
  presigned_url: string | null = null;
  percentage = 0;
  uploaded = 0;

  constructor(
    private apiService: APIService,
    private fileUploadService: FileUploadService
  ) {}

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragging = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragging = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragging = false;

    if (!event.dataTransfer.types.includes('Files')) {
      this.fileError = 'Not a valid file';
      return;
    }

    this.validateFile(event.dataTransfer.files);
  }

  validateFile(files: FileList): boolean {
    this.fileError = null;
    if (files && files.length == 0) {
      this.fileError = 'Missing file';
      return false;
    } else if (files.length > 1) {
      this.fileError = 'Multiple files are not allowed';
      return false;
    } else if (!files[0].name.toLowerCase().endsWith('.xml')) {
      this.fileError = 'Only XML file is allowed';
      return false;
    }
    this.fileError = '';
    this.file = files[0];
    return true;
  }

  onFileSelected(event: Event) {
    const element = event.target as HTMLInputElement;
    const files = element.files;
    let status: boolean = this.validateFile(files);
    if (!status) {
      element.value = '';
    }
  }

  cancelFile() {
    this.uploadStatus = null;
    this.file = null;
  }

  async handleFile() {
    this.uploadStatus = 'GETTING_PRESIGNED_URL';
    let apiURL: string = `${this.config[getENV()].raceAPI}/flask-operations`;

    let payload: any = {
      action: 'upload_tol_to_s3',
      file_name: this.file.name,
      location: this.data.event.venue_name,
      event_id: this.data.event.external_event_id,
      race_number: this.data.race.SquentialRaceOrderNumber,
      race_date: this.data.event.date,
      jurisdiction: this.data.event.JurisdictionCode,
      type: this.data.event.race_type,
    };

    let result: any = await this.apiService.postDataPromis(apiURL, payload, {});

    if (result.status == '1' || result.s == '1') {
      this.uploadStatus = 'PRESIGNED_URL_GENERATED';
      this.presigned_url =
        result.url || result.response || result.presign_url;
      this.uploadFile(this.file, result);
    } else {
      this.uploadStatus = null;
      this.fileError =
        result.error_message || 'Failed to Upload File. Please try again';
    }
  }

  async uploadFile(file: File, result: any) {
    await this.uploadFileAPI(result, file);
  }

  async uploadFileAPI(result: any, file: any) {
    let metadata = result.meta_data || {};
    return new Promise(async (resolve: any, reject: any) => {
      this.fileUploadService
        .uploadFile(this.presigned_url, file, metadata)
        .subscribe(async (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              this.uploadStatus = 'UPLOADING';
              let progress = Math.round(
                (event.loaded / (event.total || 0)) * 100
              );
              this.uploaded = event.loaded;
              this.percentage = progress;
              break;
            case HttpEventType.Response:
              if (event.status === 200) {
                this.uploadStatus = 'COMPLETED';
                resolve(true);
              } else {
                this.uploadStatus = 'ERROR';
                this.fileError = `Upload failed with status ${event.status}`;
                reject(new Error(`Upload failed with status ${event.status}`));
              }
              break;
          }
        });
    });
  }

  viewContent() {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.previewXML(e.target.result);
    };
    reader.readAsText(this.file);
  }

  previewXML(xmlContent) {
    const newWindow = window.open('', '_blank');
    const preElement = newWindow.document.createElement('pre');
    const formattedXML = this.formatXML(xmlContent);
    preElement.textContent = formattedXML;
    newWindow.document.body.appendChild(preElement);
    newWindow.document.head.innerHTML = `
      <style>
        body { font-family: monospace; background-color: #f0f0f0; }
        pre { white-space: pre-wrap; word-wrap: break-word; }
      </style>
    `;
  }

  formatXML(xml) {
    let formatted = '';
    let indent = '';
    const tab = '  ';

    xml.split(/>\s*</).forEach(function (node) {
      if (node.match(/^\/\w/)) {
        indent = indent.substring(tab.length);
      }
      formatted += indent + '<' + node + '>\r\n';
      if (node.match(/^<?\w[^>]*[^\/]$/)) {
        indent += tab;
      }
    });

    return formatted.substring(1, formatted.length - 3);
  }
}
