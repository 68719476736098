<div id="pdfPopup" class="popup">
  <div class="popup-content">
    <div class="popup-header flex-between">
      <span class="color-inherit">
        Upload TOL XML - Race #{{ data.race.SquentialRaceOrderNumber }}
      </span>
      <span class="color-inherit" (click)="closePopup.emit()">
        <i class="fa fa-times color-inherit cursor-pointer"></i>
      </span>
    </div>
    <div class="popup-body">
      <div>
        <div *ngIf="fileError" class="error flex-start">
          <i class="fa-solid fa-circle-exclamation"></i>
          <span>{{ fileError }}</span>
        </div>
        <div *ngIf="fileSuccess" class="success flex-start mb-0">
          <i class="fa-solid fa-check-circle"></i>
          <span>{{ fileSuccess }}</span>
        </div>
        <div *ngIf="uploadStatus == 'PROCESSING'" class="secondary flex-between mb-0">
          <div class="flex-start">
            <i class="fa-solid fa-clock"></i>
            <span>Processing...</span>
          </div>
          <div>
            <span class="spinner-border spinner-border-sm ml-2"></span>
          </div>
        </div>
        <div class="upload-section" *ngIf="!file; else showFile" id="uploadSection"
          [class.active]="dragging"
          (click)="uploadSection.click()"
          (dragover)="onDragOver($event)"
          (dragleave)="onDragLeave($event)"
          (drop)="onDrop($event)">
          {{ dragging ? "Drop here" : "Click here to upload or drag and drop the TOL XML File" }}
          <input #uploadSection type="file" id="tolFileInput" accept=".xml"
            style="display: none" (change)="onFileSelected($event)" />
        </div>
        <ng-template #showFile>
          <div class="flex-between p-1">
            <div class="flex-1 flex-start file">
              <div class="pr-1">
                <i class="fa fa-file-text" aria-hidden="true"></i>
              </div>
              <div class="flex-1">
                <div><strong>{{ file.name }}</strong></div>
                <div class="mt-1">
                  <span *ngIf="!uploadStatus || uploadStatus == 'COMPLETED' || uploadStatus == 'PROCESSING'; else showUploadStatus"
                    class="flex-start">
                    {{ formatFileSize(file.size) }}
                    <ng-container *ngIf="uploadStatus == 'COMPLETED' || uploadStatus == 'PROCESSING'">
                      <span> | </span>
                      <span class="flex-start text-success fw-bold">
                        <i class="fa fa-check-circle color-inherit"></i> Uploaded
                      </span>
                    </ng-container>
                  </span>
                  <ng-template #showUploadStatus>
                    <div>
                      <span>{{ formatFileSize(uploaded || 0) }} / {{ formatFileSize(file.size) }} ({{ percentage || 0 }}%)</span>
                    </div>
                    <div class="progress-bar">
                      <div class="progress" [style.width.%]="percentage || 0"></div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
            <div class="flex-start">
              <ng-container *ngIf="!uploadStatus || uploadStatus == 'COMPLETED'; else showLoader">
                <button class="flex-start btn btn-success-soft" (click)="handleFile()" *ngIf="!uploadStatus">
                  <i class="fa-solid fa-upload color-inherit"></i> Upload
                </button>
                <button class="flex-start btn btn-danger-soft" (click)="cancelFile()"
                  *ngIf="uploadStatus != 'COMPLETED'; else showCloseButton">
                  <i class="fa fa-times color-inherit"></i> Cancel
                </button>
                <ng-template #showCloseButton>
                  <button class="flex-start btn btn-danger-soft" (click)="closePopup.emit()">
                    <i class="fa fa-times color-inherit"></i> Close
                  </button>
                </ng-template>
              </ng-container>
              <ng-template #showLoader>
                <span class="spinner-border spinner-border-sm ml-2"></span>
              </ng-template>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="popup-actions flex-end">
      <ng-content select="closePopup"></ng-content>
    </div>
  </div>
</div>
