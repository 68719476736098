<div id="popup">
  <div class="popup-container">
    <div class="popup-header">Jira Notes</div>
    <div class="popup-content">
      <div class="pb-1">
        <nav class="relative">
          <div class="nav nav-tabs" id="issueTab" role="tablist">
            <button
              *ngFor="let requestType of requestTypes"
              class="nav-link tab active"
              [class.active]="requestType.slug === selectedRequestType"
              [id]="requestType.slug + '-tab'"
              data-bs-toggle="tab"
              [attr.data-bs-target]="'#' + requestType.slug"
              type="button"
              role="tab"
              (click)="selectedRequestType = requestType.slug"
              [attr.aria-controls]="requestType.slug"
            >
              {{ requestType.name }}
              <span class="badge bg-secondary">{{
                issues[requestType.slug]?.length || 0
              }}</span>
            </button>
            <button
              class="nav-link tab active"
              [class.active]="selectedRequestType == 'venue-notes'"
              [id]="'venue-notes-tab'"
              data-bs-toggle="tab"
              [attr.data-bs-target]="'#venue-notes'"
              type="button"
              role="tab"
              (click)="selectedRequestType = 'venue-notes'"
              [attr.aria-controls]="'venue-notes'"
            >
              Venue Notes
            </button>
          </div>
          <div class="floating-right">
            <a class="btn btn-primary-soft" href="https://triplesdata.atlassian.net/servicedesk/customer/portal/1/group/5" target="_blank">Raise an Issue</a>
          </div>
        </nav>
      </div>
      <div class="issue-list">
        <div class="tab-content" id="issueTabContent">
          <div
            class="tab-pane fade"
            *ngFor="let requestType of requestTypes"
            id="{{ requestType.slug }}"
            role="tabpanel"
            [class.active]="requestType.slug == selectedRequestType"
            [class.show]="requestType.slug == selectedRequestType"
            [attr.aria-labelledby]="requestType.slug + '-tab'"
          >
            <div class="table-container">
              <table
                class="table table-hover table-docs table-striped"
                *ngIf="issues[requestType.slug].length > 0"
              >
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Summary</th>
                    <th *ngIf="requestType.slug === 'tag-incident'">Tag Asset ID</th>
                    <th *ngIf="requestType.slug === 'tag-incident'">Kit</th>
                    <th>Reporter</th>
                    <th>Created</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let issue of issues[requestType.slug]"
                    (click)="selectIssue(issue)"
                  >
                    <td>
                      <span class="issue-status {{ issue.statusStr }}">{{ issue.status || '-' }}</span>
                    </td>
                    <td>{{ issue.summary || '-' }}</td>
                    <td *ngIf="requestType.slug === 'tag-incident'">{{ issue.tag_asset_id || '-' }}</td>
                    <td *ngIf="requestType.slug === 'tag-incident'">{{ issue.tag_associated_kit || '-' }}</td>
                    <td>{{ issue.roc_user || '-' }}</td>
                    <td>{{ (issue.created | date : "MMM dd, yyyy") || '-' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="flex-center p-2 h-100"
              *ngIf="loading[requestType.slug]"
            >
              <div class="spinner-border spinner-border-sm"></div>
            </div>
            <div
              class="flex-center p-2 h-100"
              *ngIf="
                !loading[requestType.slug] &&
                issues[requestType.slug].length == 0
              "
            >
              <div
                class="flex-center"
                *ngIf="
                  !loading[requestType.slug] &&
                  issues[requestType.slug].length == 0
                "
              >
                <div>
                  <i class="fas fa-check text-success"></i>
                </div>
                <div class="text-success">No Issues Found</div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="venue-notes"
            role="tabpanel"
            [class.active]="selectedRequestType == 'venue-notes'"
            [class.show]="selectedRequestType == 'venue-notes'"
            [attr.aria-labelledby]="'venue-notes-tab'"
          >
            <div class="notes-container">
              <!-- Add loader -->
              <div class="flex-center p-2 h-100" *ngIf="loading['notes']">
                <div class="spinner-border spinner-border-sm"></div>
              </div>

              <!-- Show content when not loading -->
              <ng-container *ngIf="!loading['notes']">
                <div class="notes-header flex-end">
                  <span *ngIf="!isEditingNotes" class="nav-link" (click)="toggleNotesEdit()">
                    <i class="fa fa-pencil color-inherit"></i> Edit Notes
                  </span>
                </div>
                <ng-container *ngIf="!isEditingNotes">
                  <div [innerHTML]="notes || 'No notes available'"></div>
                </ng-container>
                <ng-container *ngIf="isEditingNotes">
                  <div>
                    <textarea id="venueNotesEditor"></textarea>
                  </div>
                  <div class="button-container flex-end mt-3">
                    <button class="btn btn-light mr-2" (click)="cancelNotesEdit()">Cancel</button>
                    <button class="btn btn-success-soft" (click)="saveVenueNotes()">Save Notes</button>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
          <div class="absolute-comm" [class.open]="selectedIssue">
            <div class="absolute-container">
              <div class="width-400px relative">
                <div class="flxed-loader" *ngIf="loading['comm']">
                  <div class="flex-center p-2 h-100">
                    <div class="spinner-border spinner-border-sm"></div>
                  </div>
                </div>
                <i
                  class="fas fa-times floating-close"
                  (click)="selectedIssue = null"
                ></i>
                <div class="ticket-details" *ngIf="selectedIssue">
                  <div class="flex-between">
                    <h2 class="ticket-key">{{ selectedIssue.issue_key }}</h2>
                    <a href="https://triplesdata.atlassian.net/browse/{{ selectedIssue.issue_key}}" target="_blank">
                      Open in Jira
                      <i class="fas fa-external-link-alt color-inherit"></i>
                    </a>
                  </div>
                  <h3 class="ticket-subject">
                    {{ selectedIssue.subject || selectedIssue.summary }}
                  </h3>
                  <nav>
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                      <button
                        class="nav-link tab active"
                        id="details-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#details"
                        type="button"
                        role="tab"
                        aria-controls="details"
                        aria-selected="true"
                      >
                        Issue Details
                      </button>
                      <button
                        class="nav-link tab"
                        id="comments-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#comments"
                        type="button"
                        role="tab"
                        aria-controls="comments"
                        aria-selected="false"
                      >
                        Comments
                      </button>
                      <button
                        class="nav-link tab"
                        id="history-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#history"
                        type="button"
                        role="tab"
                        aria-controls="history"
                        aria-selected="false"
                      >
                        Status History
                      </button>
                    </div>
                  </nav>
                  <div class="tab-content" id="ticketTabsContent">
                    <div
                      class="tab-pane fade show active"
                      id="details"
                      role="tabpanel"
                      aria-labelledby="details-tab"
                    >
                      <div class="ticket-info">
                        <div class="info-item">
                          <span class="info-label">Created:</span>
                          <span class="info-value">{{ selectedIssue.creation_time }}</span>
                        </div>
                        <div class="info-item">
                          <span class="info-label">Priority:</span>
                          <span class="info-value">{{ selectedIssue.priority }}</span>
                        </div>
                        <div class="info-item">
                          <span class="info-label">Status:</span>
                          <span class="info-value status-badge {{ selectedIssue.statusStr }}">{{ selectedIssue.status }}</span>
                        </div>
                        <div class="info-item">
                          <span class="info-label">Reporter:</span>
                          <span class="info-value">{{ selectedIssue.roc_user || '-' }}</span>
                        </div>
                        <div class="info-item">
                          <span class="info-label">Assignee:</span>
                          <span class="info-value">{{ selectedIssue.assignee }}</span>
                        </div>
                        <div class="info-item" *ngIf="selectedIssue.tag_asset_id">
                          <span class="info-label">Tag Asset ID:</span>
                          <span class="info-value">{{ selectedIssue.tag_asset_id }}</span>
                        </div>
                        <div class="info-item" *ngIf="selectedIssue.tag_associated_kit">
                          <span class="info-label">Kit:</span>
                          <span class="info-value">{{ selectedIssue.tag_associated_kit }}</span>
                        </div>
                      </div>
                      <div class="info-item">
                        <span class="info-label">Description:</span>
                        <span class="info-value">{{ selectedIssue.description || "-" }}</span>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="comments"
                      role="tabpanel"
                      aria-labelledby="comments-tab"
                    >
                      <div class="chat-container">
                        <div
                          class="chat-message"
                          *ngFor="let comment of selectedIssue.comments"
                        >
                          <div class="message-content">
                            <div class="message-header">
                              <div class="commenter-info">
                                <div class="avatar">
                                  {{ getInitials(comment.submittedBy) }}
                                </div>
                                <span class="commenter-name">{{
                                  comment.submittedBy
                                }}</span>
                              </div>
                              <span class="comment-time">{{
                                comment.timeCreated
                              }}</span>
                            </div>
                            <div class="message-body">{{ comment.body }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="history"
                      role="tabpanel"
                      aria-labelledby="history-tab"
                    >
                      <div class="status-timeline">
                        <div
                          class="status-item"
                          *ngFor="let status of selectedIssue.status_history"
                        >
                          <div class="status-main">
                            <span class="status-value">{{
                              status.status
                            }}</span>
                            <span class="status-time">{{ status.time }}</span>
                          </div>
                          <div class="status-sub">
                            changed by {{ status.name }}
                          </div>
                        </div>
                        <div class="status-item">
                          <div class="status-main">
                            <span class="status-value">To Do</span>
                            <span class="status-time">{{
                              selectedIssue.creation_time
                            }}</span>
                          </div>
                          <div class="status-sub">Initial Status</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup-buttons flex-end">
      <button (click)="hideModal.emit(false)" class="btn btn-light">
        Close
      </button>
    </div>
  </div>
</div>
