import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { getENV } from 'src/app/_helpers/helpers';

@Component({
  selector: 'base-station-calibration',
  templateUrl: './base-station-calibration.component.html',
  styleUrls: ['./base-station-calibration.component.css']
})
export class BaseStationCalibrationComponent implements OnInit, OnDestroy {
  @Input() config: any;
  @Input() eventId: string;
  @Input() venueId: string; // Added venue ID input
  @Input() operator: string; // Add operator input
  @Input() event: any; // Add this input
  @Output() hideModel = new EventEmitter<boolean>();

  loading: boolean = true;
  calibrationData: any = null;
  calibrationInProgress: boolean = false;
  destroyed: boolean = false;
  username: string = localStorage.getItem('un');
  hasCalibrationData: boolean = false;  // Add this new property

  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  private get operatorName(): string {
    return this.operator || this.username;
  }

  private parseCalibrationData(comments: string): any {
    try {
      return JSON.parse(comments);
    } catch (e) {
      return null;
    }
  }

  ngOnInit() {
    this.checkCalibrationStatus(true); // Pass true to indicate initial load
  }

  async startCalibration() {
    this.calibrationInProgress = true;
    const apiURL = `${this.config[getENV()].raceAPI}/flask-operations`;
    const payload = {
      action: 'bs_calibration',
      event_id: this.eventId,
      venue_id: this.venueId,
      operator_name: this.operatorName
    };

    try {
      const result = await this.apiService.postDataPromis(apiURL, payload, {});
      if (result.status === '1' || result.s === '1') {
        this.checkCalibrationStatus();
      } else {
        throw new Error(result.error_message || 'Failed to start calibration');
      }
    } catch (error) {
      this.notifier.alert('Error', '', error.message || 'Failed to start calibration', 'error', 5000);
      this.calibrationInProgress = false;
    }
  }

  async checkCalibrationStatus(isInitialLoad: boolean = false) {
    if (this.destroyed) return;

    const apiURL = `${this.config[getENV()].raceAPI}/flask-operations`;
    const payload = {
      action: 'action_status',
      button_action: 'bs_calibration',
      event_id: this.eventId,
      report_action: 'bs_calibration',
      venue_id: this.venueId,
      operator_name: this.operatorName
    };

    try {
      const result = await this.apiService.postDataPromis(apiURL, payload, {});
      if (result.status === '1' || result.s === '1') {
        const status = result.response?.StepCompletionStatus?.toUpperCase();
        const calibrationData = this.parseCalibrationData(result.response?.Comments);

        this.hasCalibrationData = !!calibrationData;
        if (this.event) {
          this.event.bs_calibration = this.hasCalibrationData;
        }

        if (isInitialLoad) {
          this.calibrationData = calibrationData;
          this.loading = false;
          if (status === 'STARTED' || status === 'IN PROGRESS') {
            this.calibrationInProgress = true;
            setTimeout(() => this.checkCalibrationStatus(false), 10000);
          }
          return;
        }

        if (status === 'COMPLETED') {
          this.notifier.alert('Success', '', 'Calibration completed successfully', 'success', 5000);
          this.calibrationInProgress = false;
          this.calibrationData = calibrationData;
        } else if (status === 'FAILED') {
          this.notifier.alert('Error', '', result.response?.Comments || 'Calibration failed', 'error', 5000);
          this.calibrationInProgress = false;
        } else if (status === 'STARTED' || status === 'IN PROGRESS') {
          if (!this.destroyed) {
            setTimeout(() => this.checkCalibrationStatus(false), 10000);
          }
        }
      } else {
        throw new Error(result.error_message || 'Failed to check calibration status');
      }
    } catch (error) {
      this.notifier.alert('Error', '', error.message || 'Failed to check calibration status', 'error', 5000);
      this.calibrationInProgress = false;
    }
    this.loading = false;
  }

  close() {
    this.hideModel.emit(false);
  }

  ngOnDestroy() {
    this.destroyed = true;
  }
}
