import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment-timezone';
import {
  locationMappers,
  typeBasedLocations,
  buttonActions,
} from '../race-reports/locations';
import {
  checkForVenuePermission,
  convertTimestamp,
  getENV,
  getRaceType,
} from 'src/app/_helpers/helpers';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RacesObject } from '../../../../home/interfaces';

declare var $: any;

// Add this interface near the top of the file
interface RaceTime {
  hour: number;
  minute: number;
  originalHour?: number;
  originalMinute?: number;
}

export enum RaceStatusEN {
  SCHEDULED = 'SCHEDULED',
  ARMED = 'ARMED',
  RUNNING = 'RUNNING',
  FINISHED = 'FINISHED',
  ABANDONED = 'ABANDONED',
}

interface RaceStatus {
  PLANNED: number;
  DNS: number;
  DNT: number;
  DNF: number;
  DSQ: number;
  OFFICIAL: number;
  FINISHED: number;
  PUBLISHED: number;
  ABANDONED: number;
}

export interface TimerState {
  startTime: number | null;
  currentTime: string;
  isRunning: boolean;
  lastStatus: RaceStatusEN;
}

export const TIMER_STORAGE_KEY = 'raceTimerState';

interface ButtonAction {
  slug: string;
  status: 'PLANNED' | 'COMPLETED' | 'FAILED';
  actions: Array<{
    name: string;
    status: string;
    comment: string;
  }>;
}

interface ButtonActionConfig {
  name: string;
  action: string;
  type?: string;
  slug: string;
  states: string | string[];
  conditions?: Array<{
    state: string;
    condition: {
      key: string;
      equation: string;
      value: string;
    };
  }>;
}

interface Race {
  ExternalRaceId: string;
  RaceState: string; // Allow both enum and string
  AnimalDetail: any[];
  ReportStatus: any[];
  status: RaceStatus;
  buttonActions?: ButtonAction[];
  raceStatus?: string;
  publishStatus?: string;
  isExpanded?: boolean;
}

interface Race {
  RaceState: string;
  advertisedStartTime: Date;
  // ...other properties
}

@Component({
  selector: 'app-on-course-ops-judges',
  templateUrl: './on-course-ops-judges.component.html',
  styleUrls: [
    './on-course-ops-judges.component.css',
    './../race-reports/race-reports.component.css',
  ],
})
export class OnCourseOpsJudgesComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  date: string = null;
  race_states: string[] = [
    'Scheduled',
    'Arm',
    'Running',
    'PreFinished',
    'Finished',
    'Canceled',
    'QA PASSED',
    'QA FAILED',
  ];
  horse_race_states = [
    { name: 'PLANNED', value: 'PLANNED' },
    { name: 'DNS', value: 'DNS' },
    { name: 'DNT', value: 'DNT' },
    { name: 'DNF', value: 'DNF' },
    { name: 'DSQ', value: 'DSQ' },
    { name: 'OFFICIAL', value: 'OFFICIAL' },
    { name: 'FINISHED', value: 'FINISHED' },
    { name: 'PUBLISHED', value: 'PUBLISHED' },
    { name: 'ABANDONED', value: 'ABANDONED' },
  ];
  private refreshInterval: any;
  private readonly REFRESH_RATE = 10000; // 10 seconds
  raceTimers: { [key: string]: any } = {};

  async ngAfterViewInit(): Promise<void> {
    this.date = this.date || moment().format('DD MMMM YYYY');
    $('.datetimepicker').val(this.date);
    $('.datetimepicker')
      .datepicker({
        autoclose: true,
        // minViewMode: 1,
        format: 'dd MM yyyy',
        orientation: 'bottom auto',
        endDate: moment().add(2, 'days').toDate(),
      })
      .on('changeDate', (selected: any) => {
        this.date = $('.datetimepicker').val();
        this.fetchEvents();
      });

    setTimeout(() => {
      this.fetchEvents();
    }, 100);
  }

  private isWithin30Seconds(advertisedTime: Date): boolean {
    const now = new Date();
    const timeDiff = advertisedTime.getTime() - now.getTime();
    return timeDiff <= 30000 && timeDiff > 0; // 30 seconds in milliseconds
  }

  async refreshRaces() {
    try {
      // Implement your refresh logic here
      const response = await this.selectEvent(this.selected);
      // Update races array
    } catch (error) {
      console.error('Error refreshing races:', error);
    }
  }

  startRefreshTimer() {
    this.refreshInterval = setInterval(() => {
      this.refreshRaces();
    }, 10000);
  }

  canArm(race: Race): boolean {
    return (
      race.RaceState === 'SCHEDULED' &&
      this.isWithin30Seconds(race.advertisedStartTime)
    );
  }

  canReset(race: Race): boolean {
    return race.RaceState !== 'SCHEDULED';
  }

  canUploadLIF(race: Race): boolean {
    return race.RaceState === 'FINISHED';
  }

  uploadFileObj: any = null;
  uploadFile(event: any, race: any, type: string) {
    event.stopPropagation();
    this.uploadFileObj = {
      type: type,
      race: race,
      raceCource: this.selected,
    };
  }

  querySubscription: Subscription;
  params: any = {};
  ngOnInit(): void {
    this.querySubscription = this.activeRoute.queryParams.subscribe(
      (params: any) => {
        if (params.event_id) {
          this.params['event_id'] = params.event_id;
        }
        if (params.race_id) {
          this.params['race_id'] = params.race_id;
        }
        if (params.date) {
          this.date = params.date;
        }
      }
    );
    this.startAutoRefresh(true);
  }

  config: any;
  constructor(
    private apiService: APIService,
    private notifier: NotifierService,
    private activeRoute: ActivatedRoute,
    private router: Router
  ) {
    this.config = this.activeRoute.snapshot.data['config'];
  }

  selected: any = null;
  locations: any = [];
  races: any = [];
  async fetchEvents() {
    return new Promise(async (resolve: any) => {
      this.notifier.loading(true);
      this.selected = null;
      this.locations = [];
      this.races = [];
      const date = this.date
        ? moment(this.date, 'DD MMMM YYYY').format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD');
      let raceTypes: any = localStorage.getItem('raceTypes');
      if (raceTypes) {
        raceTypes = JSON.parse(raceTypes);
      }
      let apiURL: string = `${
        this.config[getENV()].raceAPI
      }/event-list?formatted_date=${date}`;

      if (raceTypes && raceTypes.length > 0) {
        apiURL += `&race_types=${raceTypes.join(',')}`;
      }

      let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

      if (result.eventNames && result.eventNames.length > 0) {
        result.eventNames = result.eventNames.filter((event: any) => {
          return checkForVenuePermission(event['external_venue_id']);
        });
        let resultEventNames = result.eventNames;

        resultEventNames.forEach((event: any, index: number) => {
          event['operator'] = null;
          let raceType = getRaceType(event['external_venue_id'])['raceTypeStr'];

          if (
            raceType == 'Thoroughbred Racing' &&
            event['venue_name'] == 'Cranbourne'
          ) {
            event['venue_name'] = 'Cranbourne Turf';
          }

          const jurisdiction = (typeBasedLocations[raceType] as any).find(
            (j: any) => {
              let location: any = j.Locations.find((location: any) => {
                return (
                  location.Name.toLowerCase() ==
                  event['venue_name'].toLowerCase()
                );
              });
              if (location) {
                event['type'] = location.type;
              }
              return location;
            }
          );

          if (jurisdiction) {
            event['State'] = jurisdiction.State;
            event['JurisdictionCode'] = jurisdiction.JurisdictionCode;
            event['Jurisdiction'] = jurisdiction.Jurisdiction;
            event['StateCode'] = jurisdiction['State'];
            event['RaceType'] = raceType;
            event['date'] = date;
          }

          event['name'] = event['venue_name'];
          event['id'] = event['external_event_id'];
          // this.loadEvent(event, index);
        });

        this.locations = resultEventNames;
        if (this.params.event_id) {
          const selectedEvent = this.locations.find(
            (event: any) => event.external_event_id === this.params.event_id
          );
          if (selectedEvent) {
            this.selectEvent(selectedEvent);
          } else {
            this.notifier.loading(false);
          }
        } else {
          this.notifier.loading(false);
        }
      } else {
        this.locations = [];
        this.notifier.loading(false);
      }
      resolve(true);
    });
  }

  stopPropagation(event: any) {
    event.stopPropagation();
  }

  actionConfirmation: any = {
    race: null,
    action: null,
  };
  confirmPopup(race: any, action: string = null) {
    if (!action) {
      action = race['statusSelected'];
    }
    if (action == 'ARM') {
      this.submitGrayHound(race, action);
      return;
    }
    this.actionConfirmation = {
      race: race,
      action: action,
    };
  }

  async closePopup(event: boolean) {
    if (event) {
      let race = this.races.find(
        (race: any) =>
          race['ExternalRaceId'] ===
          this.actionConfirmation['race']['ExternalRaceId']
      );
      let action: string = this.actionConfirmation['action'];
      this.submitGrayHound(race, action);
    }
    this.actionConfirmation = {
      race: null,
      action: null,
    };
  }

  private async submitGrayHound(
    race: any,
    action: string = null
  ): Promise<void> {
    race['loading'] = true;
    let apiURL: string = `${this.config[getENV()].raceAPI}/flask-operations`;
    let payload: any = {
      action: 'greyhounds_server_trigger',
      operation: action,
      race_id: race['ExternalRaceId'],
    };

    let result: any = await this.apiService.postDataPromis(apiURL, payload, {});

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', 'Success', 'success', 5000);
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
    race['loading'] = false;
  }

  async reviewPDF(event: any, race: any, type: string = '.pdf') {
    event.stopPropagation();
    let apiURL: string = `${this.config[getENV()].raceAPI}/flask-operations`;

    let payload: any = {
      action: 'review_files',
      location:
        locationMappers[this.selected.venue_name] || this.selected.venue_name,
      race_number: race['SquentialRaceOrderNumber'],
      race_date: this.selected['date'],
      type: this.selected['RaceType'],
      event_id: this.selected['external_event_id'],
      race_id: race['ExternalRaceId'],
      file_extension: type,
    };

    if (type == '.csv') {
      payload['file_folder'] = 'tol';
    }

    let result: any = await this.apiService.postDataPromis(apiURL, payload, {});

    if (result.status === '1' || result.s === '1') {
      let a: HTMLAnchorElement = document.createElement('a');
      a.target = '_blank';
      a.download = result.presigned_url;
      a.href = result.presigned_url;
      a.click();
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
  }

  async selectEvent(event: any, loader: boolean = true) {
    if (loader) {
      this.notifier.loading(true);
    }

    this.router.navigate(['/dash/on-course-ops/judges'], {
      queryParams: { event_id: event['external_event_id'], date: this.date },
    });
    this.selected = event;

    // this.loadEvent(event.external_event_id);

    const apiURL = `${
      this.config[getENV()].raceAPI
    }/getracedetails?ExternalVenueId=${
      event.external_venue_id
    }&ExternalEventId=${event.external_event_id}`;

    const result = await this.apiService.getDataPromis(apiURL, {}, {});

    if (result && Array.isArray(result) && result.length > 0) {
      result.sort(
        (a, b) => a.SquentialRaceOrderNumber - b.SquentialRaceOrderNumber
      );

      result.forEach((race: any) => {
        race.rawRaceTime = race.RaceTime;
        race['statusSelected'] = '';
        race['disabled'] = true;
        race['eventName'] = event.name;
        race['raceStatus'] = race['RaceState']?.toUpperCase() || 'PLANNED';
        race['RaceTimeStr'] = race['RaceTime'];
        race['RaceTime'] = convertTimestamp(
          race['RaceTime'],
          event['venue_state']
        );

        race['RaceTimeHTML'] = race['RaceTime'];

        race['queryParamsVenue'] = {
          event_id: race['ExternalEventId'],
          venue_id: race['ExternalVenueId'],
          date: race['eventDate'],
          event_name: race['eventName'],
        };

        race['queryParamsRace'] = {
          race_id: race['ExternalRaceId'],
        };

        race[
          'name'
        ] = `#${race['SquentialRaceOrderNumber']} ${race['RaceName']}`;
        race['id'] = race['SquentialRaceOrderNumber'];
      });

      this.races = result.map((race) => {
        this.processRaceStatus(race);
        this.processReportStatus(
          race,
          event.JurisdictionCode,
          event.StateCode,
          event.RaceType
        );
        return race;
      });

      if (this.selected['RaceType'] == 'Greyhound Racing') {
        await this.fetchRaceStatuses();
      }
      if (loader) {
        this.notifier.loading(false);
      }
    }
    this.clearAllTimers();
  }

  async fetchRaceStatuses() {
    const apiURL =
      'https://50b3p4zwm3.execute-api.ap-southeast-2.amazonaws.com/tsd-prod-api/greyhound-monitor?action=races';
    try {
      const response = await this.apiService.getDataPromis(apiURL, {}, {});
      if (response.status == '1' || response.s == '1') {
        const races = response.races || [];
        this.races.forEach((race: any) => {
          let raceStatus = races.find(
            (r: any) => r.raceid == race.ExternalRaceId
          );
          if (raceStatus) {
            const previousState = race.RaceState;
            race.RaceState = raceStatus.status;
            race['disabled'] = false;

            // Start/update timer if state changed
            if (previousState !== race.RaceState) {
              this.startRaceTimer(race);
            }
          }
        });
      }
    } catch (error) {
      console.error('Error fetching race statuses:', error);
    }
  }

  closeUploadLIF() {
    if (this.uploadFileObj.uploadStatus == 'COMPLETED') {
      this.uploadFileObj.race.RaceState = 'OFFICIAL';
    }
    this.uploadFileObj = null;
  }

  async loadEntrants(race: any) {
    if (race.expanded) {
      race.expanded = false;
      return;
    }
    race.expanded = true;
    if (race.entrants && race.entrants.length > 0) {
      return;
    }
    race.loading = true;
    let apiURL: string = `${
      this.config[getENV()].raceAPI
    }/race-entrant-details?ExternalRaceId=${
      race.ExternalRaceId
    }&ExternalEventId=${race.ExternalEventId}`;

    let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

    if (typeof result == 'string') {
      race.error = result;
      race.entrants = [];
      return;
    }
    race.entrants = result.entrants || [];
    race.entrants.forEach((horse: any) => {
      horse['RaceState'] = horse['RaceState']
        ? horse['RaceState'].toUpperCase()
        : 'PLANNED';
      horse['RaceStateNew'] = horse['RaceState'];
      horse['imagePath'] = (() => {
        if (result.code_type === 'HARNESS') {
          return `https://tsd-prd01-silks.s3.ap-southeast-2.amazonaws.com/Harness_Racing_Australia/${horse.StartNumber}.png`;
        } else if (this.selected.external_venue_id.includes('STC')) {
          return `https://tsd-prd01-silks.s3.ap-southeast-2.amazonaws.com/singapore/${horse.ExternalSilkID}.png`;
        } else if (this.selected.external_venue_id.startsWith('GR')) {
          return `https://tsd-prd01-silks.s3.ap-southeast-2.amazonaws.com/Greyhounds/${horse.StartNumber}.png`;
        } else {
          return `https://tsd-prd01-silks.s3.ap-southeast-2.amazonaws.com/Racing_Australia/${horse.ExternalSilkID}.png`;
        }
      })();
    });
    race.loading = false;
  }

  async saveHorseStatus(race: any) {
    return new Promise(async (resolve: any, reject: any) => {
      let eventData: any = {
        external_race_id: race.ExternalRaceId,
        track_id: race['TrackID'],
        track_name: race['Track'],
        race_state: race['RaceState'].toUpperCase(),
        update_data: {},
      };

      // Track used tag IDs to check for uniqueness
      const usedTagIds: Set<any> = new Set();
      let isValidationFailed = false;
      race.entrants.forEach((race_entrant: any) => {
        race_entrant.RaceState = race_entrant.RaceStateNew;
        let tag_sn = race_entrant.DeviceNumber || null;
        let trainer_name = race_entrant.ExternalTrainerID || null;
        let dns_id = race_entrant?.RaceState?.toUpperCase() == 'DNS' || false;
        let jockey_name = race_entrant?.ExternalJockeyID || null;
        let tag_id = race_entrant?.DeviceID || null;
        eventData['update_data'][race_entrant.ExternalAnimalID] = {
          tag: tag_id ? Number(tag_id) : null,
          tag_sn: tag_sn,
          trainer: trainer_name,
          jockey: jockey_name,
          is_dns: dns_id,
          barrier: race_entrant.BarrierNumber || '',
          state: race_entrant?.RaceState,
        };
      });
      const apiUrl = `${this.config[getENV()].raceAPI}/update-race-details`;
      const apiKey = 'U7RoenAJeFp21d07UQMY7Y1TAitCuR76nuXQJ8pg';
      const headers = { 'x-api-key': apiKey };
      this.notifier.loading(true);
      let result: any = await this.apiService.postDataPromis(
        apiUrl,
        eventData,
        headers
      );

      this.notifier.loading(false);
      resolve(true);
    });
  }

  async editRaceTime(race: any, newTime: string) {
    if (race.RaceState === 'ARMED' || race.RaceState === 'RUNNING') {
      this.notifier.alert(
        'Error',
        '',
        'Cannot edit race time in current state',
        'error',
        5000
      );
      return;
    }

    const apiURL = `${this.config[getENV()].raceAPI}/update-race-time`;
    const payload = {
      race_id: race.ExternalRaceId,
      new_time: newTime,
    };

    try {
      await this.apiService.postDataPromis(apiURL, payload, {});
      race.RaceTime = newTime;
      this.notifier.alert(
        'Success',
        '',
        'Race time updated successfully',
        'success',
        5000
      );
    } catch (error) {
      this.notifier.alert(
        'Error',
        '',
        'Failed to update race time',
        'error',
        5000
      );
    }
  }

  private startRaceTimer(race: any): void {
    if (this.raceTimers[race.ExternalRaceId]) {
      clearInterval(this.raceTimers[race.ExternalRaceId]);
    }

    if (race.RaceState === 'RUNNING') {
      let startTime = Date.now();
      race.elapsedTime = '00:00:00';
      this.raceTimers[race.ExternalRaceId] = setInterval(() => {
        const elapsed = Date.now() - startTime;
        race.elapsedTime = new Date(elapsed).toISOString().substr(11, 8);
      }, 1000);
    } else if (race.RaceState === 'FINISHED') {
      clearInterval(this.raceTimers[race.ExternalRaceId]);
    }
  }

  private startAutoRefresh(firstTime: boolean = false): void {
    this.refreshInterval = setInterval(() => {
      if (this.selected && !this.showRaceTimePopup) {
        this.selectEvent(this.selected, firstTime);
      }
      firstTime = false;
    }, this.REFRESH_RATE);
  }

  private stopAutoRefresh(): void {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  private clearAllTimers(): void {
    Object.values(this.raceTimers).forEach((timer) => clearInterval(timer));
    this.raceTimers = {};
  }

  showRaceTimePopup: boolean = false;
  selectedRace: any = null;
  newRaceTime: { hour: number; minute: number } = { hour: 0, minute: 0 };
  hours: number[] = Array.from({ length: 24 }, (_, i) => i);
  minutes: number[] = Array.from({ length: 60 }, (_, i) => i);

  openRaceTimePopup(race: any) {
    this.selectedRace = race;
    const [hour, minute] = race.RaceTime.split('H').map(Number);
    this.newRaceTime = { hour, minute };
    this.showRaceTimePopup = true;
  }

  closeRaceTimePopup() {
    this.showRaceTimePopup = false;
    this.selectedRace = null;
    this.newRaceTime = { hour: 0, minute: 0 };
  }

  addMinutes(minutes: number) {
    let time = moment({
      hour: this.newRaceTime.hour,
      minute: this.newRaceTime.minute,
    });
    time.add(minutes, 'minutes');
    this.newRaceTime.hour = time.hour();
    this.newRaceTime.minute = time.minute();
  }

  resetRaceTIme() {
    let raw = this.convertRaceTime(this.selectedRace.rawRaceTime);
    const [hour, minute] = raw.split('H').map(Number);
    this.newRaceTime = { hour, minute };
  }

  updateRaceTime() {
    if (this.selectedRace) {
      let raw = this.convertRaceTime(this.selectedRace.rawRaceTime);
      const [hour, minute] = raw.split('H').map(Number);
      const newRaceTime = `${this.newRaceTime.hour
        .toString()
        .padStart(2, '0')}H${this.newRaceTime.minute
        .toString()
        .padStart(2, '0')}`;
      if (this.newRaceTime.hour == hour && this.newRaceTime.minute == minute) {
        this.selectedRace.RaceTime = newRaceTime;
        this.selectedRace.timeChanged = false;
        this.selectedRace.RaceTimeHTML = newRaceTime;
        this.selectedRace.diffTime = 0;
      } else {
        const originalTime = moment({ hour, minute });
        const newTime = moment({
          hour: this.newRaceTime.hour,
          minute: this.newRaceTime.minute,
        });
        const diff = newTime.diff(originalTime, 'minutes');
        const diffFormatted = diff >= 0 ? `+${diff}m` : `${diff}m`;
        this.selectedRace.RaceTimeHTML = `${newRaceTime} <span class='fw-bold'>${diffFormatted}</span>`;
        this.selectedRace.RaceTime = newRaceTime;
        this.selectedRace.diffTime = diff;
        this.selectedRace.timeChanged = true;
      }
      this.closeRaceTimePopup();
    }
  }

  convertRaceTime(time: string): string {
    return convertTimestamp(time, this.selected?.venue_state);
  }

  private initializeRaceStatus(): RaceStatus {
    return {
      PLANNED: 0,
      DNS: 0,
      DNT: 0,
      DNF: 0,
      DSQ: 0,
      OFFICIAL: 0,
      FINISHED: 0,
      PUBLISHED: 0,
      ABANDONED: 0,
    };
  }

  private processRaceStatus(race: Race): void {
    if (!race.RaceState) {
      race.RaceState = 'SCHEDULED';
    }

    race.RaceState = race.RaceState.toUpperCase();

    const states = [
      'SCHEDULED',
      'ARMED',
      'RUNNING',
      'FINISHED',
      'ABANDONED',
      'PLANNED',
      'PLANNED',
      'DNS',
      'DNT',
      'DNF',
      'DSQ',
      'OFFICIAL',
      'FINISHED',
      'PUBLISHED',
      'ABANDONED',
    ];
    if (!states.includes(race.RaceState)) {
      race.RaceState = 'SCHEDULED';
    }
    race.status = this.initializeRaceStatus();
    race.RaceState = race.RaceState || 'PLANNED';
    race.raceStatus = race.RaceState;

    if (race.RaceState.toUpperCase() === 'ABANDONED') {
      race.status.ABANDONED = race.AnimalDetail.length;
    } else {
      race.AnimalDetail.forEach((animal) => {
        let status = 'PLANNED';
        animal.RaceState = animal.RaceState || 'PLANNED';

        if (animal.RaceState.toUpperCase() === 'PLANNED') {
          status =
            race.RaceState.toUpperCase() === 'FINISHED'
              ? 'FINISHED'
              : 'PLANNED';
        } else {
          status = animal.RaceState.toUpperCase();
        }

        race.status[status as keyof RaceStatus]++;
      });
    }
  }

  private processReportStatus(
    race: Race,
    jurisdictionCode: string,
    stateCode: string,
    raceType: string
  ): void {
    // Initialize default actions
    race.buttonActions = [
      { slug: 'OR', status: 'PLANNED', actions: [] },
      { slug: 'PDF', status: 'PLANNED', actions: [] },
      { slug: 'CUST', status: 'PLANNED', actions: [] },
      { slug: 'TSD', status: 'PLANNED', actions: [] },
    ];

    // Filter applicable actions based on jurisdiction
    const applicableActions = buttonActions.filter(
      (report: ButtonActionConfig) => {
        if (!report.conditions) return true;

        const condition = report.conditions.find(
          (c) => c.state === jurisdictionCode
        );
        if (!condition) return true;

        const { key, equation, value } = condition.condition;
        return equation === 'equals'
          ? race[key] === value
          : race[key] !== value;
      }
    );

    // Process each applicable action
    applicableActions.forEach((report: ButtonActionConfig) => {
      if (
        (report.states === 'ALL' ||
          (Array.isArray(report.states) &&
            (report.states.includes(jurisdictionCode) ||
              report.states.includes(stateCode)))) &&
        (!report.type || report.type === raceType)
      ) {
        const action = race.buttonActions.find((ba) => ba.slug === report.slug);
        if (action) {
          const reportStatus = race.ReportStatus?.find(
            (rs) => rs.StepType === report.action
          );

          action.actions.push({
            name: report.name,
            status: reportStatus
              ? reportStatus.StepCompletionStatus.toUpperCase()
              : 'PLANNED',
            comment: reportStatus?.Comments || '',
          });
        }
      }
    });

    // Update overall status for each button
    race.buttonActions.forEach((button) => {
      const hasCompleted = button.actions.some((a) => a.status === 'COMPLETED');
      const hasFailed = button.actions.some((a) => a.status === 'FAILED');
      button.status = hasFailed
        ? 'FAILED'
        : hasCompleted
        ? 'COMPLETED'
        : 'PLANNED';
    });

    // Remove empty action groups
    race.buttonActions = race.buttonActions.filter(
      (ba) => ba.actions.length > 0
    );
  }

  toggleRaceDetails(race: Race): void {
    race.isExpanded = !race.isExpanded;
    this.loadEntrants(race);
  }

  ngOnDestroy(): void {
    this.stopAutoRefresh();
    this.clearAllTimers();
    this.querySubscription.unsubscribe();
  }

  getRaceStatusClass(race: any): string {
    const status = race.RaceState?.toLowerCase();
    return status ? `race-status-${status}` : '';
  }
}
