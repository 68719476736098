import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { steps, atc_steps } from './steps';

import { DashService } from '../../../../dash.service';
import { getENV } from 'src/app/_helpers/helpers';

export class PrepareforRace {
  status: string = 'PENDING';
  failedCount: number = 0;
  interval: any = null;
  currentStep: number = 1;
  small_file: boolean = false;
  finish: boolean = false;
  expanded: boolean = false;
  stopped: boolean = false;

  constructor(
    private config: any,
    public payload: any,
    private dashService: DashService
  ) {}

  private async check() {
    return { status: false };
    let apiURL: string = `${
      this.config[getENV()].raceAPI
    }/flask-operations`;
    let sending: any = {
      action: `${this.payload.type}_check`,
      venue_name: this.payload.location,
      PC: this.payload.pc,
      kit: this.payload.kit,
      operator: this.payload.operator,
      venue_id: this.payload.venue_id,
      event_id: this.payload.event_id,
      type: this.payload.code_type,
      rerun: this.payload.relogin,
    };

    const response = await fetch(apiURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(sending),
    });

    const result = await response.json();

    if (result?.response?.status) {
      return { status: true, message: 'COMPLETED' };
    } else {
      return {
        status: false,
        message:
          result?.response?.reason ||
          result?.error_message ||
          'Unable to validate user. Please relogin or try again later..',
      };
    }
  }

  private async checkStepStatus(index: number, async: boolean = false) {
    let apiURL: string = `${
      this.config[getENV()].raceAPI
    }/flask-operations`;

    const payload: any = {
      action: 'login_logout_status',
      venue_name: this.payload.location,
      PC: this.payload.pc,
      kit: this.payload.kit,
      operator: this.payload.operator,
      race_date: this.payload.race_date,
      venue_id: this.payload.venue_id,
      event_id: this.payload.event_id,
      type: this.payload.type,
      step: index,
      rerun: this.payload.relogin,
    };
    const response = await fetch(apiURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    let res = await response.json();
    if (res.status == '1') {
      if (!res?.response?.StepCompletionStatus) {
        if (!async) {
          res = { response: { StepCompletionStatus: 'PENDING' } };
        } else {
          res = {
            response: { StepCompletionStatus: 'FAILED', Comments: 'FAILED' },
          };
        }
      }
      return {
        status: true,
        message: res?.response?.Comments,
        step_status:
          res?.response?.StepCompletionStatus.toUpperCase().replaceAll(
            ' ',
            '_'
          ) || 'FAILED',
      };
    } else {
      return { status: false, step_status: 'PENDING' };
    }
  }

  private async checkStatusSync(step: any, index: number) {
    return new Promise(async (resolve: any, reject: any) => {
      this.interval = setInterval(async () => {
        if (this.stopped || !this.interval) {
          if (this.interval) {
            clearInterval(this.interval);
          }
          this.interval = null;
        }
        let res = await this.checkStepStatus(step['step'] || index, true);
        if (res.status) {
          step['comment'] = res.message;
          step['status'] = res.step_status;
          if (res.step_status == 'FAILED') {
            clearInterval(this.interval);
            resolve(false);
          } else if (res.step_status == 'COMPLETED') {
            clearInterval(this.interval);
            resolve(true);
          }
        } else {
          clearInterval(this.interval);
          resolve(false);
        }
      }, 5000);
    });
  }

  private async triggerStep(step: any, index: number) {
    return new Promise(async (resolve: any, reject: any) => {
      step['status'] = 'IN_PROGRESS';
      let apiURL: string = `${
        this.config[getENV()].raceAPI
      }/flask-operations`;

      let payload: any = {
        action: this.payload.action,
        event_id: this.payload.event_id,
        variable: {
          venue_name: this.payload.location,
          PC: this.payload.pc,
          kit: this.payload.kit,
          operator: this.payload.operator,
          venue_id: this.payload.venue_id,
          event_id: this.payload.event_id,
          type: this.payload.code_type,
          race_date: this.payload.race_date,
        },
        step: step['step'] || index,
        rerun: this.payload.relogin,
      };

      if(this.payload.type == 'logout'){
        payload['stop_pc'] = this.payload.pc_start_stop;
      } else {
        payload['start_pc'] = this.payload.pc_start_stop;
      }

      const response = await fetch(apiURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const res = await response.json();

      if (res.status != '1') {
        this.failedCount += 1;
        step['status'] = 'FAILED';
        if (this.payload.type == 'logout' && index == 1) {
          this.small_file = true;
        }
        resolve(false);
      } else {
        let status: any = await this.checkStatusSync(step, index);
        if (!status) {
          this.failedCount += 1;
          step['status'] = 'FAILED';
        }
        resolve(status);
      }
    });
  }

  private async initialize() {
    if (this.payload['status'] == 'FAILED') {
      // this.payload.steps[this.payload.type].forEach((step: any) => {
      //   step['status'] = 'COMPLETED';
      // });
      this.finish = true;
      this.payload.rerun = false;
      this.payload.relogin = false;
      return;
    }
    if (this.payload['relogin']) {
      this.payload.steps[this.payload.type].forEach((step: any) => {
        step['status'] = 'PENDING';
      });
    }
    this.status = 'IN_PROGRESS';
    this.payload['status'] = 'IN_PROGRESS';
    let check: any = await this.check();
    this.small_file = false;
    this.finish = false;
    if (check['status'] && !this.payload.relogin) {
      this.status = 'COMPLETED';
      this.payload['status'] = 'COMPLETED';
      this.payload.steps[this.payload.type].forEach((step: any) => {
        step['status'] = 'COMPLETED';
      });
      this.finish = true;
      localStorage.setItem(
        `prepareSystem_${this.payload.event_id}`,
        JSON.stringify(this.payload)
      );
      this.currentStep = this.payload.steps[this.payload.type].length;
      return;
    }
    localStorage.setItem(
      `prepareSystem_${this.payload.event_id}`,
      JSON.stringify(this.payload)
    );
    this.failedCount = 0;
    for (
      let i = 0;
      i < this.payload['steps'][this.payload['type']].length;
      i++
    ) {
      if (this.failedCount > 0 || this.stopped) {
        break;
      }
      this.currentStep = i + 1;
      let step = this.payload.steps[this.payload.type][i];
      step['status'] = 'STARTED';
      let stepStatus = await this.checkStepStatus(step['step'] || (i + 1));
      step['status'] = stepStatus['step_status'];
      step['comment'] = stepStatus['message'];
      if (
        (this.payload.relogin || this.payload.type == 'logout') &&
        !this.payload.rerun
      ) {
        await this.triggerStep(step, i + 1);
      } else {
        if (stepStatus['step_status'] == 'COMPLETED') {
          continue;
        } else if (stepStatus['step_status'] == 'IN_PROGRESS') {
          this.checkStatusSync(step, i + 1);
        } else {
          await this.triggerStep(step, i + 1);
        }
      }
      localStorage.setItem(
        `prepareSystem_${this.payload.event_id}`,
        JSON.stringify(this.payload)
      );
    }
    this.finish = true;
    if (this.failedCount == 0) {
      this.status = 'COMPLETED';
      if (
        (this.payload.location === 'Eagle Farm' ||
        this.payload.location === 'Doomben') && this.payload.type == 'logout'
      ) {
        this.sendNotification('BASE_STATION_LOGOUT');
      }
    } else {
      this.status = 'FAILED';
      this.payload['status'] = 'FAILED';
    }
    localStorage.setItem(
      `prepareSystem_${this.payload.event_id}`,
      JSON.stringify(this.payload)
    );
  }

  public resumePrepare(type: 'connect' | 'disconnect') {
    this.stopped = false;
    if (type == 'connect') {
      this.payload.baseStation = true;
      this.newPrepare(true);
    }
  }

  private sendNotification(message: string) {
    this.dashService.sendMessage(
      JSON.stringify({
        type: message,
        prepareId: this.payload.event_id,
        location: this.payload.location,
      })
    );
  }

  stopPrepare() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.stopped = true;
  }

  newPrepare(force: boolean = false) {
    if (this.payload.baseStation) {
      force = true;
    }
    if (
      (this.payload.location === 'Eagle Farm' ||
        this.payload.location === 'Doomben') &&
      !force && this.payload.type == 'login'
    ) {
      this.sendNotification('BASE_STATION_LOGIN');
      return;
    }
    this.initialize();
  }
}

@Injectable({
  providedIn: 'root',
})
export class PrepareService {
  private _dataSource = new BehaviorSubject<PrepareforRace[]>([]);
  dataSource$ = this._dataSource.asObservable();
  public prepares: PrepareforRace[] = [];
  dashService = inject(DashService);

  config: any;
  constructor() {}

  removePrepare(prepare: PrepareforRace, index: number) {
    try {
      prepare.stopPrepare();
      let prepares: any = localStorage.getItem('prepares');
      if (prepares) {
        try {
          prepares = JSON.parse(prepares);
        } catch (error) {
          prepares = [];
          this._dataSource.next([]);
          return;
        }
        prepares = prepares.splice(
          prepares.indexOf(prepare.payload.event_id),
          1
        );
        if (prepares.length == 0) {
          localStorage.removeItem('prepares');
        } else {
          if(typeof prepares =='string'){
            prepares = [prepares];
          }
          localStorage.setItem('prepares', prepares);
        }
      }
      if (localStorage.getItem(`prepareSystem_${prepare.payload.event_id}`)) {
        localStorage.removeItem(`prepareSystem_${prepare.payload.event_id}`);
      }
      this.prepares.splice(index, 1);
    } catch (error) {
      this.prepares = [];
      localStorage.setItem('prepares', JSON.stringify([]));
    }
    this._dataSource.next(this.prepares);
  }

  newPrepare(payload: any, config: any): PrepareforRace {
    this.config = config;
    if(payload.jurisdiction == 'ATC'){
      payload['steps'] = JSON.parse(JSON.stringify(atc_steps));
    } else {
      payload['steps'] = JSON.parse(JSON.stringify(steps));
    }
    let prepare: PrepareforRace;
    let prepares: any = localStorage.getItem('prepares');
    if (prepares) {
      try {
        prepares = JSON.parse(prepares);
      } catch (error) {
        prepares = [];
      }
      if (!Array.isArray(prepares)) {
        localStorage.removeItem('prepares');
        prepares = [payload.event_id];
        prepare = new PrepareforRace(this.config, payload, this.dashService);
        prepare.newPrepare();
        this.prepares.push(prepare);
      } else {
        if (prepares.indexOf(payload.event_id) < 0) {
          prepares.push(payload.event_id);
          prepare = new PrepareforRace(this.config, payload, this.dashService);
          prepare.newPrepare();
          this.prepares.push(prepare);
        } else {
          prepare = this.prepares.find(
            (p) => p.payload.event_id === payload.event_id
          );
          if (prepare) {
            prepare.payload = payload;
            prepare.newPrepare();
          } else {
            prepares = [payload.event_id];
            prepare = new PrepareforRace(
              this.config,
              payload,
              this.dashService
            );
            prepare.newPrepare();
            this.prepares.push(prepare);
          }
        }
      }
    } else {
      prepares = [payload.event_id];
      prepare = new PrepareforRace(this.config, payload, this.dashService);
      prepare.newPrepare();
      this.prepares.push(prepare);
    }
    if (typeof prepares == 'string') {
      prepares = [prepares];
    }
    localStorage.setItem('prepares', JSON.stringify(prepares));
    this._dataSource.next(this.prepares);
    return prepare;
  }

  callPreviousPrepares(config: any) {
    let dashService: DashService = new DashService();
    this.config = config;
    let prepares: any = localStorage.getItem('prepares');
    if (prepares) {
      try {
        prepares = JSON.parse(prepares);
      } catch (error) {
        localStorage.removeItem('prepares');
        return;
      }
      for (let i = 0; i < prepares.length; i++) {
        let prepare_: any = localStorage.getItem(
          `prepareSystem_${prepares[i]}`
        );
        if (prepare_) {
          let payload: any = JSON.parse(prepare_);
          let prepare: PrepareforRace = new PrepareforRace(
            this.config,
            payload,
            this.dashService
          );
          prepare.newPrepare();
          if (typeof prepares == 'string') {
            prepares = [prepares];
          }
          localStorage.setItem('prepares', JSON.stringify(prepares));
          this.prepares.push(prepare);
        }
      }
      this._dataSource.next(this.prepares);
    }
  }
}
