export let steps: any = {
  login: [
    {
      name: 'Download essential files from AWS S3',
      status: 'PENDING',
    },
    {
      name: 'Back up original EQTraCe on the PC',
      status: 'PENDING',
    },
    {
      name: 'Setup config file',
      status: 'PENDING',
    },
    {
      name: 'Move files to root folder of config file',
      status: 'PENDING',
    },
    {
      name: 'Edit database string',
      status: 'PENDING',
    },
    {
      name: 'Download and Restore bak file into database',
      status: 'PENDING',
    },
    {
      name: 'Setup HRExporter file',
      status: 'PENDING',
    },
    {
      name: 'Setup LTS and Mastering file',
      status: 'PENDING',
    },
    {
      name: 'Clean up Cache',
      status: 'PENDING',
    },
  ],
  logout: [
    {
      name: 'Restore original EQTraCe',
      status: 'PENDING',
    },
    {
      name: 'Back up and upload database',
      status: 'PENDING',
    },
    {
      name: 'Clean up backup folder',
      status: 'PENDING',
    },
    {
      name: 'Clean up data folder',
      status: 'PENDING',
    },
    {
      name: 'Clean up files in AWS S3',
      status: 'PENDING',
    },
  ],
};

export let atc_steps: any = {
  login: [
    {
      name: 'Move Final Fields file to EQTrace PC and Bucker PC',
      status: 'PENDING',
    },
    {
      name: 'Move Scratching file to EQTrace PC and Bucker PC',
      status: 'PENDING',
    },
    {
      name: 'Update DB',
      status: 'PENDING',
      step: 9,
    },
  ],
  logout: [
    {
      name: 'Update DB',
      status: 'PENDING',
      step: 5,
    },
  ],
};
