import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import VenueEmails from '../services/emails';
import { getENV, getRaceType } from 'src/app/_helpers/helpers';
import * as moment from 'moment-timezone';
import { locationMappers } from '../post-race/harness-reports/components/race-reports/locations';
import { VenueDetailsService } from 'src/app/modules/globals/dash/modules/settings/services/venue-details.service';
@Component({
  selector: 'app-eod-report',
  templateUrl: './eod-report.component.html',
  styleUrls: ['./eod-report.component.css'],
})
export class RaceEodReportComponent implements OnInit {
  @Input('races') races: any;
  @Input('event') event: any;
  @Output('hideModel') hideModel: any = new EventEmitter<boolean>();

  config: any;
  constructor(
    private apiService: APIService,
    private notifier: NotifierService,
    private activeRoute: ActivatedRoute,
    private venueService: VenueDetailsService
  ) {
    this.config = this.activeRoute.snapshot.data['config'];
    this.venueEmailObj = new VenueEmails(
      apiService,
      this.config[getENV()],
      this.notifier
    );
  }

  venueEmailObj: VenueEmails;

  venueEmail: string = '';
  isMylapsVenue: boolean = false;
  generateReportButton = {
    loading: false,
    error: false,
    completed: false,
    pending: true,
    message: '',
    pdf_loading: false,
    files: [],
  };

  publishReportButton = {
    loading: false,
    error: false,
    completed: false,
    pending: true,
    message: '',
    steps: [],
  };

  ngOnInit(): void {
    this.checkGenerateStatus();
    this.checkPublishStatus();
    this.venueEmailObj
      .loadVenueEmail(this.event['venue_id'])
      .then((data: any) => {
        this.venueEmail = data['data'][0]['EODEmailDL'] || '-';
      });
    this.loadVenue();
    
  }

  lastUpdatedMs: number = 0;
  async loadVenue() {
    console.log(this.event)
    try {
      const venueDetails = await this.venueService.getLocationDetails(
        this.config,
        this.event['venue_id'],
        this.event['raceType'],
        this.event['venue']
      );
      if (venueDetails && venueDetails.venues) {
        this.isMylapsVenue = venueDetails.venues.MylapsIP != null; 
      }
      console.log(this.isMylapsVenue)
    } catch (error) {
      console.error('Error loading venue details:', error);
    }
  }
  async checkGenerateStatus() {
    this.generateReportButton.completed = false;
    this.generateReportButton.pending = false;
    this.generateReportButton.error = false;
    this.generateReportButton.message = '';
    this.generateReportButton.loading = true;
    this.generateReportButton.pdf_loading = false;
    this.generateReportButton['files'] = [];
    let payload: any = {
      action: 'action_status',
      event_state: this.event['event_state'] || 'PLANNED',
      event_id: this.event['external_event_id'] || this.event['event_id'],
      button_action: 'generate_eod_report',
      venue_id: this.event['external_venue_id'] || this.event['venue_id'],
    };

    let apiURL: string = `${this.config[getENV()].raceAPI}/flask-operations`;

    let result: any = await this.apiService.postDataPromis(apiURL, payload, {});

    if (result.status == '1' || result.s == '1') {
      let status =
        result?.response?.StepCompletionStatus?.toUpperCase() || 'PENDING';

      if (status == 'COMPLETED') {
        this.generateReportButton.completed = true;
        try {
          let steps: any = JSON.parse(result?.response?.Comments);
          this.generateReportButton['files'] = steps;
        } catch (error) {}
      } else if (status == 'IN PROGRESS') {
        this.generateReportButton.loading = true;
        setTimeout(() => {
          this.checkGenerateStatus();
        }, 5000);
        return;
      } else if (status == 'FAILED') {
        const error =
          result?.response?.Comments ||
          'Something went wrong. Please try again!';
        this.generateReportButton.error = true;
        this.generateReportButton.message = error;
      } else {
        this.generateReportButton.pending = true;
      }
    } else {
      this.generateReportButton.message = 'FAILED';
      this.generateReportButton.error = true;
    }
    this.generateReportButton.loading = false;
  }

  async checkPublishStatus() {
    this.publishReportButton.completed = false;
    this.publishReportButton.pending = false;
    this.publishReportButton.error = false;
    this.publishReportButton.message = '';
    this.publishReportButton.loading = true;
    this.publishReportButton.steps = [];
    let payload: any = {
      action: 'action_status',
      event_state: this.event['event_state'] || 'PLANNED',
      event_id: this.event['external_event_id'] || this.event['event_id'],
      button_action: 'publish_eod_report',
      venue_id: this.event['external_venue_id'] || this.event['venue_id'],
    };

    let apiURL: string = `${this.config[getENV()].raceAPI}/flask-operations`;

    let result: any = await this.apiService.postDataPromis(apiURL, payload, {});

    if (result.status == '1' || result.s == '1') {
      let status =
        result?.response?.StepCompletionStatus?.toUpperCase() || 'PENDING';

      if (status == 'COMPLETED') {
        this.publishReportButton.completed = true;
        let last_updated =
          result?.response?.LastUpdatedAt || result?.response?.CreatedAt;
        if (last_updated) {
          this.lastUpdatedMs = moment(last_updated).local().valueOf();
        }
        try {
          let steps: any = JSON.parse(result?.response?.Comments);
          steps.forEach((step: any) => {
            if (!step.status) {
              this.publishReportButton.error = true;
            }
            this.publishReportButton.steps.push({
              StepCompletionStatus: step.status ? 'COMPLETED' : 'FAILED',
              StepType: step.action,
              Comments: step.message,
            });
          });
        } catch (error) {}
      } else if (status == 'IN PROGRESS') {
        this.publishReportButton.loading = true;
        setTimeout(() => {
          this.checkPublishStatus();
        }, 5000);
        return;
      } else if (status == 'FAILED') {
        const error =
          result?.response?.Comments ||
          'Something went wrong. Please try again!';
        this.publishReportButton.error = true;
        this.publishReportButton.message = error;
      } else {
        this.publishReportButton.pending = true;
      }
    } else {
      this.publishReportButton.message = 'FAILED';
      this.publishReportButton.error = true;
    }
    this.publishReportButton.loading = false;
  }

  showEventNotes() {}

  async reviewPDF(type: string = 'PDF') {
    this.generateReportButton.pdf_loading = true;
    const venue_id: string =
      this.event['external_venue_id'] || this.event['venue_id'];
    let report_type = getRaceType(venue_id)['raceTypeStr'];
    if (this.isMylapsVenue) {
      report_type = 'Mylaps Racing';
    } 
    let payload: any = {
      action: 'eod_review_files',
      event_state: this.event['event_state'] || 'PLANNED',
      event_id: this.event['external_event_id'] || this.event['event_id'],
      button_action: 'publish_eod_report',
      venue_id: venue_id,
      type: report_type,
    };
    
    let apiURL: string = `${this.config[getENV()].raceAPI}/flask-operations`;

    let result: any = await this.apiService.postDataPromis(apiURL, payload, {});

    if (result.status == '1' || result.s == '1') {
      try {
        let urls = result.presigned_urls;
        let url: any = urls[`${type.toLowerCase()}`];
        if(!url){
          this.notifier.alert(
            'Info',
            '',
            `No ${type} found. Please try again!`,
            'info',
            5000
          );
          this.generateReportButton.pdf_loading = false;
          return;
        }
        let a = document.createElement('a');
        a.href = url;
        a.download = `${type.toLowerCase()}`;
        a.target = '_blank';
        a.click();
      } catch (error) {
        this.notifier.alert(
          'Info',
          '',
          error,
          'info',
          5000
        );
      }
    } else {
      this.notifier.alert(
        'Info',
        '',
        result.error_message || 'Something went wrong. Please try again!',
        'info',
        5000
      );
    }
    this.generateReportButton.pdf_loading = false;
  }

  async generatePDF() {
    this.generateReportButton.loading = true;
    const veune_id: string =
      this.event['external_event_id'] || this.event['event_id'];
    let report_type = getRaceType(veune_id)['raceTypeStr'];
    if (this.isMylapsVenue) {
      report_type = 'Mylaps Racing';
    } 
    let payload = {
      action: 'generate_eod_report',
      event_state: this.event['event_state'] || 'PLANNED',
      event_id: this.event['external_event_id'] || this.event['event_id'],
      venue_id: this.event['external_venue_id'] || this.event['venue_id'],
      type: report_type,
      jurisdiction: this.event['jurisdictionCode'],
      location: locationMappers[this.event['venue']] || this.event['venue'],
      race_date: this.event['event_date'],
    };

    let apiURL: string = `${this.config[getENV()].raceAPI}/flask-operations`;

    let result: any = await this.apiService.postDataPromis(apiURL, payload, {});

    if (result.status == '1' || result.s == '1') {
      setTimeout(() => {
        this.checkGenerateStatus();
      }, 5000);
    } else {
      this.generateReportButton.message = result.error_message || 'FAILED';
      this.generateReportButton.error = true;
      this.generateReportButton.loading = false;
    }
  }

  confirmPublishCheck: boolean = false;
  publishInputs: boolean = false;
  hideConfirmPublish(event: boolean) {
    this.confirmPublishCheck = false;
    if (event) {
      this.publishReport();
    }
  }

  mailCoolDown: boolean = false;
  hideCooldownAlert(event: boolean) {
    this.mailCoolDown = false;
    if (event) {
      this.lastUpdatedMs = 0;
      this.checkForPublish(false);
    }
  }

  checkForPublish(cooldown: boolean = true, publish: boolean = true) {
    if (this.lastUpdatedMs && cooldown) {
      const nowMs = moment().local().valueOf();
      const minutesAgo = Math.abs(
        Math.floor((nowMs - this.lastUpdatedMs) / 60000)
      );
      if (minutesAgo < 5) {
        this.mailCoolDown = true;
        return;
      } else {
        this.mailCoolDown = false;
      }
    }
    if (publish) {
      this.publishInputs = true;
      return;
    }

    this.confirmPublishCheck = true;
  }

  hidePublishInputs(event: boolean) {
    this.publishInputs = false;
    if (event) {
      this.checkForPublish(false, false);
    }
  }

  async publishReport() {
    this.publishReportButton.loading = true;
    const veune_id = this.event['external_venue_id'] || this.event['venue_id'];
    let report_type = getRaceType(veune_id)['raceTypeStr'];
    if (this.isMylapsVenue) {
      report_type = 'Mylaps Racing';
    } 
    let payload = {
      action: 'publish_eod_report',
      general: this.event['general'] || 'No issue',
      software: this.event['software'] || 'No issue',
      infrastructure: this.event['infrastructure'] || 'No issue',
      location: locationMappers[this.event['venue']] || this.event['venue'],
      jurisdiction: this.event['jurisdictionCode'],
      graphics: this.event['graphics'] || 'No issue',
      pulse: this.event['pulse'] || 'No issue',
      barrier_load_performance:
        this.event['barrier_load_performance'] || 'No issue',
      tags: this.event['tags'] || 'No issue',
      type: report_type,
      reports: this.event['reports'] || 'No issue',
      race_date: this.event['event_date'],
      event_id: this.event['external_event_id'] || this.event['event_id'],
      venue_id: veune_id,
      event_state: this.event['event_state'] || 'PLANNED',
    };
    let apiURL: string = `${this.config[getENV()].raceAPI}/flask-operations`;

    let result: any = await this.apiService.postDataPromis(apiURL, payload, {});

    if (result.status == '1' || result.s == '1') {
      setTimeout(() => {
        this.checkPublishStatus();
      }, 5000);
    } else {
      this.publishReportButton.message = result.error_message || 'FAILED';
      this.publishReportButton.error = true;
      this.publishReportButton.loading = false;
    }
  }
}
